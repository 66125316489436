import React, { Fragment, useState, useRef } from 'react'
import { Box, Typography, Divider, TextField } from '@mui/material';
import { ArrowBack, ArrowForward, KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import { Close } from '@mui/icons-material';
import SaveAndShare from '../Dialogue/SaveAndShare';
import { useDispatch } from "react-redux";
import { incrementAsyncTotalItems, incrementAsyncTotalAdultSize, incrementAsyncTotalYouthSize, } from '../../features/counter/counterSlice';
import useAuth from './../../hooks/useAuth';
import colors from './../../styles/colors';
import Images from './../../assets/Images';
import { PrimaryButton, SecondaryButton } from './../UI/Buttons';
import LoginDialog from './../../views/Auth/Dialogs/LoginDialog';



function OrderSection({ setGetOrderPage, saveAndShareDesign, loadingStatus, open, setOpen, saveDesignOpen, setSaveDesignOpen }) {
  console.log("🚀 ~ file: OrderSection.js ~ line 17 ~ OrderSection ~ setOpen", setOpen)
  // Using Redux
  const dispatch = useDispatch();

  // Login Dialog
  const { user } = useAuth();
  // const [open, setOpen] = useState(false);
  // const [saveDesignOpen, setSaveDesignOpen] = useState(false);

  const [sizesOpen, setSizesOpen] = useState(true)
  const handleSizeOpenStatus = () => {
    setSizesOpen(!sizesOpen)
  }
  // Adult Size Array Object
  const [selectedAdultSizes, setSelectedAdultSizes] = useState({ 'S': 0, 'M': 0, 'L': 0, 'XL': 0, '2XL': 0, '4XL': 0 })
  // Youth Size Array Object
  const [selectedYouthSizes, setSelectedYouthSizes] = useState({ 'YXS': 0, 'YS': 0, 'YM': 0, 'YL': 0, 'YXL': 0 })

  // Adult Size
  const adultSize = ['S', 'M', 'L', 'XL', '2XL', '4XL']

  // Youth Size
  const youthSize = ['YXS', 'YS', 'YM', 'YL', 'YXL']

  const [activeState, setActiveState] = useState(0);

  const handleAdultSize = (key, value) => {
    const dummyValue = selectedAdultSizes
    dummyValue[key] = value
    setSelectedAdultSizes(dummyValue)
    console.log('selectedAdultSizes', selectedAdultSizes)
    handleTotalItem()
  }

  const handleYouthSize = (key, value) => {
    const dummyValue = selectedYouthSizes
    dummyValue[key] = value
    setSelectedYouthSizes(dummyValue)
    console.log('selectedYouthSizes', selectedYouthSizes)
    handleTotalItem()
  }

  const [totalItems, setTotalItems] = useState(0)
  // Accumulated Total Item
  const handleTotalItem = () => {
    let total = 0
    for (const key in selectedAdultSizes) {
      total += Number(selectedAdultSizes[key]) || 0
    }
    for (const key in selectedYouthSizes) {
      total += Number(selectedYouthSizes[key]) || 0
    }
    dispatch(incrementAsyncTotalItems(Number(total) || 0));
    setTotalItems(total)
  }

  // *save and share design dialog
  const [isSaveAndShareDialog, setIsSaveAndShareDialog] = useState(false);
  const saveAndShareDialogFormRef = useRef();
  const [value, setValues] = useState({
    name: "",
    email: "",
  });

  // *Save And Share Design Dialog
  const saveAndShareDesignDialog = () => {
    const boolean = isSaveAndShareDialog === true ? false : true;
    setIsSaveAndShareDialog(boolean);
    setValues({
      name: "",
      email: "",
    });
  };

  return (
    <Fragment>
      <Box sx={{ backgroundColor: colors.white, py: 10, px: 4, }}>
        {activeState === 0 ? (
          <Fragment>
            <Box>
              <Typography sx={{ fontSize: "12px", color: colors.primary }}>QUANTITY & SIZES</Typography>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", my: 1.5 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <ArrowBack onClick={setGetOrderPage} style={{ color: colors.black, marginRight: "12px", cursor: "pointer" }} />
                  <Typography sx={{ fontSize: "26px", color: colors.black, fontWeight: "bold" }}>Choose Quantity & Sizes</Typography>
                </Box>
                <Close sx={{ color: colors.black, cursor: "pointer" }} onClick={setGetOrderPage} />
              </Box>
              <Typography sx={{ fontSize: '13.6px', color: colors.textSecondary }}>Enter quantities to calculate the price. Save money by increasing quantity and reducing ink colors in your design.</Typography>
            </Box>
            <Divider sx={{ my: 2 }} />
            <Box sx={{ my: 2 }}>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                {sizesOpen ? (
                  <KeyboardArrowDown style={{ marginRight: 10, fontSize: "32px", cursor: "pointer", color: colors.black }} onClick={() => { handleSizeOpenStatus() }} />
                ) : (
                  <KeyboardArrowRight style={{ marginRight: 10, fontSize: "32px", cursor: "pointer", color: colors.black }} onClick={() => { handleSizeOpenStatus() }} />
                )}
                <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
                  <img src={Images.productOrder} alt="Product" />
                  <Box sx={{ ml: 2 }}>
                    <Typography sx={{ fontWeight: "bold", color: colors.black }}>RT2000 RushOrderTees Classic Tee</Typography>
                    <Typography>White</Typography>
                  </Box>
                </Box>
                <Typography sx={{ fontSize: "16px", color: colors.textSecondary }}>{totalItems} Items</Typography>
              </Box>
              <Divider sx={{ my: 2 }} />
              {sizesOpen &&
                <Box>
                  {/* Adult Size */}
                  <Box sx={{ my: 2 }}>
                    <Typography sx={{ fontWeight: "bold", color: colors.black, mb: 2 }}>Sizes</Typography>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mr: 4 }}>
                      {[...Array(Number(6))].map((item, index) => (
                        <Box key={index} sx={{ textAlign: "center" }}>
                          <Typography sx={{ fontSize: "14px", color: colors.textSecondary }}>{adultSize[index]}</Typography>
                          <Box sx={{ width: "70px" }}>
                            <TextField
                              defaultValue={selectedAdultSizes[adultSize[index]] !== 0 && selectedAdultSizes[adultSize[index]]}
                              onChange={(e) => { handleAdultSize(adultSize[index], e.target.value) }} className='myNumberType' type={"number"} size="small" variant="outlined" />
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                  {/* Youth Size */}
                  {/* <Box sx={{ my: 3 }}>
                    <Typography sx={{ fontWeight: "bold", color: colors.black, mb: 2 }}>Youth Size</Typography>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mr: 16 }}>
                      {[...Array(Number(5))].map((item, index) => (
                        <Box key={index} sx={{ textAlign: "center" }}>
                          <Typography sx={{ fontSize: "14px", color: colors.textSecondary }}>{youthSize[index]}</Typography>
                          <Box sx={{ width: "70px" }}>
                            <TextField
                              defaultValue={selectedYouthSizes[youthSize[index]] !== 0 && selectedYouthSizes[youthSize[index]]}
                              onChange={(e) => { handleYouthSize(youthSize[index], e.target.value) }} className='myNumberType' type={"number"} size="small" variant="outlined" />
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Box> */}
                </Box>
              }
            </Box>
            <Box sx={{ my: 6, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              {/* ========== Product Detail ========== */}
              <PrimaryButton disabled={totalItems === 0} endIcon={<ArrowForward />} onClick={() => { setActiveState(1) }} >
                Calculate Price
              </PrimaryButton>
              <SecondaryButton
                variant="outlined"
                className="button get-price"
                onClick={() => {
                  if (!user) {
                    setOpen(true);
                  }
                  else {
                    saveAndShareDesignDialog()
                  }
                }
                }
              >
                Save Design
              </SecondaryButton>
              {/* <PrimaryButton disabled={totalItems === 0} endIcon={<ArrowForward />} onClick={() => { setActiveState(1) }} >
                Calculate Price
              </PrimaryButton> */}
            </Box>
          </Fragment>
        ) : (
          <Fragment>
            <Box>
              <Typography sx={{ fontSize: "12px", color: colors.primary, mt: 3 }}>REVIEW YOUR ORDER</Typography>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", my: 1.5 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <ArrowBack style={{ color: colors.black, marginRight: "12px", cursor: "pointer" }} onClick={() => { setActiveState(0) }} />
                  <Typography sx={{ fontSize: "26px", color: colors.black, fontWeight: "bold" }}>Your Products & Pricing</Typography>
                </Box>
                <Close sx={{ color: colors.black, cursor: 'pointer' }} onClick={() => { setActiveState(0) }} />
              </Box>
              <Typography sx={{ fontSize: '13.6px', color: colors.textSecondary }}>Your order includes a professional design review, a satisfaction guarantee, and guaranteed on-time delivery.</Typography>
            </Box>
            <Divider sx={{ my: 2 }} />
            <Box sx={{ display: 'flex', alignItems: "center" }}>
              {/* <LocalShipping style={{ color: colors.primary, fontSize: "34px", marginRight: 12 }} /> */}
              <img src={Images.truck} style={{ width: "40px", marginRight: 12 }} alt="truck" />
              <Typography sx={{ color: colors.textSecondary }}><span style={{ fontweight: "bold" }} >Free</span> Delivery as soon as October 4 or <span style={{ fontweight: "bold" }} >Fast</span> Delivery on September 23</Typography>
            </Box>
            <Box sx={{ my: 3 }}>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
                  <img src={Images.productOrder} width="40px" alt="Product" />
                  <Box sx={{ ml: 2 }}>
                    <Typography sx={{ fontWeight: "bold", color: colors.black, fontSize: "18px" }}>RT2000 RushOrderTees Classic Tee</Typography>
                    <Typography>White</Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: "16px", color: colors.textSecondary }}>{totalItems} Items</Typography>
                  <Typography sx={{ fontSize: "16px", color: colors.textSecondary }}>
                    <span style={{ fontWeight: "bold", color: colors.black }}>$27</span> each</Typography>
                </Box>
              </Box>
              <Divider sx={{ my: 2 }} />
              <Box sx={{ my: 4, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Typography sx={{ fontSize: "18px", fontWeight: "bold", color: colors.black }}>Your Total ({totalItems} Items)</Typography>
                <Typography sx={{ fontSize: "18px", fontWeight: "bold", color: colors.black }}>${+totalItems * (27)}</Typography>
              </Box>
            </Box>
            <Box sx={{ my: 6 }}>
              <PrimaryButton disabled={totalItems === 0} endIcon={<ArrowForward />}
                onClick={(e) => { setOpen(true); dispatch(incrementAsyncTotalAdultSize(selectedAdultSizes)); dispatch(incrementAsyncTotalYouthSize(selectedYouthSizes)); saveAndShareDesignDialog(); }}
              >
                Add to Cart
              </PrimaryButton>
            </Box>
          </Fragment>
        )}

      </Box>
      {!user ? (
        <LoginDialog open={open} setOpen={setOpen} setSaveDesignOpen={setSaveDesignOpen} isSaveAndShareDialog={isSaveAndShareDialog} />
      ) : (
        <SaveAndShare
          isSaveAndShareDialog={isSaveAndShareDialog}
          saveAndShareDesignDialog={saveAndShareDesignDialog}
          saveAndShareDialogFormRef={saveAndShareDialogFormRef}
          saveAndShareDesign={saveAndShareDesign}
          addToCartStatus={true}
          loadingStatus={loadingStatus}
        />
      )}
      {saveDesignOpen &&
        <SaveAndShare
          isSaveAndShareDialog={isSaveAndShareDialog}
          saveAndShareDesignDialog={saveAndShareDesignDialog}
          saveAndShareDialogFormRef={saveAndShareDialogFormRef}
          saveAndShareDesign={saveAndShareDesign}
          addToCartStatus={true}
          loadingStatus={loadingStatus}
        />
      }
    </Fragment>
  )
}

export default OrderSection