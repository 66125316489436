import React from 'react'
import Layout from './../../layout/Layout/Layout';
import { Box, Grid, Typography } from '@mui/material';
import colors from './../../styles/colors';
import Images from '../../assets/Images';

function BecomePartner() {
  return (
    <Layout>
      <Box sx={{
        background: `linear-gradient(to right,rgba(0,0,0,0.5),rgba(0,0,0,0.5)),url(${Images.partnerBanner}) no-repeat top center / cover`,
        minHeight: 250,
        px: 5,
        py: { xs: 3, md: 0 },
        color: "white",
        display: "flex",
        alignItems: "center",
      }}>
        <Grid container justifyContent={"center"} spacing={8}>
          <Grid item xs={12} md={4}>
            <Box textAlign="center">
              <Typography sx={{ fontSize: "34px", fontWeight: "bold", color: colors.white }}>Become a Partner</Typography>
              <Typography sx={{ fontSize: "16px", color: colors.white }}>400,000+ Customers & Counting...</Typography>
            </Box>
          </Grid>
        </Grid>

      </Box>

      <Box sx={{ width: "70%", mx: "auto", my: 8 }}>
        <Typography sx={{ fontSize: "26px", fontWeight: "bold", color: colors.black }}>Interested in partnering with us?</Typography>
        <Typography sx={{ fontSize: "14px", color: colors.textSecondary, my: 3 }}>Becoming a RushOrderTees partner is a great way for your presses to earn their keep. We have streamlined the process to make it as easy as possible for our partners; we send you the shirts, your preferred file types and you ship through our system. No dealing with artwork, approvals, invoices or anything outside of the printing itself.</Typography>
        <Typography sx={{ fontSize: "14px", color: colors.textSecondary }}>To discuss partnerships, get in touch:</Typography>
        <Typography sx={{ fontSize: "14px", color: colors.primary, my: 2 }}><span style={{ fontWeight: "bold", color: colors.black }}>Email us: </span>info@tradetimegt.com</Typography>
        <Typography sx={{ fontSize: "14px", color: colors.primary }}><span style={{ fontWeight: "bold", color: colors.black }}>Call us: </span>+971-52-821-7664 / +971-52-946-5755</Typography>
      </Box>
    </Layout>
  )
}

export default BecomePartner