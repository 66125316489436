import React, { forwardRef, useState } from 'react';
import { Box, Slide, DialogTitle, DialogContent, Dialog, IconButton, Button, CardMedia, Typography } from '@mui/material';
import { Close } from '@material-ui/icons';
import { PrimaryButton } from '../../UI/Buttons';
import { CloudUpload } from '@mui/icons-material';
import Images from './../../../assets/Images';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddImageDialog({ openImageDialog, setImageDialog, getImageData }) {


  const handleClose = () => {
    setImageDialog(false);
  };

  return (
    <Dialog
      open={openImageDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"

    >
      <DialogTitle><IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton></DialogTitle>
      <DialogContent>
        <Box sx={{ my: 1.5 }}>
          <Box sx={{ border: `1.2px dashed #b3aeae`, borderRadius: 2, p: 4, textAlign: 'center' }}
            onChange={(e) => getImageData(e)}
          >
            <input
              hidden
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              multiple
            />

            <CardMedia
              component="img"
              alt="Upload"
              height="30"
              image={Images.toolUpload}
              sx={{ objectFit: 'contain' }}
            />

            <Typography sx={{ fontSize: 12 }} variant="body2" >
              Drag & Drop or
              <br />
              <Typography variant="body2" component="span" sx={{ color: '#1f51cf' }}>
                {" "}
                Browse{" "}
              </Typography>
              Your Computer
            </Typography>
          </Box>

          <Button onChange={(e) => { getImageData(e); handleClose() }} sx={{ mt: 1 }} startIcon={<CloudUpload />} variant="contained" component="label">
            Upload
            <input hidden accept="image/*" multiple type="file" />
          </Button>
          {/* <PrimaryButton fullWidth myStyle={{ mt: 3 }}>Add To Design</PrimaryButton> */}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
