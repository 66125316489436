import React, { useState } from 'react'
import { Box, Grid, Button, CardMedia, Container, Divider, FormControl, TextField, Typography, } from '@mui/material';
// import { Service } from 'app/config/service';
import { useForm } from 'react-hook-form';
import { PrimaryButton } from './../../components/UI/Buttons';
import { Error } from './../../components/UI/Error';
import colors from './../../styles/colors';
import { emailRegex } from '../../utils';
import Images from './../../assets/Images';
import Layout from './../../layout/Layout/Layout';

function ContactUs() {

  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  // *For User Contact
  const submitContactForm = async (userData) => {
    setLoading(true)
    try {
      let obj = {
        name: userData.name,
        phone_number: userData.number,
        email: userData.email,
        comment: userData.comment,
      }
      console.log('contact form', obj)
      // For API Call
      // const { status, responseCode } = await Service.submitContactForm(obj)
      // if (status === true && responseCode === 200) {
      //   toast.success('Form submit successfully!', {
      //     position: "top-center",
      //     autoClose: 1000,
      //     hideProgressBar: true,
      //     closeOnClick: false,
      //     pauseOnHover: false,
      //     draggable: false,
      //     progress: undefined,
      //   });
      //   reset()
      // }
    } catch (error) {
      console.log('file: Contact.js => line 35 => submitContactForm => error', error);

    } finally {
      setLoading(false)
    }
  };

  return (
    <Layout>
      <Container>
        <CardMedia
          image={Images.contactUs} sx={{ height: 300, borderRadius: '20px' }} >
        </CardMedia>

        <Box
          sx={{ padding: '20px 0px', boxShadow: `rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;`, margin: '20px 0px', backgroundColor: colors.lightGray, px: 2, boxSizing: 'border-box' }}>
          <Box sx={{ my: 1 }}>
            <Typography variant="h4"
              sx={{ textAlign: 'center', padding: '10px 0px', fontWeight: '600', color: '#222', }}>
              Contact Form
            </Typography>
            <Divider sx={{ bgcolor: colors.primary, width: '60px', padding: '1px', margin: 'auto', }} />
          </Box>
          <Typography component="form" onSubmit={handleSubmit(submitContactForm)}>
            <Grid container spacing={2} >
              <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="standard"  >
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Name"
                    error={errors?.email?.message && (true)}
                    {...register("name", {
                      required: 'Name is required',
                    })}
                  />
                  {errors?.name?.message && (
                    <Error message={errors?.name?.message} />
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="outlined" >
                  <TextField
                    variant="outlined"
                    label="Phone"
                    type="number"
                    error={errors?.phone?.message && (true)}
                    {...register("number", {
                      required: 'Number is required',
                      minLength: {
                        value: 11,
                        message: "Minimum 11 digits required"
                      }
                    })}
                  />
                  {errors?.number?.message && (
                    <Error message={errors?.number?.message} />
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth variant="standard"  >
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Email"
                    error={errors?.email?.message && (true)}
                    {...register("email", {
                      required: 'Email is required',
                      pattern: {
                        value: emailRegex,
                        message: 'Please enter a valid email address',
                      }
                    })}
                  />
                  {errors?.email?.message && (
                    <Error message={errors?.email?.message} />
                  )}
                </FormControl>

              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="standard"  >
                  <TextField
                    multiline rows={4}
                    variant="outlined"
                    type="text"
                    label="Message"
                    error={errors?.comment?.message && (true)}

                    {...register("comment", {
                      required: 'Message is required'
                    })}
                  />
                  {errors?.comment?.message && (
                    <Error message={errors?.comment?.message} />
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <PrimaryButton
                  type="submit"
                  fullWidth
                  // variant="contained"
                  loading={loading}
                // sx={{
                //   borderRadius: '30px',
                //   padding: '10px 25px',
                //   mt: '20px',
                //   textTransform: 'capitalize',
                // }}
                >
                  Submit
                </PrimaryButton>
              </Grid>
            </Grid>
          </Typography>
        </Box>
      </Container>
    </Layout >
  );
}

export default ContactUs;