import React, { useState, useEffect } from 'react'
import { Typography, Box, Grid, Divider, } from '@mui/material';
import Images from './../../assets/Images';
import { ArrowBack } from '@material-ui/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, } from "react-redux";
import Layout from './../../layout/Layout/Layout';
import { PrimaryButton } from './../UI/Buttons';
import { selectTotalAddToCart } from '../../features/counter/counterSlice';
import colors from '../../styles/colors';
import { Service } from './../../config/service';

function ViewOrderPage() {
  // Using Redux
  const navigate = useNavigate();
  const { id } = useParams();
  const [orderDetails, setOrderDetails] = useState([]);

  //* Get Order Details API
  const getInvoiceDetails = async () => {
    try {
      const { data } = await Service.getOrderDetailsInvoice(id)
      console.log("🚀 ~ file: ViewOrderPage.js ~ line 33 ~ getInvoiceDetails ~ data", data)
      setOrderDetails(data)
    } catch (error) {
      console.log("🚀 ~ file: ViewOrderPage.js ~ line 36 ~ //getInvoiceDetails ~ error", error)
    }
  }


  useEffect(() => {
    getInvoiceDetails()
  }, [id])


  return (
    <Layout>
      <Box sx={{ backgroundColor: colors.whiteSpace, py: 4, px: 4, height: "100%" }}>
        <Typography sx={{ textAlign: "center", fontSize: "32px", fontWeight: "bold", mb: 2 }}>Order Details</Typography>
        {orderDetails?.map((item, index) => (
          <Grid key={index} container spacing={2} >
            <Grid item xs={12} md={8}>
              <Grid container sx={{ border: `1px solid #e5e7ea`, p: 2, backgroundColor: colors.white }}>
                <Grid item xs={12} md={6} sx={{ mb: 1 }}>
                  <Typography variant="body2" sx={{ fontWeight: "bold", color: colors.black }}>Billed To:</Typography>
                  <Typography variant="body2" sx={{ color: colors.textSecondary, overflowWrap: 'break-word' }}>{item?.billing_to}</Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ mb: 1 }}>
                  <Typography variant="body2" sx={{ fontWeight: "bold", color: colors.black }}>Shipped To:</Typography>
                  <Typography variant="body2" sx={{ color: colors.textSecondary, overflowWrap: 'break-word' }}>{item?.shipping_to}</Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ mb: 1 }}>
                  <Typography variant="body2" sx={{ fontWeight: "bold", color: colors.black }}>Billing Address:</Typography>
                  <Typography variant="body2" sx={{ color: colors.textSecondary, overflowWrap: 'break-word' }}>{item?.billing_address}</Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ mb: 1 }}>
                  <Typography variant="body2" sx={{ fontWeight: "bold", color: colors.black }}>Shipping Address:</Typography>
                  <Typography variant="body2" sx={{ color: colors.textSecondary, overflowWrap: 'break-word' }}>{item?.shipping_address}</Typography>
                </Grid>
              </Grid>
              {/* Product Details */}
              <Box sx={{ border: `1px solid #e5e7ea`, py: 2, px: 2, alignItems: "center", backgroundColor: colors.white, my: 2 }}>
                <Box>
                  <Typography variant="body2" sx={{ fontWeight: "bold", color: colors.black }}>Design Name:</Typography>
                  <Typography variant="body2" sx={{ color: colors.textSecondary, overflowWrap: 'break-word' }}>{item?.design_name}</Typography>
                </Box>
                <Divider sx={{ my: 1.5 }} />
                <Box>
                  <Typography sx={{ fontSize: "12px" }}>
                    <span style={{ fontWeight: "bold", color: colors.textSecondary }}>PRODUCT: </span>
                    RT2000 by Design Tool Classic Tee
                  </Typography>
                  <Grid container sx={{ display: "flex", flexWrap: 'wrap', justifyContent: "space-between", my: 2 }}>
                    <Grid item xs={12} md={3.75} sx={{ display: 'flex', alignItems: 'center' }}>
                      {item?.product_images.map((image_detail, image_index) => (
                        <img src={image_detail?.image} style={{ width: "100px", marginRight: "8px" }} alt="Product Front" />
                      ))}
                    </Grid>
                    {/* <Box>
                      <img src={Images.cartProductImage} style={{ width: "100px", marginRight: "8px" }} alt="Product Front" />
                      <img src={Images.cartProductImageBack} width="100px" alt="Product Back" />
                    </Box> */}
                    <Grid item xs={12} md={6} sx={{ flexGrow: 1, pt: { xs: 2 }, boxSizing: 'border-box' }}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography sx={{ fontWeight: "bold", mr: 1, color: colors.textSecondary }}>
                          Color:
                        </Typography>
                        <Typography sx={{ backgroundColor: item?.product_color, width: '18px', height: '18px', borderRadius: "4px", border: `1px solid black` }}></Typography>
                        {/* <Typography sx={{ ml: 1, fontSize: "13px" }}>White</Typography> */}
                      </Box>
                      <Box>
                        <Typography sx={{ fontWeight: "bold", color: colors.textSecondary }}>
                          Size:
                        </Typography>
                        {item?.size &&
                          <Box sx={{ display: "flex", mb: 1, alignItems: "center" }}>
                            {item?.size?.map((value, index) => (
                              value?.quantity !== 0 &&
                              <Box key={index}>
                                <Typography sx={{ border: `1px solid ${colors.textSecondary}`, px: 0.5, boxSizing: 'border-box', color: colors.textSecondary, fontSize: "13px", mr: 0.5 }}>
                                  {value?.size} - {value?.quantity}</Typography>
                              </Box>
                            ))}
                          </Box>
                        }
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={2} sx={{ alignSelf: 'flex-end', pt: { xs: 2 }, boxSizing: 'border-box' }}>
                      <Typography sx={{ color: colors.textSecondary, fontSize: "12px" }}>{item?.total_quantity} items</Typography>
                      <Typography sx={{ color: colors.textSecondary, fontSize: "12px" }}>${item?.each_product_price} each</Typography>
                      <Typography sx={{ fontWeight: "bold", color: colors.black, fontSize: "14px" }}>${item?.total_amount}</Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ border: `1px solid #e5e7ea`, p: 2, alignItems: "center", backgroundColor: colors.white }}>
                <Typography sx={{ fontWeight: "bold", fontSize: "18px", color: colors.black }}>Order Summary</Typography>
                <Divider sx={{ my: 2 }} />
                <Box sx={{ mb: 1, display: "flex", flexWrap: 'wrap', alignItems: "center", justifyContent: "space-between" }}>
                  <Typography variant="body2" sx={{ fontWeight: "bold", color: colors.black }}>Order Number:</Typography>
                  <Typography variant="body2" sx={{ fontWeight: "bold" }} >{item?.order_no}</Typography>
                </Box>
                <Box sx={{ mb: 1, display: "flex", flexWrap: 'wrap', alignItems: "center", justifyContent: "space-between" }}>
                  <Typography variant="body2" sx={{ fontWeight: "bold", color: colors.black }}>Order Status:</Typography>
                  <Typography variant="body2" color={item?.status === "Verified" ? "green" : colors.primary} sx={{ fontWeight: "bold" }} >{item?.status}</Typography>
                </Box>
                <Box sx={{ mb: 1, display: "flex", flexWrap: 'wrap', alignItems: "center", justifyContent: "space-between" }}>
                  <Typography variant="body2" sx={{ fontWeight: "bold", color: colors.black }}>Order Issue Date:</Typography>
                  <Typography variant="body2" sx={{ color: colors.textSecondary }}>{item?.order_date}</Typography>
                </Box>
                <Box sx={{ mb: 1, display: "flex", flexWrap: 'wrap', alignItems: "center", justifyContent: "space-between" }}>
                  <Typography variant="body2" sx={{ fontWeight: "bold", color: colors.black }}>Subtotal ({item?.total_quantity} Items):</Typography>
                  <Typography variant="body2" sx={{ color: colors.textSecondary }}>${item?.total_quantity}</Typography>
                </Box>
                <Box sx={{ mb: 1, display: "flex", flexWrap: 'wrap', alignItems: "center", justifyContent: "space-between" }}>
                  <Typography variant="body2" sx={{ fontWeight: "bold", color: colors.black }}>Delivery Expected Date:</Typography>
                  <Typography variant="body2" sx={{ color: colors.textSecondary }}>{item?.delivery_date_expected}</Typography>
                </Box>
                <Box sx={{ mb: 1, display: "flex", flexWrap: 'wrap', alignItems: "center", justifyContent: "space-between" }}>
                  <Typography variant="body2" sx={{ fontWeight: "bold", color: colors.black }}>Delivery Charges:</Typography>
                  <Typography variant="body2" sx={{ color: colors.textSecondary }}>${item?.shipping_charges}</Typography>
                </Box>
                <Divider sx={{ my: 2 }} />
                <Box sx={{ display: "flex", flexWrap: 'wrap', alignItems: "center", justifyContent: "space-between" }}>
                  <Typography sx={{ fontSize: "16px", fontWeight: "bold", color: colors.primary }}>Total</Typography>
                  <Typography sx={{ fontSize: "16px", fontWeight: "bold", color: colors.primary }}>${item?.total_amount}</Typography>
                </Box>
              </Box>
              <PrimaryButton myStyle={{ mt: 1 }} onClick={() => {
                navigate('/order-details')
              }} fullWidth startIcon={<ArrowBack />}>
                Go Back
              </PrimaryButton>
            </Grid>
          </Grid>
        ))}
      </Box>
    </Layout>
  )
}

export default ViewOrderPage