import React, { useEffect } from 'react';
import { BrowserRouter, Outlet, Route, Routes, } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';

import AppRoutes from './routes/routes';
import colors from './styles/colors';
import PrivateRoutes from './routes/privateRoutes';
import { Navigate } from 'react-router-dom';
import useAuth from './hooks/useAuth';
import Login from './views/Auth/Login';
import Register from './views/Auth/Register';
import Toaster from './components/Toaster/Toaster';

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
      contrastText: '#fff',
    },
    secondary: {
      main: colors.secondary,
      contrastText: '#fff',
    },
  },
});

function App() {
  const { user, verify } = useAuth();
  const token = localStorage.getItem('jwt')

  useEffect(() => {
    verify()
  })


  return (
    <BrowserRouter>
      <Toaster />
      <ThemeProvider theme={theme}>

        <Routes>
          <Route element={!user ? <Outlet /> : <Navigate to="/" />} >
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
          </Route>
          {/* ========== Public Routes ========== */}
          {AppRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={route.component}
            />
          ))}
          {/* ========== Private Routes ========== */}
          <Route element={user || token ? <Outlet /> : <Navigate to="/login" />}>
            {PrivateRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={route.component}
              />
            ))}
          </Route>

        </Routes>

      </ThemeProvider>

    </BrowserRouter>
  );
}

export default App;
