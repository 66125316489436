import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ArrowForward, PersonOutlined } from '@mui/icons-material'
import { Avatar, Box, Button, Typography } from '@mui/material'
// * Import Component
// import { Service } from 'app/config/service'
import { makeStyles } from '@mui/styles'
import Swal from 'sweetalert2'
import useAuth from './../../hooks/useAuth';
import Images from '../../assets/Images'
import colors from '../../styles/colors'
import { Grid } from '@mui/material/';


const useStyles = makeStyles({
  image: {
    borderRadius: 2,
    objectFit: "contain"
  },
  labelStyle: {
    width: "100px",
    height: "100px",
    margin: "5px",
    borderRadius: "10px",
    overflow: "hidden",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
});


function ProfileDetail({ pageName, userName }) {

  const { logout } = useAuth()

  const classes = useStyles()

  const [selectedProfileImg, setSelectedProfileImg] = useState();

  // const [userData, setUserData] = useState("");
  const userData = {
    ProfilePath: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fsnappa.com%2Fcreate%2Fprofile-pictures&psig=AOvVaw2VHjumih-ZGhqUh7E0tEYq&ust=1664709582468000&source=images&cd=vfe&ved=0CAsQjRxqFwoTCJDx88T0vvoCFQAAAAAdAAAAABAE",
    FirstName: "Mr", LastName: "Johnathan"
  }
  // *For Cover Image
  const imageChange = (e) => {
    try {
      if (e.target.files && e.target.files.length > 0) {
        Swal.fire({
          icon: 'warning',
          html: "<p style='color: gray'>" + ' Are you sure you want to add/update profile picture' + "</p>",
          confirmButtonColor: colors.primary,
          showCancelButton: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            let formData = new FormData();
            formData.append("Upload", e.target.files[0]);
            // const { Status, Data, ResponseCode } = await Service.updateUserProfilePic(id, formData)
            // if (Status == true && ResponseCode === 201) {
            //   var imgUrl = "https://design-tools.optech.com" + Data
            //   setSelectedProfileImg(imgUrl);
            // }
          }

        });

      }
    } catch (error) {
      console.log("🚀 ~ file: ProfileDetails.js ~ line 68 ~ imageChange ~ error", error)
    }
  };

  // //* Get Order Details API
  // const getUserInfo = async () => {
  //   try {
  //     const { data } = await Service.getUserInfo(id)
  //     setUserData(data)
  //   } catch (error) {
  //     console.log('file: MyOrders.js => line 71 => getUserInfo => error', error);
  //   }
  // }

  // useEffect(() => {
  //   getUserInfo()

  // }, [])


  return (
    <Grid container sx={{ justifyContent: 'space-between', borderRadius: '8px', boxShadow: ` rgb(0 0 0 / 10%) 0px 4px 6px -1px, rgb(0 0 0 / 6%) 0px 2px 4px -1px;`, mb: 1, p: 2, }}>
      <Grid item xs={12} md={6} sx={{ display: 'flex', my: 'auto' }}>
        <img src={Images.user} width="20%" alt="User Profile" style={{ borderRadius: '8px' }} />

        {/* <Box sx={{ position: "relative", ml: 2 }}>
          <label className={classes.labelStyle} htmlFor='file-input'
          >
            {selectedProfileImg ? (
              <Avatar sx={{ width: 80, height: 80, bgcolor: "transparent" }}>
                <img
                  src={selectedProfileImg}
                  alt=""
                  width="100px"
                  height="100px"
                  className={classes.image}
                  onClick={imageChange}
                />
              </Avatar>
            ) :
              userData?.ProfilePath !== "" ? (
                <Avatar sx={{ width: 80, height: 80, bgcolor: "transparent" }}>
                  <img
                    src={userData?.ProfilePath}
                    alt=""
                    width="100%"
                    className={classes.image}
                    onClick={imageChange}
                  />
                </Avatar>

              ) :
                (<Avatar onClick={imageChange} sx={{ width: 60, height: 60, bgcolor: colors.primary }}></Avatar>)
            }
          </label>
          <input
            disabled={pathname === "/my-profile" ? false : true}
            style={{ display: "none" }}
            id="file-input"
            type="file"
            onChange={imageChange}
            accept="image/*"
          />
        </Box> */}
        <Box sx={{ my: 'auto', mx: 3 }}>
          <Typography variant="h6" sx={{ color: colors.black, fontWeight: 'bold' }}>{userName}</Typography>
          <Typography variant="body1" sx={{ color: colors.textSecondary }}>{pageName}</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={2} sx={{ my: 'auto', display: 'flex', justifyContent: 'flex-end', mt: { xs: 2 } }}>
        <Button

          variant='contained'
          endIcon={<ArrowForward />}
          onClick={() => logout()}
          sx={{
            bgcolor: colors.primary,
            '&:hover': {
              bgcolor: colors.primary,
            },
          }}
        >
          Logout
        </Button>
      </Grid>
    </Grid>
  )
}

export default ProfileDetail