import React, { forwardRef, useState } from 'react';
import { Box, Slide, DialogTitle, DialogContentText, DialogContent, DialogActions, Dialog, Button, IconButton, TextField, Typography } from '@mui/material';
import { Close } from '@material-ui/icons';
import { PrimaryButton } from '../../UI/Buttons';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddTextDialog({ openTextDialog, setTextDialog, addToDesign, handleTextFontSize, setTextValue }) {


  const handleClose = () => {
    setTextDialog(false);
  };

  return (
    <Box>
      <Dialog
        open={openTextDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ display: "flex", alignItems: 'center', justifyContent: "space-evenly" }}>
          <Typography sx={{ fontWeight: 'bold' }}>Add Text To Design</Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton></DialogTitle>
        <DialogContent>
          <Box sx={{ my: 1.5 }}>
            <TextField onChange={(e) => setTextValue(e, "add")} size='small' fullWidth id="outlined-basic" label="Enter Your Text" variant="outlined" />
            <PrimaryButton fullWidth myStyle={{ mt: 3 }}
              onClick={() => {
                addToDesign();
                handleTextFontSize();
                handleClose();
              }}
            >Add </PrimaryButton>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
