import React, { useState, useEffect } from 'react'
import { Add } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import colors from './../../styles/colors';
import Images from './../../assets/Images';
import { Service } from './../../config/service';
import { useSearchParams, useParams } from "react-router-dom";

// Color Box Select Styling
const useStyles = makeStyles({
  colorBox: {
    width: "30px",
    height: "30px",
    boxShadow: 'rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;',
    borderRadius: "5px",
    margin: "0px 10px"
  },
  selectedColorBox: {
    width: "30px",
    height: "30px",
    boxShadow: 'rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;',
    borderRadius: "5px",
    margin: "5px",
    cursor: "pointer"
  },
  tick: {
    position: "absolute",
    cursor: 'pointer',
    top: 5, left: 5,
    color: "white",
    width: "30px"
  }
})

function Filter({ id, getProductDetailsByFilter }) {

  const { prod_id } = useParams();
  console.log("🚀 ~ file: Filter.js ~ line 39 ~ Filter ~ prod_id", prod_id)
  // Use Params
  let [searchParams, setSearchParams] = useSearchParams();

  // Color Platte
  const classes = useStyles()
  const [selectedColor, setSelectedColor] = useState("#000");

  //* Get Products By Sub Category ID API
  const [filterDetails, setFilter] = useState([])
  const [filterKeys, setFilterKeys] = useState([])
  // console.log("🚀 ~ file: Filter.js ~ line 54 ~ Filter ~ filterDetails",filterDetails )
  const getProductFilterSubCatByCatID = async (id) => {
    console.log("🚀 ~ file: SubCategory.js ~ line 26 ~ getProductSubCatByCatID ~ id", id)
    try {
      const { filters } = await Service.getProductsBySubCatID(id)
      console.log("🚀 ~ file: Filter.js ~ line 60 ~ getProductFilterSubCatByCatID ~ filters", filters)
      console.log("🚀 ~ file: Filter.js ~ line 59 ~ getProductFilterSubCatByCatID ~ filters keys", Object.keys(filters[0]))
      setFilter(filters)
      setFilterKeys(Object.keys(filters[0]))
    } catch (error) {
      console.log("🚀 ~ file: Filter.js ~ line 62 ~ getProductFilterSubCatByCatID ~ error", error)
    }
  }

  // Handle Search Param Function
  const [colorID, setColorId] = useState(null);
  const [brandID, setBrandId] = useState(null);
  const [sizeID, setSizeId] = useState(null);
  const [fabricID, setFabricId] = useState(null);

  const handleSearchParam = () => {
    searchParams.set("limit", 10)
    searchParams.set("page", 1)
    searchParams.set("subcat_id", prod_id)
    if (brandID) {
      console.log('brandID', brandID)
      searchParams.set("brand_id", brandID)
    }
    if (fabricID) {
      console.log('fabricID', fabricID)
      searchParams.set("fabric_id", fabricID)
    }
    if (colorID) {
      console.log('colorID', colorID)
      searchParams.set("color_id", colorID)
    }
    if (sizeID) {
      console.log('sizeID', sizeID)
      searchParams.set("size_id", sizeID)
    }
    setSearchParams(searchParams)
    // getProductDetailsByFilter(searchParams)
  }

  // Check Box
  const [selectedCheckBox, setSelectedCheckBox] = useState('')
  const handleChangeCheckBox = (id, key, key_id) => {
    console.log("🚀 ~ file: Filter.js ~ line 97 ~ handleChangeCheckBox ~ key", key)
    setSelectedCheckBox(id);
    if (key === 'colors') {
      setColorId(key_id);
    }
    else if (key === 'colors') {
      setBrandId(key_id);
    }
    else if (key === "sizes") {
      setSizeId(key_id);
    }
    else if (key === "brand") {
      setBrandId(key_id);
    }
    else if (key === "fabric") {
      setFabricId(key_id);
    }
    handleSearchParam();
  }

  useEffect(() => {
    getProductFilterSubCatByCatID(id);
  }, [])

  useEffect(() => {
    handleSearchParam();
  }, [colorID, brandID, sizeID, fabricID])


  return (
    <Box>
      {filterKeys.map((item, index) => {
        if (item === "colors") {
          return (
            <Accordion elevation={0}>
              <AccordionSummary expandIcon={<Add sx={{ color: colors.primary }} />}>
                <Typography variant="body1">{item.charAt(0).toUpperCase() + item.slice(1)}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ display: "flex", alignItems: "center", flexWrap: 'wrap', my: 2 }}>
                  {filterDetails[0][item][0].map((colorCode, index) => (
                    <Box sx={{ position: "relative" }} key={index}>
                      <Box className={classes.selectedColorBox} sx={{ bgcolor: `${colorCode.color_code}` }} onClick={() => {
                        setSelectedColor(colorCode.color_code); handleChangeCheckBox(colorCode.name, item, colorCode.name_id)
                      }}></Box>
                      {colorCode.color_code === selectedColor && <img src={Images.Tick} alt="" className={classes.tick} />}
                    </Box>
                  ))}
                </Box>
              </AccordionDetails>
            </Accordion>
          )
        }
        else {
          return (
            <Accordion key={index} elevation={0}>
              <AccordionSummary expandIcon={<Add sx={{ color: colors.primary }} />}>
                <Typography variant="body1">{item.charAt(0).toUpperCase() + item.slice(1)}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {filterDetails[0][item]?.map((value, index) => {
                  return value.map((subItem, subIndex) => {
                    return (
                      <Box key={subIndex} sx={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={() => handleChangeCheckBox(subItem.name, item, subItem.name_id)}>
                        <FormGroup>
                          <FormControlLabel control={<Checkbox
                            // value={subItem.name}
                            onChange={() => handleChangeCheckBox(subItem.name, item, subItem.name_id)}
                            checked={selectedCheckBox === subItem.name}
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 24 }, width: "100%", color: colors.primary }} />} />
                        </FormGroup>
                        <Typography sx={{ fontSize: 14 }}>{subItem.name}</Typography>
                      </Box>
                    )
                  })
                })}
              </AccordionDetails>
            </Accordion>
          )
        }
      }
      )}
    </Box>
  )
}

export default Filter