import React, { useState } from 'react'
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Images from '../../assets/Images';

// Color Box Select Styling
const useStyles = makeStyles({
  colorBox: {
    width: "30px",
    height: "30px",
    boxShadow: 'rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;',
    borderRadius: "5px",
    margin: "0px 10px"
  },
  selectedColorBox: {
    width: "30px",
    height: "30px",
    boxShadow: 'rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;',
    borderRadius: "5px",
    margin: "5px",
    cursor: "pointer"
  },
  tick: {
    position: "absolute",
    cursor: 'pointer',
    top: 5, left: 5,
    color: "white",
    width: "30px"
  }
})

// Color List
const colorsList = ["#000", "#fff", "#DFFF00", "#FFBF00", "#DE3163", "#6495ED",]

function ColorBox({ colorName }) {
  // Color Platte
  const classes = useStyles()
  const [selectedColor, setSelectedColor] = useState("#000");
  return (
    <Box sx={{ display: "flex", alignItems: "center", flexWrap: 'wrap', my: 2 }}>
      {colorName.map((item, index) => (
        <Box key={index} sx={{ position: "relative" }} >
          <Box className={classes.selectedColorBox} sx={{ bgcolor: `${item.color_code}` }} onClick={() => {
            setSelectedColor(`${item}`)
          }}></Box>
          {item.color_code === selectedColor && <img src={Images.Tick} alt="" className={classes.tick} />}
        </Box>
      ))}
    </Box>
  )
}

export default ColorBox