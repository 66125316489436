import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addOriginalImageCanvas: null,
  addDesignDetails: { designName: null, designEmail: null },
  totalAdultSizes: null,
  totalYouthSizes: null,
  totalAmountToPay: 0,
  subCategory: {},
  deliveryDetails: { deliveryDays: null, deliveryFeeToPay: 0 },
  totalDetails: { totalItems: 0, eachItemPrice: 27 },
  orderSaveDetails: null,
  totalAddToCart: [],
  startDesigningDetails: [],
  productType: null,
};

export const counterSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    setAddOriginalImageCanvas: (state, action) => {
      state.addOriginalImageCanvas = action.payload;
    },
    setAddDesignName: (state, action) => {
      state.addDesignDetails.designName = action.payload;
    },
    setAddDesignEmail: (state, action) => {
      state.addDesignDetails.designEmail = action.payload;
    },
    setTotalAdultSize: (state, action) => {
      state.totalAdultSizes = action.payload;
    },
    setTotalYouthSize: (state, action) => {
      state.totalYouthSizes = action.payload;
    },
    setTotalItems: (state, action) => {
      state.totalDetails.totalItems = action.payload;
    },
    setEachItemsPrice: (state, action) => {
      state.totalDetails.eachItemPrice = action.payload;
    },
    setTotalAmountToPay: (state, action) => {
      state.totalAmountToPay = action.payload;
    },
    setSubCategoryDetails: (state, action) => {
      state.subCategory = [...state.subCategory, action.payload];
    },
    setDeliveryFeeToPay: (state, action) => {
      state.deliveryDetails.deliveryFeeToPay = action.payload;
    },
    setDeliveryDays: (state, action) => {
      state.deliveryDetails.deliveryDays = action.payload;
    },
    setOrderSaveDetails: (state, action) => {
      state.orderSaveDetails = action.payload;
    },
    setTotalAddToCart: (state, action) => {
      state.totalAddToCart = [...state.totalAddToCart, action.payload];
    },
    setTotalAddToCartItemsAfterRemove: (state, action) => {
      state.totalAddToCart = action.payload;
    },
    setStartDesigningDetails: (state, action) => {
      state.startDesigningDetails = action.payload;
    },
    setProductType: (state, action) => {
      state.productType = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const incrementAsyncTotalItems = (amount) => (dispatch) => {
  dispatch(setTotalItems(amount))
}
export const incrementAsyncTotalAdultSize = (amount) => (dispatch) => {
  dispatch(setTotalAdultSize(amount))
}
export const incrementAsyncTotalYouthSize = (amount) => (dispatch) => {
  dispatch(setTotalYouthSize(amount))
}
export const {
  setAddOriginalImageCanvas,
  setAddDesignName,
  setAddDesignEmail,
  setTotalItems,
  setEachItemsPrice,
  setTotalAdultSize,
  setTotalYouthSize,
  setTotalAmountToPay,
  setSubCategoryDetails,
  setDeliveryFeeToPay,
  setDeliveryDays,
  setOrderSaveDetails,
  setTotalAddToCart,
  setTotalAddToCartItemsAfterRemove,
  setStartDesigningDetails,
  setProductType,
} = counterSlice.actions;
export const selectAddOriginalImageCanvas = (state) => state.counter.addOriginalImageCanvas;
export const selectAddDesignDetails = (state) => state.counter.addDesignDetails;
export const selectTotalDetails = (state) => state.counter.totalDetails;
export const selectTotalAdultSizes = (state) => state.counter.totalAdultSizes;
export const selectTotalYouthSizes = (state) => state.counter.totalYouthSizes;
export const selectTotalAmountToPay = (state) => state.counter.totalAmountToPay;
export const selectSubCategory = (state) => state.counter.subCategory;
export const selectDeliveryDetails = (state) => state.counter.deliveryDetails;
export const selectOrderSaveDetails = (state) => state.counter.orderSaveDetails;
export const selectTotalAddToCart = (state) => state.counter.totalAddToCart;
export const selectStartDesigningDetails = (state) => state.counter.startDesigningDetails;
export const selectProductType = (state) => state.counter.productType;
export default counterSlice.reducer;
