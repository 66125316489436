import React, { Fragment, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Collapse,
  Container,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  tooltipClasses,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ExpandLess, ExpandMore, FavoriteBorder, ListAlt, Person, ShoppingCartOutlined } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import clsx from "clsx";

//*import Component
import { useSelector } from 'react-redux';
import { selectTotalAddToCart } from './../../features/counter/counterSlice';
import { PrimaryButton, SecondaryButton } from './../../components/UI/Buttons';
import useAuth from './../../hooks/useAuth';
import colors from './../../styles/colors';
import { styled } from '@mui/material/styles';
import Images from './../../assets/Images';

const useStyle = makeStyles({
  navItems: {
    color: colors.black,
    textDecoration: "none",
    transition: "0.3s ease-in-out",
    "&:hover": {
      color: colors.primary,
    },
  },
  active: {
    color: colors.primary,
  },
  headerFixed: {
    position: "sticky",
    top: 0,
    left: "0",
    width: "100%",
    boxSizing: "border-box",
    backgroundColor: "#fff",
    "&.jss4": {
      width: '100% !important',
    }

  },
  menuOptions: {
    "&:hover": {
      transition: '0.2s all ease-in',
      color: colors.white,
      backgroundColor: colors.primary,
    }
  }
});

// custom shop tooltip
const CustomShopTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    minWidth: 170,
    backgroundColor: colors.white,
    boxShadow: `rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;`,
    textAlign: "center",
    cursor: 'pointer',
    marginLeft: 60,
    background: colors.white,
    // border: `1px solid ${colors.textSecondary}`,
    "&>.MuiTooltip-arrow": {
      "&:before": {
        // border: `1px solid ${colors.textSecondary}`,
        color: colors.white,
        display: "none",
      }
    }
  },
});
// custom about tooltip
const CustomAboutTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    minWidth: 170,
    backgroundColor: colors.white,
    boxShadow: `rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;`,
    textAlign: "center",
    cursor: 'pointer',
    marginLeft: 100,
    background: colors.white,
    // border: `1px solid ${colors.textSecondary}`,
    "&>.MuiTooltip-arrow": {
      "&:before": {
        // border: `1px solid ${colors.textSecondary}`,
        color: colors.white,
        display: "none",
      }
    }
  },
});

function Header() {
  const classes = useStyle();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user, logout } = useAuth();
  const userName = localStorage.getItem("userName");
  const userEmail = localStorage.getItem("userEmail");
  const token = localStorage.getItem("jwt")
  const totalAddToCart = useSelector(selectTotalAddToCart);

  //*Account Hover Section Constant*/
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Header Dropdown
  const [openProductDropDown, setOpenProductDropDown] = useState(false);
  const [openAboutDropDown, setOpenAboutDropDown] = useState(false);
  const [openUserProfileDropDown, setOpenUserProfileDropDown] = useState(false);

  const handleExpandedProductList = () => {
    setOpenProductDropDown(!openProductDropDown);
  };

  const handleExpandedAboutList = () => {
    setOpenAboutDropDown(!openAboutDropDown);
  };

  const handleExpandedUserProfileList = () => {
    setOpenUserProfileDropDown(!openUserProfileDropDown);
  };

  //*Header Hide on Small Screen
  const [showResults, setShowResults] = useState(false);

  const menuList = (
    <Grid container spacing={0} alignItems="center" justifyContent="space-between">
      <Grid
        item
        xs={12}
        md={9}
        sx={{
          textAlign: { xs: "center", md: "left" },
          display: "flex", flexGrow: 1,
          // alignItems: 'center',
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box onClick={() => navigate('/design-tool')} sx={{ textAlign: "center", cursor: "pointer", mx: 2, display: { xs: 'none', md: 'block' } }}>
          <Typography variant="body1" fontWeight="bold" color={colors.primary}>Create</Typography>
          <Typography color="gray" fontSize="12px" >Custom Branding</Typography>
        </Box>
        <Box onClick={() => navigate('/design-tool')} sx={{ textAlign: '-webkit-center', display: { xs: 'block', md: 'none' } }}>
          <Typography className={clsx(classes.navItems, {
            [classes.active]: pathname === "/design-tool",
          })} variant="body1" sx={{ maxWidth: 175, textAlign: 'left' }} >Create</Typography>
        </Box>
        <Box sx={{ alignSelf: 'center' }}>
          <List
            sx={{ minWidth: 210, bgcolor: 'background.paper', display: { xs: 'block', md: 'none' } }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            {/* Shop */}
            <ListItemButton onClick={handleExpandedProductList}>
              <ListItemText className={clsx(classes.navItems, {
                [classes.active]: pathname === "/category",
              })} primary="Shop" />
              {openProductDropDown ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openProductDropDown} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton>
                  <ListItemText onClick={() => navigate('/category')} primary={<Typography sx={{ fontSize: '14px' }}>T-Shirts</Typography>} />
                </ListItemButton>
                <ListItemButton>
                  <ListItemText onClick={() => navigate('/category')} primary={<Typography sx={{ fontSize: '14px' }}>Polos</Typography>} />
                </ListItemButton>
                <ListItemButton>
                  <ListItemText onClick={() => navigate('/category')} primary={<Typography sx={{ fontSize: '14px' }}>Bags</Typography>} />
                </ListItemButton>
                <ListItemButton>
                  <ListItemText onClick={() => navigate('/category')} primary={<Typography sx={{ fontSize: '14px' }}>Caps</Typography>} />
                </ListItemButton>
                <ListItemButton>
                  <ListItemText onClick={() => navigate('/category')} primary={<Typography sx={{ fontSize: '14px' }}>Uniforms</Typography>} />
                </ListItemButton>
              </List>
            </Collapse>
            {/* About US */}
            <ListItemButton onClick={handleExpandedAboutList}>
              <ListItemText className={clsx(classes.navItems, {
                [classes.active]: pathname === "/who-we-are" || pathname === "/chairman-message" || pathname === "/become-partner",
              })} primary="About Us" />
              {openAboutDropDown ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openAboutDropDown} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton>
                  <ListItemText onClick={() => navigate('/who-we-are')} className={clsx(classes.navItems, {
                    [classes.active]: pathname === "/who-we-are",
                  })} primary={<Typography sx={{ fontSize: '14px' }}>Who we are</Typography>} />
                </ListItemButton>
                <ListItemButton>
                  <ListItemText onClick={() => navigate('/chairman-message')} className={clsx(classes.navItems, {
                    [classes.active]: pathname === "/chairman-message",
                  })} primary={<Typography sx={{ fontSize: '14px' }}>Chairman Message</Typography>} />
                </ListItemButton>
                <ListItemButton>
                  <ListItemText onClick={() => navigate('/become-partner')} className={clsx(classes.navItems, {
                    [classes.active]: pathname === "/become-partner",
                  })} primary={<Typography sx={{ fontSize: '14px' }}>Become a partner</Typography>} />
                </ListItemButton>
              </List>
            </Collapse>
            {/* Profile */}
            {user || token ?
              (<>
                <ListItemButton onClick={handleExpandedUserProfileList}>
                  <ListItemText className={clsx(classes.navItems, {
                    [classes.active]: pathname === "/order-details" || pathname === "/profile-details" || pathname === "/order-invoice/:id",
                  })} primary="User Profile" />
                  {openAboutDropDown ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openUserProfileDropDown} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton>
                      <ListItemText onClick={() => navigate('/profile-details')} className={clsx(classes.navItems, {
                        [classes.active]: pathname === "/profile-details",
                      })} primary={<Typography sx={{ fontSize: '14px' }}>My Profile</Typography>} />
                    </ListItemButton>
                    <ListItemButton>
                      <ListItemText onClick={() => navigate('/order-details')} className={clsx(classes.navItems, {
                        [classes.active]: pathname === "/order-details",
                      })} primary={<Typography sx={{ fontSize: '14px' }}>Order Details</Typography>} />
                    </ListItemButton>
                  </List>
                </Collapse>
              </>) : ''}
          </List>
        </Box>
        <Box sx={{ textAlign: '-webkit-center', display: { xs: 'block', md: 'none' } }}>
          <Typography onClick={() => navigate('/contact-us')} className={clsx(classes.navItems, {
            [classes.active]: pathname === "/contact-us",
          })} variant="body1" sx={{ maxWidth: 175, textAlign: 'left' }} >Contact Us</Typography>
        </Box>
        <Box sx={{ mx: 2, display: { xs: 'none', md: 'block' } }}>
          <CustomShopTooltip title={
            <Fragment>
              <Typography onClick={() => { navigate('/category') }} className={classes.menuOptions} sx={{ textAlign: "center", cursor: "pointer", fontSize: "14px", py: 1 }} color={colors.primary}>T-Shirts</Typography>
              <Divider />
              <Typography onClick={() => { navigate('/category') }} className={classes.menuOptions} sx={{ textAlign: "center", cursor: "pointer", fontSize: "14px", py: 1 }} color={colors.primary}>Polos</Typography>
              <Divider />
              <Typography onClick={() => { navigate('/category') }} className={classes.menuOptions} sx={{ textAlign: "center", cursor: "pointer", fontSize: "14px", py: 1 }} color={colors.primary}>Bags</Typography>
              <Divider />
              <Typography onClick={() => { navigate('/category') }} className={classes.menuOptions} sx={{ textAlign: "center", cursor: "pointer", fontSize: "14px", py: 1 }} color={colors.primary}>Caps</Typography>
              <Divider />
              <Typography onClick={() => { navigate('/category') }} className={classes.menuOptions} sx={{ textAlign: "center", cursor: "pointer", fontSize: "14px", py: 1 }} color={colors.primary}>Uniforms</Typography>
            </Fragment>
          } arrow>
            <Box onClick={() => { navigate('/category') }} sx={{ textAlign: "center", cursor: "pointer", }}>
              <Typography variant="body1" fontWeight="bold" color={colors.primary}>Shop</Typography>
              <Typography color="gray" fontSize="12px" >Marketplace Designs</Typography>
            </Box>
          </CustomShopTooltip>
        </Box>
        <Box sx={{ mx: 2, display: { xs: 'none', md: 'block' } }}>
          <CustomAboutTooltip title={
            <Fragment>
              <Typography onClick={() => navigate('/who-we-are')} className={classes.menuOptions} sx={{ textAlign: "center", cursor: "pointer", fontSize: "14px", py: 1 }} color={colors.primary}>Who we are </Typography>
              <Divider />
              <Typography onClick={() => navigate('/chairman-message')} className={classes.menuOptions} sx={{ textAlign: "center", cursor: "pointer", fontSize: "14px", py: 1 }} color={colors.primary}>Chairman Message </Typography>
              <Divider />
              <Typography onClick={() => navigate('/become-partner')} className={classes.menuOptions} sx={{ textAlign: "center", cursor: "pointer", fontSize: "14px", py: 1 }} color={colors.primary}>Become a partner </Typography>
            </Fragment>
          } arrow>
            <Typography sx={{ textAlign: "center", cursor: "pointer" }} variant="body1" fontWeight="bold" color={colors.primary}>About Us</Typography>
          </CustomAboutTooltip>
        </Box>
        <Box sx={{ mx: 2, display: { xs: 'none', md: 'block' } }} >
          <Typography onClick={() => { navigate('/contact-us') }} sx={{ textAlign: "center", cursor: "pointer" }} variant="body1" fontWeight="bold" color={colors.primary}>Contact Us</Typography>
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        md={1.5}
        sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: { xs: "center ", md: "right" }, my: 1.5 }}
      >
        {user || token ? (
          <Fragment>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", mr: 3 }}>
              <FavoriteBorder sx={{ ml: 1.25, cursor: "pointer" }} />
              {totalAddToCart.length > 0 ? (
                <Badge badgeContent={totalAddToCart.length} color="primary" sx={{ cursor: "pointer" }}>
                  <ShoppingCartOutlined onClick={() => { navigate('/cart') }} sx={{ ml: 1.25, cursor: "pointer" }} />
                </Badge>
              ) : (
                <ShoppingCartOutlined onClick={() => { navigate('/cart') }} sx={{ ml: 1.25, cursor: "pointer" }} />
              )}

            </Box>
            <Box sx={{ display: { xs: 'none', md: 'block' } }} >
              <Box sx={{ cursor: "pointer", }} >
                <Avatar src={Images.userProfile} onClick={(e) => { handleClick(e) }} />
              </Box>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    width: '350px',
                    p: 2,
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 60,
                      height: 60,
                      ml: 1,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <Typography sx={{
                  fontSize: '16px', fontWeight: 500, color: colors.black,
                  p: 1.5
                }}>User Profile</Typography>
                <Box sx={{ display: 'flex', alignItems: "center", cursor: "pointer" }}>
                  <Avatar src={Images.userProfile} sx={{ borderRadius: '50px' }} />
                  <Box>
                    <Typography
                      sx={{
                        fontSize: '18px',
                        fontWeight: "bold"
                      }}>{userName}</Typography>
                    <Typography
                      sx={{
                        fontSize: '14px',
                        fontWeight: 500,
                        color: colors.textSecondary
                      }}>{userEmail}</Typography>
                  </Box>
                </Box>
                <Divider sx={{ my: 1 }} />
                <MenuItem onClick={() => { navigate('/profile-details') }} sx={{ p: 1, borderRadius: "8px" }} >
                  <Box sx={{ display: 'flex' }}>
                    <Button sx={{
                      bgcolor: colors.primaryLight,
                      mr: 1,
                      borderRadius: '10px',
                      '&:hover': {
                        bgcolor: colors.primaryLight,
                      }
                    }}><Person fontSize="small" />
                    </Button>
                    <Box >
                      <Typography
                        sx={{
                          fontSize: '1rem',
                        }}>My Profile</Typography>
                      <Typography variant="h6"
                        sx={{
                          fontSize: '0.875rem',
                          color: colors.textSecondary
                        }}>Account Settings</Typography>
                    </Box>
                  </Box>
                </MenuItem>
                <Divider sx={{ my: 1 }} />
                <MenuItem onClick={() => { navigate('/order-details') }} sx={{ p: 1, borderRadius: "8px", mb: 1.5 }} >
                  <Box sx={{ display: 'flex' }} >
                    <Button
                      sx={{
                        bgcolor: colors.primaryLight,
                        mr: 1,
                        borderRadius: '10px',
                        '&:hover': {
                          bgcolor: colors.primaryLight,
                        }
                      }}><ListAlt fontSize="small" />
                    </Button>
                    <Box>
                      <Typography variant="h5"
                        sx={{
                          fontSize: '1rem'
                        }}>Orders</Typography>
                      <Typography variant="h6"
                        sx={{
                          fontSize: '0.875rem',
                          color: colors.textSecondary
                        }}>Order Details</Typography>
                    </Box>
                  </Box>
                </MenuItem>
                <PrimaryButton onClick={() => logout()} fullWidth >
                  Logout
                </PrimaryButton>
              </Menu>
            </Box>
            {/* <Box sx={{ display: { xs: 'block', md: 'none' } }}>

            </Box> */}
            <Box sx={{ display: { xs: 'block', md: 'none' }, textAlign: 'center', my: 1.5, cursor: 'pointer' }}>
              <SecondaryButton onClick={() => { logout() }}>Logout</SecondaryButton>
            </Box>
          </Fragment>
        ) : (
          <Box sx={{ textAlign: 'center', my: 1.5, cursor: "pointer" }} >
            <SecondaryButton onClick={() => { navigate('/login') }}>Login</SecondaryButton>
          </Box>
        )}
      </Grid>
    </Grid >
  );

  return (
    <Box
      className={classes.headerFixed}
      component="header"
      sx={{ transition: '0.3s all ease', bgcolor: colors.white, boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px", zIndex: 999 }}
    >
      <Container sx={{ position: "relative" }}>
        <Grid container spacing={0} alignItems="center" justifyContent={'space-between'}>
          <Grid item xs={6} sm={11} md={1.5}>
            <img onClick={() => navigate('/')} src={Images.logo} alt="" width="100%" height="70px" style={{ objectFit: "contain", cursor: "pointer", marginRight: 3 }} />
          </Grid>

          <MenuIcon
            sx={{
              color: colors.primary,
              display: {
                xs: "block",
                md: "none",
              },
              fontSize: "40px",
            }}
            onClick={() => setShowResults(!showResults)}
          />
          {showResults ? (
            <Box
              sx={{
                width: {
                  md: "80%",
                  xs: "100%",
                },
              }}
            >
              {menuList}
            </Box>
          ) : (
            <Box
              sx={{
                display: {
                  xs: "none",
                  md: "block",
                },
                width: "80%",
              }}
            >
              {menuList}
            </Box>
          )}
        </Grid>
      </Container>
    </Box>
  );
}

export default Header;
