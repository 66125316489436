import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './index.scss';
import App from './App';
import { store } from './store';
import { Provider } from 'react-redux';
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import reportWebVitals from './reportWebVitals';
import AuthProvider from './hoc/AuthProvider';

let persistor = persistStore(store);

ReactDOM.render(
  <AuthProvider>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </AuthProvider>
  , document.getElementById('root')
);

reportWebVitals();
