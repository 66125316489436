import React, { useState, useEffect } from 'react';
import { Container, Grid, Typography, Box, Divider } from '@mui/material';
import { Phone, WhatsApp, ThumbUpOffAlt, LocalShippingOutlined, SavingsOutlined, DesignServicesOutlined, ArrowForward } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

// *Import Components
import colors from '../../styles/colors'
import Layout from '../../layout/Layout/Layout';
import { PrimaryButton, SecondaryButton } from '../../components/UI/Buttons';
import { useNavigate } from 'react-router-dom';
import Images from './../../assets/Images';
import HomeCarousel from './../../components/HomeCarousel/HomeCarousel';
import { Service } from '../../config/service';

const useStyle = makeStyles({
  productImages: {
    cursor: "pointer",
    backgroundSize: 'cover',
    display: 'block',
    transition: '0.2s all ease-in',
    "&:hover": {
      transform: 'scale(1.2)',
      boxSizing: 'border-box',
      transition: '0.2s all ease-in',
    }
  }

})

const brandSVG = [
  Images.Brand1, Images.Brand2, Images.Brand3, Images.Brand4, Images.Brand5, Images.Brand6, Images.Brand7, Images.Brand8,
  Images.Brand9, Images.Brand10, Images.Brand11, Images.Brand12, Images.Brand13, Images.Brand14, Images.Brand15
]

function Home() {
  const classes = useStyle();
  const navigate = useNavigate();

  // For Api Call
  const [productDetails, setProductDetails] = useState([]);
  console.log("🚀 ~ file: Home.js ~ line 38 ~ Home ~ productDetails", productDetails)

  //* Get Product Details API
  const getProductCategory = async () => {
    try {
      const { data } = await Service.getProductCategory()
      console.log("🚀 ~ file: Home.js ~ line 44 ~ getProductCategory ~ data", data)
      let obj = [data[0], data.slice(1, 3), data.slice(3, 6)]
      setProductDetails(obj)
    } catch (error) {
      console.log("🚀 ~ file: Home.js ~ line 47 ~ getProductCategory ~ error", error)
    }
  }

  useEffect(() => {
    getProductCategory()
  }, [])


  return (
    <Layout>
      {/* Slider */}
      <HomeCarousel />
      <Container>
        <Grid container rowSpacing={4} alignItems="center" justifyContent={'space-between'} my={6}>
          {/* Lower Banner Section One */}
          <Grid item xs={12} md={7}>
            <Box>
              <Typography variant="h4" sx={{ color: colors.black, fontWeight: "bold", mb: 2 }}>Discover corporate clothing, merchandise items & more for your business</Typography>
              <Typography variant="body1" sx={{ color: colors.textSecondary, mb: 2 }}>Trade Time LLC Business helps corporate customers make their brands stand out. You’ll find a great variety of work wear items in our range. Give your team a uniform look featuring your logo at the next trade fair or public event. In addition to a wide range of fabrics, colors and sizes, you can choose special finishing methods to give your corporate clothing an exclusive look. We offer <span style={{ color: colors.primary }}>embroidered work wear</span> in addition to direct digital printing. Embroidery yields top quality results that are particularly durable. We also offer <span style={{ color: colors.primary }}>screen printing.</span> The prints are impressively durable and vibrant, and the products can easily be re-ordered.</Typography>
              <Typography variant="body1" sx={{ color: colors.textSecondary, }}>It’s a great print method for corporate clothing, and also for accessories and other articles from Spreadshirt’s product range. Discover our <span style={{ color: colors.primary }}>custom merchandise items</span> for your employees, business customers and corporate events. Any special wishes or requests regarding products and printing methods? Need a personal point of contact? No worries! <span style={{ color: colors.primary }}>Our bulk order team</span> will take care of your business needs for baskets of 100 or more items.</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <img src={Images.lowerSectionOne} width="100%" alt="Section1" />
          </Grid>
          {/* Lower Banner Section Two */}
          <Grid item xs={12} md={5} mt={3}>
            <img src={Images.customShirts} width="100%" style={{ float: "right" }} alt="Custom Shirt" />
          </Grid>
          <Grid item xs={12} md={6.5} mt={3}>
            <Box>
              <Typography variant="h4" sx={{ color: colors.black, fontWeight: "bold", mb: 2 }}>Company Shirts</Typography>
              <Typography variant="body1" sx={{ color: colors.textSecondary, mb: 2 }}>Whether you're working from home or out of the home, we've got you covered with top styles and templates for businesses of all types.</Typography>
            </Box>
            <PrimaryButton myStyle={{ borderRadius: "20px", mr: 2 }}>Design A Company Shirt</PrimaryButton>
          </Grid>
          {/* Lower Banner Section Three */}
          <Grid item xs={12} md={7} mt={3}>
            <Typography variant="h4" sx={{ color: colors.black, fontWeight: "bold", mb: 2 }}>Embroidery Your Logo on Hundreds of Products.</Typography>
            <Typography variant="body2" sx={{ color: colors.textSecondary, mb: 1 }}>Any size order. Any deadline. We've git you covered.</Typography>
            <Box sx={{ display: "flex", alignItems: "center", my: 2 }}>
              <PrimaryButton myStyle={{ borderRadius: "20px", mr: 2 }}>Shop Polos</PrimaryButton>
              <SecondaryButton myStyle={{ borderRadius: "20px" }}>Shop Caps</SecondaryButton>
            </Box>
          </Grid>
          <Grid item xs={12} md={5} mt={3}>
            <img src={Images.lowerSectionTwo} width="100%" alt="Section2" />
          </Grid>
          {/* Trade Time LLC */}
          <Grid item container alignItems="center" justifyContent={'space-around'} my={8} sx={{ boxShadow: `rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;`, borderRadius: "8px", borderTop: `7px solid ${colors.primary}` }}>
            <Grid item xs={12} md={12} textAlign={'center'}>
              <Typography variant='h5' sx={{ color: colors.black, fontWeight: "bold", mb: 1 }}>Trade Time LLC</Typography>
              <Typography variant='body2' sx={{ color: colors.textSecondary, mb: 2 }}>Why so many choose us for custom apparel</Typography>
            </Grid>
            <Divider sx={{ width: "100%", bgcolor: '#00000000', my: 2 }} />
            <Grid item xs={12} md={3}>
              <Box sx={{ p: 2, textAlign: "center", cursor: "pointer", borderRight: `1px solid ${colors.gunMetal}`, height: "300px" }}>
                <LocalShippingOutlined sx={{ color: colors.primary, mt: 4, fontSize: "46px" }} />
                <Typography sx={{ fontSize: "18px", color: colors.primary, fontWeight: 500, mb: 1 }}>Fast Free Delivery</Typography>
                <Typography variant='body1' sx={{ color: colors.textSecondary, mb: 2, px: 2 }}>Trade Time LLC deliveries are our speciality, and we never take an order unless we are 100% sure that we can guarantee your deadline.</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ p: 2, textAlign: "center", cursor: "pointer", borderRight: `1px solid ${colors.gunMetal}`, height: "300px" }}>
                <SavingsOutlined sx={{ color: colors.primary, mt: 4, fontSize: "46px" }} />
                <Typography sx={{ fontSize: "18px", color: colors.primary, fontWeight: 500, mb: 1 }}>Better Pricing</Typography>
                <Typography variant='body1' sx={{ color: colors.textSecondary, mb: 2, px: 2 }}>With free shipping and competitive pricing, we provide our high-quality products for a price and standard unmatched anywhere.</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ p: 2, textAlign: "center", cursor: "pointer", borderRight: `1px solid ${colors.gunMetal}`, height: "300px" }}>
                <ThumbUpOffAlt sx={{ color: colors.primary, mt: 4, fontSize: "46px" }} />
                <Typography sx={{ fontSize: "18px", color: colors.primary, fontWeight: 500, mb: 1 }}>Industry Satisfaction</Typography>
                <Typography variant='body1' sx={{ color: colors.textSecondary, mb: 2, px: 2 }}>Accuracy, quality and expedience are core to our business and day-to-day operations. We're happy when you're happy.</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ p: 2, textAlign: "center", cursor: "pointer", height: "300px" }}>
                <DesignServicesOutlined sx={{ color: colors.primary, mt: 4, fontSize: "46px" }} />
                <Typography sx={{ fontSize: "18px", color: colors.primary, fontWeight: 500, mb: 1 }}>Design Review & Repair</Typography>
                <Typography variant='body1' sx={{ color: colors.textSecondary, mb: 2, px: 2 }}>Our professional in-house designers review and repair every order we receive to ensure each one is printed to perfection.</Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {/* How it works */}
        <Box sx={{ position: "relative", width: "100%", mt: 4, mb: 10, cursor: "pointer" }} >
          <Box sx={{ textAlign: "center" }}>
            <Typography variant='h4' sx={{ color: colors.black, fontWeight: "bold", mb: 1 }} >How it works</Typography>
            <Divider sx={{ mx: "auto", width: "200px", textAlign: "center", bgcolor: colors.primary, py: 0.18, borderRadius: "2px" }} />
            <Typography variant='body2' sx={{ color: colors.textSecondary, my: 2 }}>Create your own shirts and more in our online design studio.</Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box sx={{ boxShadow: `rgba(0, 0, 0, 0.24) 0px 3px 8px;`, borderRadius: "6px", display: 'flex' }}>
                <img src={Images.designOnline} alt="Design Online" style={{ objectFit: "cover", width: "100%" }} />
              </Box>
              <Box sx={{ textAlign: "center", my: 3 }}>
                <Typography sx={{ fontSize: "18px", color: colors.black, fontWeight: "bold" }}>Design Online</Typography>
                <Divider sx={{ mx: "auto", width: "50px", py: 0.075, bgcolor: colors.black, mt: 0.75, mb: 2 }} />
                <Typography sx={{ fontSIze: "14px", color: colors.textSecondary }}>We've made it super easy to create your custom shirts with our Design Studio. Choose from thousands of original clip art and fonts or upload your own images.</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ boxShadow: `rgba(0, 0, 0, 0.24) 0px 3px 8px;`, borderRadius: "6px", display: 'flex' }}>
                <img src={Images.screenPrinting} alt="Expertly Printed" style={{ objectFit: "cover", width: "100%" }} />
              </Box>
              <Box sx={{ textAlign: "center", my: 3 }}>
                <Typography sx={{ fontSize: "18px", color: colors.black, fontWeight: "bold" }}>Expertly Printed</Typography>
                <Divider sx={{ mx: "auto", width: "50px", py: 0.075, bgcolor: colors.black, mt: 0.75, mb: 2 }} />
                <Typography sx={{ fontSIze: "14px", color: colors.textSecondary }}>Once you've designed your shirt you can leave the rest to us. We're experts at our trade and stand behind each and every shirt that leaves our facility.</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ boxShadow: `rgba(0, 0, 0, 0.24) 0px 3px 8px;`, borderRadius: "6px", display: 'flex' }}>
                <img src={Images.packaging} alt="Delivered To You" style={{ objectFit: "cover", width: "100%" }} />
              </Box>
              <Box sx={{ textAlign: "center", my: 3 }}>
                <Typography sx={{ fontSize: "18px", color: colors.black, fontWeight: "bold" }}>Delivered To You</Typography>
                <Divider sx={{ mx: "auto", width: "50px", py: 0.075, bgcolor: colors.black, mt: 0.75, mb: 2 }} />
                <Typography sx={{ fontSIze: "14px", color: colors.textSecondary }}>You've got too much to do to be worried about your t-shirts. We ship them directly to your door so you can use that time elsewhere.</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={12} sx={{ display: "flex", justifyContent: "center" }} >
              <Box>
                <PrimaryButton endIcon={<ArrowForward sx={{ color: colors.white }} />}>
                  Get Started
                </PrimaryButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {/* Shop our Top categories */}
        <Grid container spacing={2}>
          <Grid xs={12} md={12}>
            <Box sx={{ textAlign: "center" }}>
              <Typography variant='h4' sx={{ color: colors.black, fontWeight: "bold", mb: 1 }} >Shop Our Top Categories</Typography>
              <Divider sx={{ mx: "auto", width: "200px", textAlign: "center", bgcolor: colors.primary, py: 0.18, borderRadius: "2px" }} />
              <Typography variant='body2' sx={{ color: colors.textSecondary, my: 2 }}>Our best selling apparel great for any occasion.</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={7.85} >
            {/* <img onClick={() => navigate('/category')} src={Images.design} width="100%" alt="" /> */}
            <Box sx={{ width: "100%", overflow: 'hidden', boxShadow: `rgba(149, 157, 165, 0.2) 0px 8px 24px;`, position: 'relative' }}>
              <img className={classes.productImages} onClick={() => navigate('/category')} src={productDetails[0]?.image} width="100%" alt="" />
              <Box sx={{ position: 'absolute', bottom: 60, zIndex: 99, width: "100%", display: 'flex', justifyContent: 'center', cursor: 'pointer' }}>
                <Typography sx={{ width: 'max-content', backgroundColor: colors.white, py: 1.5, px: 2, boxShadow: `rgba(0, 0, 0, 0.24) 0px 3px 8px;`, borderRadius: '6px' }}>{productDetails[0]?.name}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item container rowSpacing={1} xs={12} md={4.15}>
            {productDetails[1]?.map((item, index) => (
              <Grid key={index} item xs={12} md={12}>
                <Box sx={{ width: "100%", overflow: 'hidden', boxShadow: `rgba(149, 157, 165, 0.2) 0px 8px 24px;`, position: 'relative' }}>
                  <img className={classes.productImages} onClick={() => navigate('/category')} src={item.image} width="100%" alt="" />
                  <Box sx={{ position: 'absolute', bottom: 60, zIndex: 99, width: "100%", display: 'flex', justifyContent: 'center', cursor: 'pointer' }}>
                    <Typography sx={{ width: 'max-content', backgroundColor: colors.white, py: 1.5, px: 2, boxShadow: `rgba(0, 0, 0, 0.24) 0px 3px 8px;`, borderRadius: '6px' }}>{item.name}</Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
          {productDetails[2]?.map((item, index) => (
            <Grid key={index} item xs={12} md={4}>
              <Box sx={{ width: "100%", overflow: 'hidden', boxShadow: `rgba(149, 157, 165, 0.2) 0px 8px 24px;`, position: 'relative' }}>
                <img className={classes.productImages} onClick={() => navigate('/category')} src={item.image} width="100%" alt="" />
                <Box sx={{ position: 'absolute', bottom: 60, zIndex: 99, width: "100%", display: 'flex', justifyContent: 'center', cursor: 'pointer' }}>
                  <Typography sx={{ width: 'max-content', backgroundColor: colors.white, py: 1.5, px: 2, boxShadow: `rgba(0, 0, 0, 0.24) 0px 3px 8px;`, borderRadius: '6px' }}>{item.name}</Typography>
                </Box>
              </Box>
            </Grid>
          ))}

          {/* View All Products */}
          <Grid item xs={12} md={12}>
            <Box onClick={() => navigate('/category')} sx={{ textAlign: "center", mt: 2 }}>
              <PrimaryButton endIcon={<ArrowForward sx={{ color: colors.white }} />} myStyle={{ borderRadius: "20px" }}>View All Products</PrimaryButton>
            </Box>
          </Grid>
        </Grid>
        {/* Help Section Two */}
        {/* Clients who trust us */}
        <Grid container spacing={1} alignItems="center" justifyContent={'space-between'} sx={{ my: 8 }}>
          <Grid item xs={12} md={7}>
            <Typography variant="h4" sx={{ color: colors.black, fontWeight: "bold", mb: 2 }}>Need Help? Chat with useLayoutEffect.</Typography>
            <Typography variant="body2" sx={{ color: colors.textSecondary, mb: 1 }}>Live chat with a Product Specialist or speak one-on-one with our pros by calling (800)620-1233</Typography>
            <Box sx={{ display: "flex", alignItems: "center", my: 2 }}>
              <PrimaryButton endIcon={<WhatsApp sx={{ color: colors.white }} />} myStyle={{ borderRadius: "20px", mr: 2 }}>Chat Now</PrimaryButton>
              <SecondaryButton endIcon={<Phone sx={{ color: colors.primary }} />} myStyle={{ borderRadius: "20px" }}>Call Now</SecondaryButton>
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <img src={Images.help} style={{ width: "80%", float: 'right' }} alt="Section2" />
          </Grid>
          {/* Clients who trust us */}
          <Grid item xs={12} md={12} sx={{ position: "relative", width: "100%", mt: 8, cursor: "pointer" }} >
            <Box sx={{ p: 3, border: `0.5px solid ${colors.secondary}`, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "space-around", borderRadius: '4px' }}>
              {brandSVG.map((item, index) => (
                <Box key={index} sx={{ width: "100px", height: "70px", display: "flex", justifyContent: "center", mx: 1.5, my: 1.5 }}>
                  <img src={item} width="125px" height="60px" alt="Clients" />
                </Box>
              ))}
            </Box>
            <Box sx={{ textAlign: "center", position: "absolute", top: { xs: -5, md: -12 }, right: { xs: '14%', md: "40%" }, backgroundColor: colors.white, px: 1 }}>
              <Typography sx={{ fontSize: { xs: '18px', md: "28px" }, fontWeight: 800, color: colors.primary }}>Clients who trust us</Typography>
            </Box>
          </Grid>
        </Grid>
      </Container >
    </Layout >
  )
}

export default Home