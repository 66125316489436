import React, { useState, useEffect } from 'react'
import { FilterAlt, StarRate } from '@mui/icons-material'
import { Box, Button, Container, Divider, Grid, Typography } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from "react-redux";
import Lottie from 'react-lottie';

//* Imported Components
import animationData from '../../assets/images/loader';
import SearchFilter from '../../components/SearchFilter/SearchFilter';
import Layout from '../../layout/Layout/Layout'
import { SortButton } from '../../components/UI/Buttons';
import colors from '../../styles/colors';
import Filter from '../../components/Filter/Filter';
import { Service } from '../../config/service';
import { setStartDesigningDetails } from '../../features/counter/counterSlice';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

function Product() {

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { search } = useLocation();
  console.log("🚀 ~ file: Product.js ~ line 22 ~ Product ~ search", search)
  const value = pathname.split('/')
  const id = value[2]
  // Using Redux
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(true);

  // Filter Side Nav Control
  const [products, setProducts] = useState([])
  const [selectedFilter, setSelectedFilter] = useState(true)
  const handleChangeFilter = () => {
    setSelectedFilter(!selectedFilter);
  }

  //* Get Products By Sub Category ID API
  const getProductSubCatByCatID = async (id) => {
    try {
      const { data } = await Service.getProductsBySubCatID(id)
      console.log("🚀 ~ file: Product.js ~ line 35 ~ getProductSubCatByCatID ~ data", data)
      setProducts(data);
      setLoader(false);
    } catch (error) {
      console.log("🚀 ~ file: Product.js ~ line 35 ~ getProductSubCatByCatID ~ error", error)
    }
  }

  //* Get Products By Sub Category ID API
  const getProductDetailsByFilter = async (search) => {
    try {
      const { data } = await Service.getProductFilters(search)
      console.log("🚀 ~ file: Product.js ~ line 52 ~ getProductDetailsByFilter ~ data", data)
      setProducts(data);
      setLoader(false);
    } catch (error) {
      console.log("🚀 ~ file: Product.js ~ line 55 ~ getProductDetailsByFilter ~ error", error)
    }
  }


  useEffect(() => {
    getProductSubCatByCatID(id);
  }, [])

  useEffect(() => {
    getProductDetailsByFilter(search);
  }, [search])

  return (
    <Layout>
      <Container>
        <Box sx={{ mt: 4, py: 1.5, px: 2, boxShadow: `rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;` }}>
          <SearchFilter />
          <Divider sx={{ my: 1, backgroundColor: colors.platinum, }} />
          <Box sx={{ display: "flex", algnItems: "center" }}>
            <Button startIcon={<FilterAlt sx={{ fontSize: '34px' }} />}
              onClick={handleChangeFilter}
              sx={{ backgroundColor: colors.platinum, px: 2, py: 1, fontSize: "16px", mr: 2 }}
            >
              Filters
            </Button>
            <SortButton myStyle={{ backgroundColor: colors.platinum, py: 1.5, px: 2, display: { xs: 'none', md: 'flex' }, }} />
          </Box>
        </Box>
        <Grid container sx={{ justifyContent: "space-between", my: 6 }} >
          {selectedFilter === true &&
            <Grid item xs={12} md={3}>
              <Filter id={id} />
            </Grid>
          }
          <Grid item xs={12} md={selectedFilter ? 8.8 : 12} container columnSpacing={2} rowGap={2}>
            {loader ? (
              <Grid item xs={12} md={12} sx={{ height: "500px", justifyContent: 'center' }}>
                <Lottie
                  options={defaultOptions}
                  height={300}
                  width={300}
                />
              </Grid>) : (
              <>
                {products?.map((item, index) => (
                  <Grid key={index} item xs={12} md={selectedFilter ? 4 : 3}>
                    <Box onClick={() => { navigate(`/products/${item.name}/${item.product_id}`); dispatch(setStartDesigningDetails(item)); }} sx={{ boxShadow: `rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px`, cursor: "pointer", px: 2, py: 2 }}
                    >
                      <img src={item.product_images[0]?.image} width="225px" height="225px" alt={item.name} />
                      <Box sx={{ textAlign: "center", cursor: "pointer" }}>
                        <Typography sx={{ fontSize: "18px", fontWeight: "bold", color: colors.black, pb: 1 }}>{item.name}</Typography>
                        <Box>
                          {[...Array(Number(5))].map((item, index) => (
                            <StarRate key={index} sx={{ color: "#f9a60f" }} />
                          ))}
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                          <Box>
                            <Typography sx={{ color: colors.darkGray, fontSize: "12px", fontWeight: 'bold' }}>Brand : </Typography>
                            <Typography sx={{ color: colors.darkGray, fontSize: "14px" }}>{item.brand_name}</Typography>
                          </Box>
                          <Divider orientation='vertical' sx={{ color: colors.darkGray, height: "24px", mx: 0.5 }} />
                          <Box>
                            <Typography sx={{ color: colors.darkGray, fontSize: "12px", fontWeight: 'bold' }
                            } > Total Quantity:</Typography>
                            <Typography sx={{ color: colors.darkGray, fontSize: "14px" }
                            } >{item.prod_qty}</Typography>
                          </Box>
                        </Box>
                        <Typography sx={{ color: colors.black, fontWeight: "bold", my: 1 }}>${item.prod_price} <span style={{ fontWeight: 'normal', fontSize: '12px' }}> for each item </span></Typography>
                      </Box>
                      {/* <Box sx={{ px: 2, pb: 1 }}>
                    <ColorBox />
                  </Box> */}
                    </Box>
                  </Grid>
                ))}</>
            )}
          </Grid>
        </Grid>
      </Container>
    </Layout >
  )
}

export default Product