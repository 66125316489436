import React, { useEffect, useState, useRef, Fragment, } from "react";
import { Box } from '@mui/material';
import Images from './../../assets/Images';
import { rgbToHex, mergeArray, fontFamilyLowerCase, disabledInspect, } from "./../../utils/index";
import GlobalFonts from "./../../utils/font";
import { Grid, IconButton, CardMedia, Typography, Button, Tabs, Tab, } from "@material-ui/core";
import { Close, Redo, Undo, Height, ZoomIn, ZoomOut, } from "@material-ui/icons";
import { TabPanel, TabContext } from "@material-ui/lab";
import Draggable from "react-draggable";
import { Resizable } from "react-resizable";
import "react-resizable/css/styles.css";
import PropagateLoader from "react-spinners/PropagateLoader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { rgb } from "chroma-js";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setOrderSaveDetails, setTotalAddToCart, selectTotalDetails, selectTotalAdultSizes, selectTotalYouthSizes, } from '../../features/counter/counterSlice';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

// *Import Component
import MainPanel from './../../components/MainPanel/MainPanel';
import AddTextTab from "./../../components/AddTextTab/AddTextTab";
import UploadImagePanel from './../../components/UploadImagePanel/UploadImagePanel';
import AddArtPanel from './../../components/AddArtPanel/AddArtPanel';
import SaveAndShare from "./../../components/Dialogue/SaveAndShare";
import OrderSection from './../../components/OrderSection/OrderSection';
import { Rnd } from "react-rnd";
import useAuth from './../../hooks/useAuth';
import Layout from './../../layout/Layout/Layout';
import { imageURL } from './../../config/const';
import { Service } from './../../config/service';
import LoginDialog from './../Auth/Dialogs/LoginDialog';
import { cie76 } from './../../utils/cie76';
import Toaster, { ErrorToaster } from '../../components/Toaster/Toaster';
import { makeStyles } from '@mui/styles';
import MobileViewTemplate from './../../components/MobileViewTemplate/MobileViewTemplate';
import TextSlidingPane from "../../components/MobileViewTemplate/SlidingPane/TextSlidingPane";
import UploadImageSlidePane from "../../components/MobileViewTemplate/SlidingPane/UploadImageSlidePane";
import { ImportantDevices } from "@mui/icons-material";
// import { TextSlidingPane, UploadImageSlidePane } from '../../components/MobileViewTemplate/SlidingPane/';
// import UploadImageSlidePane from './../../components/MobileViewTemplate/SlidingPane/UploadImageSlidePane';

// *For Front Image
var canvasFrontImageLeft = 0;
var canvasFrontImageTop = 0;
var canvasFrontImageWidth = 0;
var canvasFrontImageHeight = 0;

// *For Back Image
var canvasBackImageLeft = 0;
var canvasBackImageTop = 0;
var canvasBackImageWidth = 0;
var canvasBackImageHeight = 0;

// *For Color
var originalColor = "";
var selectedColor = "";
var originalColorIndex = null;

// *For Total Colors
var totalImageColors = 0;

// *Global Fonts
var GlobalFont = "";

// *invert Color Class
var frontInvertColorClass = "";
var backInvertColorClass = "";

// *For Front Text Canvas Width & Height
var frontTextWidth = "0";
var frontTextHeight = "0";

// *For Back Text Canvas Width & Height
var backTextWidth = "0";
var backTextHeight = "0";

// *For Loader Color
var loaderColor = "#4a4a4a";

var imagesArray = [
  {
    width: 80,
    height: 80,
  },
  {
    width: 80,
    height: 80,
  },
];

var ctxFrontImage;
var ctxBackImage;
let FRONT_ORIGINAL_IMAGE_DATA;
let BACK_ORIGINAL_IMAGE_DATA;

const useStyle = makeStyles({
  responsiveToolBarImage: {
    width: '40px',
    height: '40px',
    cursor: 'pointer',
  },
  removeExtraClasses: {
    '&.jss1,&.jss2,&.jss3,&.jss4,&.jss5': {
      display: 'none !important'
    },
    '&.jss11 ': {
      padding: '0px !important',
    },
    '&.jss12': {
      height: '60px !important',
      width: '0px !important',
    }

  }
});


function DesignTool() {
  const navigate = useNavigate();
  const classes = useStyle();
  // Using Redux
  const dispatch = useDispatch();
  const { totalItems, eachItemPrice } = useSelector(selectTotalDetails);
  const totalAdultSizes = useSelector(selectTotalAdultSizes);
  const totalYouthSizes = useSelector(selectTotalYouthSizes);
  const userEmail = localStorage.getItem('userEmail')
  const frontUploadWorkCanvas = useRef();
  const backUploadWorkCanvas = useRef();
  const [isPaneOpen, setIsPaneOpen] = useState(false);
  const [isUploadPaneOpen, setIsUploadPaneOpen] = useState(false);


  // *For Rotate Value
  const rotateValue = [
    {
      value: 0,
      label: "None",
    },
    {
      value: 40,
      label: "",
    },
    {
      value: 80,
      label: "",
    },
    {
      value: 100,
      label: "Thick",
    },
  ];

  //* For Base Url
  const [baseURL, setBaseURL] = useState(null);

  // *For select Color Warning
  const notify = () => {
    toast.warn("Please select at least one color", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  };

  // *save and share design dialog
  const [isSaveAndShareDialog, setIsSaveAndShareDialog] = useState(false);
  const saveAndShareDialogFormRef = useRef();
  const [value, setValues] = useState({
    name: "",
    email: "",
  });

  // *get order price
  const [openOrderDialog, setOpenOrderDialog] = useState(false)
  const getOrderPrice = () => {
    setOpenOrderDialog(!openOrderDialog)
    console.log("button", openOrderDialog)
  }

  // *For Color Panel loading Opacity
  const [opacity, setOpacity] = useState("1");

  // *For Toolbar Panel
  const [panel, setPanel] = useState("main");

  const [subCategoryPanelHeading, setSubCategoryPanelHeading] = useState("");
  const [artWorkPanelHeading, setArtWorkPanelHeading] = useState("");

  const [categoriesPanel, setCategoriesPanel] = useState("");
  const [categoryPanelValue, setCategoryPanelValue] = useState("0");
  const [subCategoriesPanel, setSubCategoriesPanel] = useState("");
  const [subCategoryPanelValue, setSubCategoryPanelValue] = useState("0");
  const [editArtPanel, setEditArtPanel] = useState("");

  const [showSubCategoriesPanel, setShowSubCategoriesPanel] = useState(true);
  const [showArtWorkPanel, setShowArtWorkPanel] = useState(true);
  const [showEditArtWorkPanel, setShowEditArtWorkPanel] = useState(true);

  // Loading Status
  const [loadingStatus, setLoadingStatus] = useState(false)

  // *For Max Colors
  // const [maxColors, setMaxColors] = useState(4);

  // *For All Panel Loader
  const [panelLoader, setPanelLoader] = useState(false);

  // *For Color Loader
  const [colorLoader, setColorLoader] = useState(false);

  // *For Product Image Loader
  const [imageLoader, setImageLoader] = useState(false);

  // *For Product Angle
  const [productAngle, setProductAngle] = useState("front");

  // *For Edit Text Panel
  const [textPanel, setTextPanel] = useState(true);
  const [fontEditPanel, setFontEditPanel] = useState(true);
  const [fontFamilyPanel, setFontFamilyPanel] = useState(false);
  const [fontColorPanel, setFontColorPanel] = useState(false);
  const [fontOutlinePanel, setFontOutlinePanel] = useState(false);

  // *For Front/Back Text Canvas
  const frontTextCanvas = useRef();
  const backTextCanvas = useRef();
  let ctx = null;

  // *Get Fonts From API
  const [fonts, setFonts] = useState([]);

  // *For Art Work (Front)
  const [frontArtTop, setFrontArtTop] = useState(0);
  const [frontArtLeft, setFrontArtLeft] = useState(0);

  // *For Art Work (Back)
  const [backArtTop, setBackArtTop] = useState(0);
  const [backArtLeft, setBackArtLeft] = useState(0);

  // *For Upload Work (Front)
  const [frontUploadTop, setFrontUploadTop] = useState(0);
  const [frontUploadLeft, setFrontUploadLeft] = useState(0);

  // *For Upload Art (Back)
  const [backUploadTop, setBackUploadTop] = useState(0);
  const [backUploadLeft, setBackUploadLeft] = useState(0);

  // *For Art Work
  const [frontArtWork, setFrontArtWork] = useState([]);
  const [backArtWork, setBackArtWork] = useState("");

  // *For Upload Image
  const [frontUploadWork, setFrontUploadWork] = useState("");
  const [backUploadWork, setBackUploadWork] = useState("");

  // *For Upload Image Panel
  const [uploadImg, setUploadImg] = useState(true);
  const [uploadImgPanel, setUploadImgPanel] = useState(false);
  const [uploadImgColor, setUploadImgColor] = useState(false);

  // *For Text Canvas
  const [frontText, setFrontText] = useState(true);
  const [backText, setBackText] = useState(true);
  const [frontTextTop, setFrontTextTop] = useState(0);
  const [frontTextLeft, setFrontTextLeft] = useState(0);
  const [backTextTop, setBackTextTop] = useState(0);
  const [backTextLeft, setBackTextLeft] = useState(0);
  const [artColors, setArtColor] = useState([]); // !store original colors from image initially
  const [frontArtColors, setFrontArtColor] = useState([]); // !store original colors from front image initially
  const [backArtColors, setBackArtColor] = useState([]); // !store original colors from back image initially
  const [artColorOptions, setArtColorOptions] = useState([]); // *merge image & admin(backend) colors into this array.

  // *For Text Font Size
  const [fontSizeWidth, setFontSizeWidth] = useState(0);
  const [fontSizeHeight, setFontSizeHeight] = useState(20);

  // *For Front/Back Text Size InTo Inches
  const [frontTextSize, setFrontTextSize] = useState(1);
  const [backTextSize, setBackTextSize] = useState(1);

  // *For Add Art Categories
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  // *For ArtWork
  const [artWork, setArtWork] = useState([]);
  const [colors, setColors] = useState([]);

  // *For Default Image
  const [frontImage, setFrontImage] = useState(null);
  const [backImage, setBackImage] = useState(null);

  // *For Front Artwork/Image Width & Height Pixel Size
  const [frontImageWidthPixel, setFrontImageWidthPixel] = useState("");
  const [frontImageHeightPixel, setFrontImageHeightPixel] = useState("");

  // *For Back Artwork/Image Width & Height Pixel Size
  const [backImageWidthPixel, setBackImageWidthPixel] = useState("");
  const [backImageHeightPixel, setBackImageHeightPixel] = useState("");

  // *For Front Art Canvas Resize
  const [frontArtCanvasResizeMaxWidth, setFrontArtCanvasResizeMaxWidth] =
    useState(0);
  const [frontArtCanvasResizeMaxHeight, setFrontArtCanvasResizeMaxHeight] =
    useState(0);

  // *For Set Upload Canvas Image
  const [frontCanvasImg, setFrontCanvasImg] = useState("");
  const [backCanvasImg, setBackCanvasImg] = useState();

  // *For Back Art Canvas Resize
  const [backArtCanvasResizeMaxWidth, setBackArtCanvasResizeMaxWidth] =
    useState(0);
  const [backArtCanvasResizeMaxHeight, setBackArtCanvasResizeMaxHeight] =
    useState(0);

  // *For Front Upload Image Canvas Resize
  const [frontUploadCanvasResizeMaxWidth, setFrontUploadCanvasResizeMaxWidth] =
    useState(0);
  const [
    frontUploadCanvasResizeMaxHeight,
    setFrontUploadCanvasResizeMaxHeight,
  ] = useState(0);

  // *For Back Upload Image Canvas Resize
  const [backUploadCanvasResizeMaxWidth, setBackUploadCanvasResizeMaxWidth] =
    useState(0);
  const [backUploadCanvasResizeMaxHeight, setBackUploadCanvasResizeMaxHeight] =
    useState(0);

  // *For Front Art Resize
  const [frontArtCanvasWidth, setFrontArtCanvasWidth] = useState(80);
  const [frontArtCanvasHeight, setFrontArtCanvasHeight] = useState(80);

  // *For Back Art Resize
  const [backArtCanvasWidth, setBackArtCanvasWidth] = useState(80);
  const [backArtCanvasHeight, setBackArtCanvasHeight] = useState(80);

  // *For Front Upload Img Resize
  const [frontUploadCanvasWidth, setFrontUploadCanvasWidth] = useState("0");
  const [frontUploadCanvasHeight, setFrontUploadCanvasHeight] = useState("0");

  // *For Back Upload Img Resize
  const [backUploadCanvasWidth, setBackUploadCanvasWidth] = useState("0");
  const [backUploadCanvasHeight, setBackUploadCanvasHeight] = useState("0");

  // *For Front Art Width & Height Pixel
  const [frontArtWPxIntoInches, setFrontArtWPxIntoInches] = useState(0);
  const [frontArtHPxIntoInches, setFrontArtHPxIntoInches] = useState(0);

  // *For Back Art Width & Height Pixel
  const [backArtWPxIntoInches, setBackArtWPxIntoInches] = useState(0);
  const [backArtHPxIntoInches, setBackArtHPxIntoInches] = useState(0);

  // *For Front Upload Width & Height Pixel
  const [frontUploadWPxIntoInches, setFrontUploadWPxIntoInches] = useState(0);
  const [frontUploadHPxIntoInches, setFrontUploadHPxIntoInches] = useState(0);

  // *For Back Upload Width & Height Pixel
  const [backUploadWPxIntoInches, setBackUploadWPxIntoInches] = useState(0);
  const [backUploadHPxIntoInches, setBackUploadHPxIntoInches] = useState(0);

  // *For Masking
  const [maskColor, setMaskColor] = useState("#fff");

  // *For Front (PA) Print Area
  const [frontLeftOffsetPA, setFrontLeftOffsetPA] = useState("");
  const [frontTopOffsetPA, setFrontTopOffsetPA] = useState("");
  const [frontWidthPA, setFrontWidthPA] = useState("");
  const [frontHeightPA, setFrontHeightPA] = useState("");

  // *For Back (PA) Print Area
  const [backLeftOffsetPA, setBackLeftOffsetPA] = useState("");
  const [backTopOffsetPA, setBackTopOffsetPA] = useState("");
  const [backWidthPA, setBackWidthPA] = useState("");
  const [backHeightPA, setBackHeightPA] = useState("");

  const printArea = useRef(null);

  // *For Front Art Lock Unlock Button & Canvas
  const [frontIsArtProportional, setFrontIsArtProportional] = useState(true);
  const [frontArtLockAspectRatio, setFrontArtLockAspectRatio] = useState(true);

  // *For Back Art Lock Unlock Button & Canvas
  const [backIsArtProportional, setBackIsArtProportional] = useState(true);
  const [backArtLockAspectRatio, setBackArtLockAspectRatio] = useState(true);

  // *For Front Upload Lock Unlock Button & Canvas
  const [frontIsUploadProportional, setFrontIsUploadProportional] =
    useState(true);
  const [frontUploadLockAspectRatio, setFrontUploadLockAspectRatio] =
    useState(true);

  // *For Back Upload Lock Unlock Button & Canvas
  const [backIsUploadProportional, setBackIsUploadProportional] =
    useState(true);
  const [backUploadLockAspectRatio, setBackUploadLockAspectRatio] =
    useState(true);

  // *For Front Art Flip
  const [frontArtXAxis, setFrontArtXAxis] = useState(1);
  const [frontArtYAxis, setFrontArtYAxis] = useState(1);

  // *For Back Art Flip
  const [backArtXAxis, setBackArtXAxis] = useState(1);
  const [backArtYAxis, setBackArtYAxis] = useState(1);

  // *For Front Upload Flip
  const [frontUploadXAxis, setFrontUploadXAxis] = useState(1);
  const [frontUploadYAxis, setFrontUploadYAxis] = useState(1);

  // *For Back Upload Flip
  const [backUploadXAxis, setBackUploadXAxis] = useState(1);
  const [backUploadYAxis, setBackUploadYAxis] = useState(1);

  // *For Blur Rotate Input
  const [rotateBlur, setRotateBlur] = useState(0);

  // *For Front/Back Text Rotate
  const [frontTextRotate, setFrontTextRotate] = useState(0);
  const [backTextRotate, setBackTextRotate] = useState(0);

  // *For Front/Back Art Rotate
  const [frontArtRotate, setFrontArtRotate] = useState(0);
  const [backArtRotate, setBackArtRotate] = useState(0);

  // *For Front/Back Upload Image Rotate
  const [frontUploadRotate, setFrontUploadRotate] = useState(0);
  const [backUploadRotate, setBackUploadRotate] = useState(0);

  // *For Front/Back Set Text Input Value
  const [frontTextInput, setFrontTextInput] = useState("");
  console.log("🚀 ~ file: DesignTool.js ~ line 420 ~ DesignTool ~ frontTextInput", frontTextInput)
  const [backTextInput, setBackTextInput] = useState("");

  // *For Text Alignment Center
  const [textCenter, setTextCenter] = useState("");

  // *For Front/Back Text Layering
  const [frontTextLayer, setFrontTextLayer] = useState("");
  const [backTextLayer, setBackTextLayer] = useState("");

  // *For Front Font Family
  const [frontFontFamily, setFrontFontFamily] = useState("Arial");
  const [frontFontLowerCase, setFrontFontLowerCase] = useState("arial");
  const [frontFontType, setFrontFontType] = useState("ttf");

  // *For Back Font Family
  const [backFontFamily, setBackFontFamily] = useState("Arial");
  const [backFontLowerCase, setBackFontLowerCase] = useState("arial");
  const [backFontType, setBackFontType] = useState("ttf");

  // *For Front/Back Font Color
  const [frontFontColor, setFrontFontColor] = useState("#000");
  const [backFontColor, setBackFontColor] = useState("#000");

  // *For Front/Back Text Outline Thickness
  const [frontTextOutlineThickness, setFrontTextOutlineThickness] = useState(0);
  const [backTextOutlineThickness, setBackTextOutlineThickness] = useState(0);

  // *For Front/Back Font Outline Color
  const [frontFontOutlineColor, setFrontFontOutlineColor] = useState("grey");
  const [backFontOutlineColor, setBackFontOutlineColor] = useState("grey");

  // *For Background Remove
  const [backgroundRemoveStatus, setBackgroundRemoveStatus] = useState(true);

  // *For Make One Color
  const [isMakeOneColor, setIsMakeOneColor] = useState(false);
  const [selectedOneColor, setSelectedOneColor] = useState(null);
  const [frontImageBeforeOneColor, setFrontImageBeforeOneColor] = useState("");
  const [backImageBeforeOneColor, setBackImageBeforeOneColor] = useState("");

  // *For Front Art Invert Color
  const [frontArtInvertColor, setFrontArtInvertColor] = useState(false);

  // *For Back Art Invert Color
  const [backArtInvertColor, setBackArtInvertColor] = useState(false);

  // *For Image Active
  const [isActive, setIsActive] = useState("");

  // *For Image Select Color
  const [selectColor, setSelectColor] = useState(false);

  // *For Product Zoom
  const [zoom, setZoom] = useState(false);

  // *For testingFontSize
  const [testingFontSize, setTestingFontSize] = useState("");

  // User Auth For Checking Use Logged in Or Not for Save & Share
  const { user } = useAuth();
  const [open, setOpen] = useState(false);

  // UseState for Login Dialog 
  const [saveDesignOpen, setSaveDesignOpen] = useState(false)

  // * Change value using Register
  const { register, setValue } = useForm();

  // * For Text Canvas Text Size
  const valueChangeOfText = (value) => {

    // Text Canvas Draggable Box Values
    const textSizeValue = document.querySelector("#front-text-box");
    const changeWidth = window.getComputedStyle(textSizeValue)
      .getPropertyValue("width");
    const changeHeight = window.getComputedStyle(textSizeValue).getPropertyValue("height");
    if (value <= changeWidth.split("px")[0]) {
      setValue("text_value_size", value);
      console.log("set Value", value);
    }
    // Main Canvas Box Values
    const draggableValue = document.querySelector("#front-print-area");
    const getWidth = window.getComputedStyle(draggableValue).getPropertyValue("width");
    console.log(
      "🚀 ~ file: design-tool.js ~ line 459 ~ valueChangeOfText ~ getWidth",
      getWidth
    );
    const getHeight = window
      .getComputedStyle(draggableValue)
      .getPropertyValue("height");
    console.log(
      "🚀 ~ file: design-tool.js ~ line 461 ~ valueChangeOfText ~ getHeight",
      getHeight
    );
    if (changeWidth.split("px")[0] > changeHeight.split("px")[0]) {
      const updatedWidthValue = (
        Number(value) + Number(changeHeight.split("px")[0])
      ).toFixed(2);
      if (updatedWidthValue < Number(getWidth.split("px")[0])) {
        console.log(
          "🚀 ~ file: design-tool.js ~ line 458 ~ valueChangeOfText ~ updatedWidthValue",
          updatedWidthValue
        );
        document.getElementById(
          "front-text-box"
        ).style.width = `${updatedWidthValue}px`;
        console.log("this is width");
      } else {
        document.getElementById("front-text-box").style.width = `${Number(
          getWidth.split("px")[0]
        )}px`;
      }
    } else if (changeHeight.split("px")[0] > changeWidth.split("px")[0]) {
      const updatedHeightValue = (
        Number(value) + Number(changeHeight.split("px")[0])
      ).toFixed(2);
      if (updatedHeightValue < Number(getHeight.split("px")[0])) {
        console.log(
          "🚀 ~ file: design-tool.js ~ line 466 ~ valueChangeOfText ~ updatedHeightValue",
          updatedHeightValue
        );
        document.getElementById(
          "front-text-box"
        ).style.height = `${updatedHeightValue}px`;
        console.log("this is height");
      } else {
        document.getElementById("front-text-box").style.height = `${Number(
          getHeight.split("px")[0]
        )}px`;
      }
    } else {
      console.log("this is else bro");
    }
  };

  // *For ArtWork Categories
  const getArtWorkCategories = async () => {
    try {
      const { data } = await Service.getArtWorkCategory();
      setCategories(data);
    } catch (error) {
      console.log("Login -> error", error);
    }
  };

  // *For Font Family
  const getFonts = async () => {
    try {
      const { data } = await Service.getFonts();
      setFonts(data);
    } catch (error) {
      console.log("Login -> error", error);
    }
  };

  // *For Font Color
  const getColors = async () => {
    try {
      const { data } = await Service.getColors();
      setColors(data);
    } catch (error) {
      console.log("Login -> error", error);
    }
  };

  // *For Change Art Work
  const [artChangeStatus, setArtChangeStatus] = useState(false);

  // *For Default Image
  const getDefaultImages = async () => {
    try {
      setImageLoader(true);
      const { data } = await Service.getDefaultImage();

      if (data) {
        setImageLoader(false);
        // *for front image
        canvasFrontImageLeft = parseFloat(data[0].left);
        canvasFrontImageTop = parseFloat(data[0].top);
        canvasFrontImageWidth = parseFloat(data[0].width);
        canvasFrontImageHeight = parseFloat(data[0].height);
        setFrontImage(data[0].image);
        setFrontImageWidthPixel(parseFloat(data[0].width_px_per_inch));
        setFrontImageHeightPixel(parseFloat(data[0].height_px_per_inch));

        // *for back image
        canvasBackImageLeft = parseFloat(data[1].left);
        canvasBackImageTop = parseFloat(data[1].top);
        canvasBackImageWidth = parseFloat(data[1].width);
        canvasBackImageHeight = parseFloat(data[1].height);
        setBackImage(data[1].image);
        setBackImageWidthPixel(parseFloat(data[1].width_px_per_inch));
        setBackImageHeightPixel(parseFloat(data[1].height_px_per_inch));

        setTimeout(() => {
          getCanvasAreaDimension();
        }, 3000);
      }
    } catch (error) {
      console.log("Login -> error", error);
    }
  };

  // *For Canvas Dimension
  const getCanvasAreaDimension = () => {
    try {
      let left = document.getElementById("canvas-image").offsetLeft;
      let width = document.getElementById("canvas-image").offsetWidth;
      let height = document.getElementById("canvas-image").offsetHeight;
      getPrintArea(
        width,
        height,
        left,
        canvasFrontImageLeft,
        canvasFrontImageTop,
        canvasFrontImageWidth,
        canvasFrontImageHeight,
        canvasBackImageLeft,
        canvasBackImageTop,
        canvasBackImageWidth,
        canvasBackImageHeight
      );
    } catch (error) {
      console.log("Login -> error", error);
    }
  };

  window.addEventListener("resize", () => {
    getCanvasAreaDimension();
  });

  // *For Change Panel
  const changePanel = (event, newPanel) => {
    setPanel(newPanel);
  };

  const getArtWorkSubCatByCat = async (newCategories, catID, catName) => {
    if (catID !== "") {
      try {
        setPanelLoader(true);
        const { data } = await Service.getArtWorkSubCatByCatID(catID);
        if (data) {
          setPanelLoader(false);
          setSubCategories(data);
          setSubCategoryPanelHeading(catName);
          setCategoriesPanel(catID.toString());
          setCategoryPanelValue(catID.toString());
        }
      } catch (error) {
        console.log("Login -> error", error);
      }
    } else {
      setCategoriesPanel(newCategories);
    }
  };

  const getArtWorkBySubCat = async (subCatID, subCatName) => {
    if (subCatID !== "") {
      try {
        setPanelLoader(true);
        const { data } = await Service.getArtWorkBySubCatID(subCatID);
        if (data) {
          setPanelLoader(false);
          // if (artChangeStatus) {
          //   let artChangeValue = [...artWork]
          //   artChangeValue[artIndex] = data
          //   setArtWork(artChangeValue);
          // }
          // else {
          //   setArtWork(data);
          // }
          setArtWork(data);
          setArtWorkPanelHeading(subCatName);
          setSubCategoriesPanel(subCatID.toString());
          setSubCategoryPanelValue(subCatID.toString());
        }
      } catch (error) {
        console.log("Login -> error", error);
      }
    } else {
      setSubCategoriesPanel(subCatID);
    }
  };

  const getEditArt = (event, newEditArtPanel) => {
    setEditArtPanel(newEditArtPanel);
  };

  const showSubCategory = (type) => {
    if (type === true) {
      setShowSubCategoriesPanel(true);
    } else {
      setShowSubCategoriesPanel(false);
    }
  };

  const showArt = (type) => {
    if (type === true) {
      setShowArtWorkPanel(true);
    } else {
      setShowArtWorkPanel(false);
    }
  };

  const showEditArt = (type) => {
    if (type === true) {
      setShowEditArtWorkPanel(true);
    } else {
      setShowEditArtWorkPanel(false);
    }
  };

  let changeImageWidthSizeValue = useRef();

  // *For Product Angle
  const getProductAngle = (angle) => {
    if (angle === "front") {
      setProductAngle("front");
    } else {
      setProductAngle("back");
    }
  };

  // *For Upload Image
  const getUploadImg = (type) => {
    if (type === true) {
      setUploadImg(true);
    } else {
      setUploadImg(false);
    }
  };

  const getUploadImgPanel = (isImagePanel, isImageColor, fromUploadPanel) => {
    // debugger;
    if (fromUploadPanel === true) {
      let totalColors =
        productAngle === "front" ? frontArtColors.length : backArtColors.length;

      if (totalColors === 0) {
        setUploadImgPanel(false);
        setUploadImgColor(false);
      }
      // else if (totalColors <= maxColors)
      // {
      //   setUploadImgPanel(false);
      //   setUploadImgColor(true);
      // }
      else {
        setUploadImgPanel(false);
        setUploadImgColor(true);
      }
    } else {
      setUploadImgPanel(isImagePanel);
      setUploadImgColor(isImageColor);
    }
  };

  // *For Edit Text
  const getFontEditPanel = (type) => {
    if (type === true) {
      setFontEditPanel(true);
    } else {
      setFontEditPanel(false);
    }
  };

  const getTextEditPanel = (
    isTextPanel,
    isFontFamilyPanel,
    isFontColorPanel,
    isFontOutlinePanel
  ) => {
    try {
      setTextPanel(isTextPanel);
      setFontFamilyPanel(isFontFamilyPanel);
      setFontColorPanel(isFontColorPanel);
      setFontOutlinePanel(isFontOutlinePanel);
    } catch (error) {
      console.log("Login -> error", error);
    }
  };

  // *For Text Layering
  const textLayer = (type) => {
    if (productAngle === "front") {
      if (type === true) {
        setFrontTextLayer("10");
      } else {
        setFrontTextLayer("2");
      }
    } else {
      if (type === true) {
        setBackTextLayer("10");
      } else {
        setBackTextLayer("2");
      }
    }
  };

  // *For Art Canvas Resize Width & Height
  const getArtCanvasResizeWidth = (event, data, index) => {
    console.log(
      "file: design-tool.js => line 653 => getArtCanvasResizeWidth => index",
      index
    );
    console.log(
      "file: design-tool.js => line 653 => getArtCanvasResizeWidth => data",
      data
    );
    console.log(
      "file: design-tool.js => line 653 => getArtCanvasResizeWidth => event",
      event
    );
    try {
      if (productAngle === "front") {
        const frontArtBoxOffset = document
          .getElementById("front-art-box")
          .getBoundingClientRect();
        const frontArtAreaOffset = document
          .getElementById("front-print-area")
          .getBoundingClientRect();
        const frontCanvasArtResizeMaxWidth =
          frontArtBoxOffset.x - frontArtAreaOffset.x;
        const frontCanvasArtResizeMaxHeight =
          frontArtBoxOffset.y - frontArtAreaOffset.y;

        // *set front art top/left
        // setFrontArtLeft(data.x)
        // setFrontArtTop(data.y)

        frontArtWork[index].left = data.x;
        frontArtWork[index].top = data.y;

        setFrontArtCanvasResizeMaxWidth(frontCanvasArtResizeMaxWidth);
        setFrontArtCanvasResizeMaxHeight(frontCanvasArtResizeMaxHeight);
      } else {
        const backArtBoxOffset = document
          .getElementById("back-art-box")
          .getBoundingClientRect();
        const backArtAreaOffset = document
          .getElementById("back-print-area")
          .getBoundingClientRect();
        const backCanvasArtResizeMaxWidth =
          backArtBoxOffset.x - backArtAreaOffset.x;
        const backCanvasArtResizeMaxHeight =
          backArtBoxOffset.y - backArtAreaOffset.y;

        // *set back art top/left
        setBackArtLeft(data.x);
        setBackArtTop(data.y);

        setBackArtCanvasResizeMaxWidth(backCanvasArtResizeMaxWidth);
        setBackArtCanvasResizeMaxHeight(backCanvasArtResizeMaxHeight);
      }
    } catch (error) {
      console.log("Login -> error", error);
    }
  };

  // *For Upload Image Canvas Resize Width & Height
  const getUploadCanvasResizeWidth = (event, data) => {
    try {
      if (productAngle === "front") {
        const frontUploadBoxOffset = document
          .getElementById("front-upload-box")
          .getBoundingClientRect();
        const frontUploadAreaOffset = document
          .getElementById("front-print-area")
          .getBoundingClientRect();
        const frontCanvasUploadResizeMaxWidth =
          frontUploadBoxOffset.x - frontUploadAreaOffset.x;
        const frontCanvasUploadResizeMaxHeight =
          frontUploadBoxOffset.y - frontUploadAreaOffset.y;

        // *set front upload top/left
        setFrontUploadLeft(data.x);
        setFrontUploadTop(data.y);

        setFrontUploadCanvasResizeMaxWidth(frontCanvasUploadResizeMaxWidth);
        setFrontUploadCanvasResizeMaxHeight(frontCanvasUploadResizeMaxHeight);
      } else {
        const backUploadBoxOffset = document
          .getElementById("back-upload-box")
          .getBoundingClientRect();
        const backUploadAreaOffset = document
          .getElementById("back-print-area")
          .getBoundingClientRect();
        const backCanvasUploadResizeMaxWidth =
          backUploadBoxOffset.x - backUploadAreaOffset.x;
        const backCanvasUploadResizeMaxHeight =
          backUploadBoxOffset.y - backUploadAreaOffset.y;

        // *set back upload top/left
        setBackUploadLeft(data.x);
        setBackUploadTop(data.y);

        setBackUploadCanvasResizeMaxWidth(backCanvasUploadResizeMaxWidth);
        setBackUploadCanvasResizeMaxHeight(backCanvasUploadResizeMaxHeight);
      }
    } catch (error) {
      console.log(
        "file: design-tool.js => line 669 => getUploadCanvasResizeWidth => error",
        error
      );
    }
  };

  // *For Text Canvas Position
  const setCanvasTextPosition = (event, data) => {
    try {
      if (productAngle === "front") {
        // *set front text position
        setFrontTextLeft(data.x);
        setFrontTextTop(data.y);
      } else {
        // *set back text position
        setBackTextLeft(data.x);
        setBackTextTop(data.y);
      }
    } catch (error) {
      console.log("Login -> error", error);
    }
  };

  // *display original image color
  const renderImageColors = () => {
    try {
      const colors = productAngle === "front" ? frontArtColors : backArtColors;
      return colors.map((color, index) => {
        return (
          <label className="color-box" key={index}>
            <input type="radio" name="selectColor" />
            <span
              className="checkmark"
              style={{
                backgroundColor: rgb(
                  color["red"],
                  color["green"],
                  color["blue"]
                ),
              }}
              onClick={() => {
                getOriginalColor(color, index);
                setSelectColor(true);
              }}
            ></span>
          </label>
        );
      });
    } catch (error) {
      console.log("Login -> error", error);
    }
  };

  // *For Original Color
  const getOriginalColor = (color, index) => {
    try {
      originalColorIndex = index;
      // let isHex = /^#([0-9A-F]{3}){1,2}$/i.test(color);
      // if (isHex === false) {
      //   originalColor = rgbToHex(color);
      // } else {
      //   originalColor = color;
      // }
      originalColor = color;
    } catch (error) {
      console.log("Login -> error", error);
    }
  };

  // *For Text Selected Color
  const getSelectedColor = (color) => {
    try {
      if (originalColor === "") {
        notify();
      } else {
        let isHex = /^#([0-9A-F]{3}){1,2}$/i.test(color);
        if (isHex === false) {
          selectedColor = rgbToHex(color);
        } else {
          selectedColor = color;
        }
        changeImageColor();
      }
    } catch (error) {
      console.log("Login -> error", error);
    }
  };

  // *For Color Change Of Upload Image
  const changeImageColor = async () => {
    try {
      var obj = {
        original_color: originalColor,
        selected_color: selectedColor,
        image: productAngle === "front" ? frontUploadWork : backUploadWork,
      };
      setColorLoader(true);
      setOpacity("0.2");
      const { data } = await Service.getImageColorChangeURL(obj);
      if (data) {
        setColorLoader(false);
        setOpacity("1");
        if (productAngle === "front") {
          const updatedArtColors = [...frontArtColors];
          updatedArtColors[originalColorIndex] = selectedColor; //hexToRgb(selectedColor);
          setFrontUploadWork(baseURL + data);
          setFrontArtColor(updatedArtColors);
          setFrontImageBeforeOneColor(baseURL + data);
        } else {
          const updatedArtColors = [...backArtColors];
          updatedArtColors[originalColorIndex] = selectedColor; //hexToRgb(selectedColor);
          setBackUploadWork(baseURL + data);
          setBackArtColor(updatedArtColors);
          setBackImageBeforeOneColor(baseURL + data);
        }
      }
    } catch (error) {
      console.log("Login -> error", error);
    }
  };

  const changeColor = (dataHex) => {
    setMaskColor(dataHex);
  };
  const [frontFileUploadImage, setFrontFileUploadImage] = useState('')
  const [backFileUploadImage, setBackFileUploadImage] = useState('')
  // !upload image to canvas start
  const getImageData = (e) => {
    console.log("🚀 ~ file: design-tool.js ~ line 1061 ~ getImageData ~ e", e)
    try {
      setPanelLoader(true);
      getUploadImg(false);
      setIsMakeOneColor(false); // *off make one color switch
      if (productAngle === "front") {
        let frontFile = e.target.files[0];
        setFrontFileUploadImage(frontFile);
        if (frontFile) {
          const frontReader = new FileReader();
          frontReader.addEventListener("load", _handleReaderLoader);
          frontReader.readAsDataURL(frontFile);
        }
      } else {
        let backFile = e.target.files[0];
        setBackFileUploadImage(backFile);
        if (backFile) {
          const backReader = new FileReader();
          backReader.addEventListener("load", _handleReaderLoader);
          backReader.readAsDataURL(backFile);
        }
      }
      setPanelLoader(false);
      getUploadImgPanel(true, false, false);
    } catch (error) {
      console.log(
        "file: design-tool.js => line 844 => getImageData => error",
        error
      );
    }
  };

  const _handleReaderLoader = (redEve) => {
    try {
      if (productAngle === "front") {
        const frontImg = new Image();
        setFrontCanvasImg(frontImg);
        let isListenerAttached = false;
        frontImg.src = redEve.target.result;
        if (!isListenerAttached) {
          frontImg.addEventListener("load", () => {
            setIsActive(frontUploadWorkCanvas);
            frontUploadWorkCanvas.current.height = frontImg.height;
            frontUploadWorkCanvas.current.width = frontImg.width;

            // *upload Image Proportional
            const uploadArtWidth = (110 / frontImg.width) * 100; // * 110 is default with of Canvas
            const uploadArtHeight = (uploadArtWidth * frontImg.height) / 100;
            setFrontUploadCanvasWidth(110);
            setFrontUploadCanvasHeight(uploadArtHeight);

            const width = 110 / frontImageWidthPixel;
            const height = uploadArtHeight / frontImageWidthPixel;
            setFrontUploadWPxIntoInches(width.toFixed(2));
            setFrontUploadHPxIntoInches(height.toFixed(2));
            ctxFrontImage.drawImage(frontImg, 0, 0, 110, uploadArtHeight);
            ctxFrontImage.save();
            cacheImageData();
            getImageColor("front");
            isListenerAttached = true;
            removeBackground();
            // dispatch(setAddOriginalImageCanvas({ ctxFrontImage }));
          });
        }
      } else {
        const backImg = new Image();
        setBackCanvasImg(backImg);
        let isListenerAttached = false;
        backImg.src = redEve.target.result;
        if (!isListenerAttached) {
          backImg.addEventListener("load", () => {
            backUploadWorkCanvas.current.height = backImg.height;
            backUploadWorkCanvas.current.width = backImg.width;

            // *upload Image Proportional
            const uploadArtWidth = (110 / backImg.width) * 100; // * 110 is default with of Canvas
            const uploadArtHeight = (uploadArtWidth * backImg.height) / 100;
            setBackUploadCanvasWidth(110);
            setBackUploadCanvasHeight(uploadArtHeight);

            ctxBackImage.drawImage(backImg, 0, 0, 110, uploadArtHeight);
            cacheImageData();
            getImageColor("back");
            isListenerAttached = true;
          });
        }
      }
    } catch (error) {
      console.log(
        "file: design-tool.js => line 852 => DesignTool => error",
        error
      );
    }
  };

  // *get image color
  const getImageColor = (value) => {
    let imgColors = [];
    var sortable = [];
    let finalColors = [];
    let selectedColors = [];
    let closetColorArray = [];
    let selectedColorArray = [];
    var countIndex = 0;
    let colorDifference = 0;
    var totalColorCount = 0;
    if (value === "front") {
      const imgData = ctxFrontImage.getImageData(
        0,
        0,
        frontUploadWorkCanvas.current.width,
        frontUploadWorkCanvas.current.height
      );

      for (var j = 0, k = imgData.data.length; j < k; j += 4) {
        let r = imgData.data[j],
          g = imgData.data[j + 1],
          b = imgData.data[j + 2];
        let rgb = [r, g, b];
        imgColors.push(rgb);
      }
    }
    else {
      const imgData = ctxFrontImage.getImageData(
        0,
        0,
        backUploadWorkCanvas.current.width,
        backUploadWorkCanvas.current.height
      );

      for (var j = 0, k = imgData.data.length; j < k; j += 4) {
        let r = imgData.data[j],
          g = imgData.data[j + 1],
          b = imgData.data[j + 2];
        let rgb = [r, g, b];
        imgColors.push(rgb);
      }
    }

    const counts = {};
    for (const num of imgColors) {
      counts[num] = counts[num] ? counts[num] + 1 : 1;
    }

    let totalUniqueColors = Object.getOwnPropertyNames(counts).length;

    for (var color in counts) {
      if (totalUniqueColors == 1) {
        sortable.push([color, counts[color]]);
      }
      else if (countIndex > 0) {
        // totalColorCount = totalColorCount + counts[color];
        sortable.push([color, counts[color]]);
      }
      countIndex++;
    }

    sortable.sort(function (a, b) {
      return b[1] - a[1];
    });

    if (totalUniqueColors == 1) {
      selectedColors.push(sortable[0]);
      totalColorCount = totalColorCount + sortable[0][1];
    }
    else {
      for (let i = 0; i < sortable.length; i++) {
        let closetColorArrayBoolean = closetColorArray.includes(i);
        if (closetColorArrayBoolean === false) {
          for (let j = i + 1; j < sortable.length; j++) {
            let split1 = sortable[i][0].split(",");
            let rgb1 = [split1[0], split1[1], split1[2]];
            let split2 = sortable[j][0].split(",");
            let rgb2 = [split2[0], split2[1], split2[2]];
            colorDifference = cie76(rgb1, rgb2);
            // console.log(rgb1, rgb2, colorDifference);
            if (colorDifference > 30) {
              let selectedColorArrayBoolean = selectedColorArray.includes(i);
              if (selectedColorArrayBoolean === false) {
                selectedColors.push(sortable[i]);
                selectedColorArray.push(i);
                // *skip background color
                if (i > 0) {
                  totalColorCount = totalColorCount + sortable[i][1];
                }
              }
            } else {
              closetColorArray.push(j);
            }
          }
        }
      }
    }

    if ((totalColorCount == 0)
      && (totalUniqueColors > 0)) {
      selectedColors.push(sortable[0]);
      totalColorCount = totalColorCount + sortable[0][1];
    }

    for (let i = 0; i < selectedColors.length; i++) {
      if (
        selectedColors[i][1] >=
        Math.round(totalColorCount / selectedColors.length)
      ) {
        if (i <= 8) {
          let split = selectedColors[i][0].split(",");
          finalColors.push({ red: split[0], green: split[1], blue: split[2] });
        }
      }
    }
    //console.log('file: design-tool.js => line 976 => getImageColor => finalColors', finalColors);
    setFrontArtColor(finalColors);
    renderSwatches();
    setArtColorOptions(mergeArray(colors, finalColors));
  };

  // *For Resize Upload Canvas Image
  const getUploadSize = (size) => {
    try {
      if (productAngle === "front") {
        setFrontUploadCanvasWidth(size.width);
        setFrontUploadCanvasHeight(size.height);
        ctxFrontImage.drawImage(frontCanvasImg, 0, 0, size.width, size.height);
        if (backgroundRemoveStatus) {
          removeBackground()
        }
      } else {
        setBackUploadCanvasWidth(size.width);
        setBackUploadCanvasHeight(size.height);
        ctxBackImage.drawImage(backCanvasImg, 0, 0, size.width, size.height);
      }
    } catch (error) {
      console.log(
        "file: design-tool.js => line 869 => getFrontUploadSize => error",
        error
      );
    }
  };

  const cacheImageData = () => {
    try {
      if (productAngle === "front") {
        const original = ctxFrontImage.getImageData(
          0,
          0,
          frontUploadWorkCanvas.current.width,
          frontUploadWorkCanvas.current.height
        ).data;
        FRONT_ORIGINAL_IMAGE_DATA = new Uint8ClampedArray(original.length);
        for (let i = 0; i < original.length; i += 1) {
          FRONT_ORIGINAL_IMAGE_DATA[i] = original[i];
        }
      } else {
        const original = ctxBackImage.getImageData(
          0,
          0,
          backUploadWorkCanvas.current.width,
          backUploadWorkCanvas.current.height
        ).data;
        BACK_ORIGINAL_IMAGE_DATA = new Uint8ClampedArray(original.length);
        for (let i = 0; i < original.length; i += 1) {
          BACK_ORIGINAL_IMAGE_DATA[i] = original[i];
        }
      }
    } catch (error) {
      console.log(
        "file: design-tool.js => line 910 => cacheImageData => error",
        error
      );
    }
  };

  const handleRemoveBackground = (event) => {
    try {
      const boolean = event.target.checked === true ? true : false;

      if (boolean) {
        removeBackground();
      } else {
        // reset();
        revertBackground();
      }
      setBackgroundRemoveStatus(!backgroundRemoveStatus)
    } catch (error) {
      console.log(
        "file: design-tool.js => line 936 => handleRemoveBackground => error",
        error
      );
    }
  };

  const removeBackground = () => {
    try {
      if (productAngle === "front") {
        const imgData = ctxFrontImage.getImageData(
          0,
          0,
          frontUploadWorkCanvas.current.width,
          frontUploadWorkCanvas.current.height
        );
        const newColor = { r: 0, g: 0, b: 0, a: 0 };

        // *get coordinates color
        // !ctx.getImageData(left, top, width, height)
        const topLeft = ctxFrontImage.getImageData(0, 0, 1, 1).data;
        const bottomRight = ctxFrontImage.getImageData(
          frontUploadWorkCanvas.current.width - 1,
          frontUploadWorkCanvas.current.height - 1,
          1,
          1
        ).data;

        const topLeftRGB = [topLeft[0], topLeft[1], topLeft[2]];
        const bottomRightRGB = [bottomRight[0], bottomRight[1], bottomRight[2]];

        let colorDifference;
        for (var i = 0, n = imgData.data.length; i < n; i += 4) {
          var r = imgData.data[i],
            g = imgData.data[i + 1],
            b = imgData.data[i + 2];

          let rgb2 = [r, g, b];

          colorDifference = cie76(topLeftRGB, rgb2);
          if (colorDifference <= 10) {
            imgData.data[i] = newColor.r;
            imgData.data[i + 1] = newColor.g;
            imgData.data[i + 2] = newColor.b;
            imgData.data[i + 3] = newColor.a;
          }

          colorDifference = cie76(bottomRightRGB, rgb2);
          if (colorDifference <= 10) {
            imgData.data[i] = newColor.r;
            imgData.data[i + 1] = newColor.g;
            imgData.data[i + 2] = newColor.b;
            imgData.data[i + 3] = newColor.a;
          }
        }

        ctxFrontImage.putImageData(imgData, 0, 0);
      } else {
        const imgData = ctxBackImage.getImageData(
          0,
          0,
          backUploadWorkCanvas.current.width,
          backUploadWorkCanvas.current.height
        );
        const newColor = { r: 0, g: 0, b: 0, a: 0 };

        // *get coordinates color
        // !ctx.getImageData(left, top, width, height)
        const topLeft = ctxBackImage.getImageData(0, 0, 1, 1).data;
        const bottomRight = ctxBackImage.getImageData(
          backUploadWorkCanvas.current.width - 1,
          backUploadWorkCanvas.current.height - 1,
          1,
          1
        ).data;

        const topLeftRGB = [topLeft[0], topLeft[1], topLeft[2]];
        const bottomRightRGB = [bottomRight[0], bottomRight[1], bottomRight[2]];

        let colorDifference;
        for (var i = 0, n = imgData.data.length; i < n; i += 4) {
          var r = imgData.data[i],
            g = imgData.data[i + 1],
            b = imgData.data[i + 2];

          let rgb2 = [r, g, b];

          colorDifference = cie76(topLeftRGB, rgb2);
          if (colorDifference <= 10) {
            imgData.data[i] = newColor.r;
            imgData.data[i + 1] = newColor.g;
            imgData.data[i + 2] = newColor.b;
            imgData.data[i + 3] = newColor.a;
          }

          colorDifference = cie76(bottomRightRGB, rgb2);
          if (colorDifference <= 10) {
            imgData.data[i] = newColor.r;
            imgData.data[i + 1] = newColor.g;
            imgData.data[i + 2] = newColor.b;
            imgData.data[i + 3] = newColor.a;
          }
        }

        ctxBackImage.putImageData(imgData, 0, 0);
      }
    } catch (error) {
      console.log(
        "file: design-tool.js => line 942 => removeBackground => error",
        error
      );
    }
  };

  const revertBackground = () => {
    try {
      if (productAngle === "front") {
        const imgData = ctxFrontImage.getImageData(
          0,
          0,
          frontUploadWorkCanvas.current.width,
          frontUploadWorkCanvas.current.height
        );
        for (let i = 0; i < imgData.data.length; i += 1) {
          imgData.data[i] = FRONT_ORIGINAL_IMAGE_DATA[i];
        }
        ctxFrontImage.putImageData(imgData, 0, 0);
      }
    }
    catch (error) {
      console.log("file: design-tool.js => line 1002 => reset => error", error);
    }
  }

  const reset = () => {
    try {
      if (productAngle === "front") {
        const imgData = ctxFrontImage.getImageData(
          0,
          0,
          frontUploadWorkCanvas.current.width,
          frontUploadWorkCanvas.current.height
        );
        for (let i = 0; i < imgData.data.length; i += 1) {
          imgData.data[i] = FRONT_ORIGINAL_IMAGE_DATA[i];
        }
        ctxFrontImage.putImageData(imgData, 0, 0);
        setFrontUploadRotate(0);
        setFrontUploadXAxis(1);
        setFrontUploadYAxis(1);
        // ctxFrontImage.reset();
      } else {
        const imgData = ctxBackImage.getImageData(
          0,
          0,
          backUploadWorkCanvas.current.width,
          backUploadWorkCanvas.current.height
        );
        for (let i = 0; i < imgData.data.length; i += 1) {
          imgData.data[i] = BACK_ORIGINAL_IMAGE_DATA[i];
        }
        ctxBackImage.putImageData(imgData, 0, 0);
      }
    } catch (error) {
      console.log("file: design-tool.js => line 1002 => reset => error", error);
    }
  };

  function rgbToHsv(r, g, b) {
    [r, g, b] = [r / 255, g / 255, b / 255];
    let maxc = Math.max(r, g, b);
    let minc = Math.min(r, g, b);
    let rangec = (maxc - minc);
    let v = maxc;
    if (minc == maxc) {
      return [0.0, 0.0, v * 255];
    }
    let s = rangec / maxc;
    let rc = (maxc - r) / rangec;
    let gc = (maxc - g) / rangec;
    let bc = (maxc - b) / rangec;
    if (r == maxc) {
      var h = bc - gc;
    } else if (g == maxc) {
      var h = 2.0 + rc - bc;
    } else {
      var h = 4.0 + gc - rc;
    }
    var h = (h / 6.0) % 1.0;
    let result = [h * 359, s * 255, v * 255];
    if (result[0] < 0) {
      result[0] = 359 - result[0];
    }
    if (result[1] < 0) {
      result[1] = 255 - result[1];
    }
    if (result[2] < 0) {
      result[2] = 255 - result[2];
    }
    return result;
  }

  function hsvToRgb(h, s, v) {
    [h, s, v] = [h / 359, s / 255, v / 255];
    if (s == 0.0) {
      return [v * 255, v * 255, v * 255];
    }
    let i = Math.floor(h * 6.0);
    let f = (h * 6.0) - i;
    let p = v * (1.0 - s);
    let q = v * (1.0 - s * f);
    let t = v * (1.0 - s * (1.0 - f))
    i = i % 6;
    if (i == 0)
      return [v * 255, t * 255, p * 255]
    if (i == 1)
      return [q * 255, v * 255, p * 255]
    if (i == 2)
      return [p * 255, v * 255, t * 255]
    if (i == 3)
      return [p * 255, q * 255, v * 255]
    if (i == 4)
      return [t * 255, p * 255, v * 255]
    if (i == 5)
      return [v * 255, p * 255, q * 255]
  }

  function inRange(c, cf) {
    let [wh1, ws1, wv1] = [0, 0, 0];
    let [wh2, ws2, wv2] = [0, 0, 255];
    if (((String(c) >= "0,0,0") && (String(c) <= "0,0,255"))
      || ((String(cf) >= "0,0,0") && (String(cf) <= "0,0,255"))) {
      return ((c[0] >= wh1) && (c[0] <= wh2)) && ((c[1] >= ws1) && (c[1] <= ws2)) && ((c[2] >= wv1) && (c[2] <= wv2));
    }
    let [h1, s1, v1] = [cf[0] - 30, 100, 100];
    let [h2, s2, v2] = [cf[0] + 30, 255, 255];
    let result = ((c[0] >= h1) && (c[0] <= h2)) && ((c[1] >= s1) && (c[1] <= s2)) && ((c[2] >= v1) && (c[2] <= v2))
    return result;
  }

  function changeImageColorGeneric(imgData, originalColor, newColor) {
    let [fr, fg, fb] = [originalColor.red, originalColor.green, originalColor.blue];
    // let [fh, fs, fv] = rgbToHsv(originalColor.red, originalColor.green, originalColor.blue);
    let [th, ts, tv] = rgbToHsv(newColor.red, newColor.green, newColor.blue);
    for (var i = 0, n = imgData.data.length; i < n; i += 4) {
      var r = imgData.data[i],
        g = imgData.data[i + 1],
        b = imgData.data[i + 2];

      var or = FRONT_ORIGINAL_IMAGE_DATA[i],
        og = FRONT_ORIGINAL_IMAGE_DATA[i + 1],
        ob = FRONT_ORIGINAL_IMAGE_DATA[i + 2];

      let [h, s, v] = rgbToHsv(r, g, b);
      let [oh, os, ov] = rgbToHsv(or, og, ob);
      if (cie76([r, g, b], [fr, fg, fb]) <= 30) {
        h = th;
        s = ts;
        v = ov;
        [r, g, b] = hsvToRgb(h, s, v);
        imgData.data[i] = r;
        imgData.data[i + 1] = g;
        imgData.data[i + 2] = b;
      }

      // if (inRange([h, s, v], [fh, fs, fv])) {
      //   h = th;
      //   s = ts;
      //   v = ov;
      //   [r, g, b] = hsvToRgb(h, s, v);
      //   imgData.data[i] = r;
      //   imgData.data[i + 1] = g;
      //   imgData.data[i + 2] = b;
      // }
    }
  }

  const changeFrontImageColor = (color) => {
    try {
      if (productAngle === "front") {
        const imgData = ctxFrontImage.getImageData(
          0,
          0,
          frontUploadWorkCanvas.current.width,
          frontUploadWorkCanvas.current.height
        );

        changeImageColorGeneric(imgData, originalColor, color);

        // const newColor = {
        //   r: color.red,
        //   g: color.green,
        //   b: color.blue,
        //   a: 255,
        // };
        // console.log("newColor");
        // console.log(newColor);
        // const rgb1 = [
        //   originalColor.red,
        //   originalColor.green,
        //   originalColor.blue,
        // ];

        // for (var i = 0, n = imgData.data.length; i < n; i += 4) {
        //   var r = imgData.data[i],
        //     g = imgData.data[i + 1],
        //     b = imgData.data[i + 2];

        //   let rgb2 = [r, g, b];
        //   let colorDifference = cie76(rgb1, rgb2);
        //   if (colorDifference <= 10) {
        //     imgData.data[i] = newColor.r;
        //     imgData.data[i + 1] = newColor.g;
        //     imgData.data[i + 2] = newColor.b;
        //     imgData.data[i + 3] = newColor.a;
        //   }
        // }
        ctxFrontImage.putImageData(imgData, 0, 0);

        const updatedArtColors = [...frontArtColors];
        updatedArtColors[originalColorIndex] = color;
        setFrontArtColor(updatedArtColors);
        originalColor = color;
      } else {
        const imgData = ctxBackImage.getImageData(
          0,
          0,
          backUploadWorkCanvas.current.width,
          backUploadWorkCanvas.current.height
        );

        changeImageColorGeneric(imgData, originalColor, color);

        // const newColor = {
        //   r: color.red,
        //   g: color.green,
        //   b: color.blue,
        //   a: 255,
        // };
        // const rgb1 = [
        //   originalColor.red,
        //   originalColor.green,
        //   originalColor.blue,
        // ];

        // for (var i = 0, n = imgData.data.length; i < n; i += 4) {
        //   var r = imgData.data[i],
        //     g = imgData.data[i + 1],
        //     b = imgData.data[i + 2];

        //   let rgb2 = [r, g, b];
        //   let colorDifference = cie76(rgb1, rgb2);
        //   if (colorDifference <= 10) {
        //     imgData.data[i] = newColor.r;
        //     imgData.data[i + 1] = newColor.g;
        //     imgData.data[i + 2] = newColor.b;
        //     imgData.data[i + 3] = newColor.a;
        //   }
        // }
        ctxBackImage.putImageData(imgData, 0, 0);

        const updatedArtColors = [...backArtColors];
        updatedArtColors[originalColorIndex] = color;
        setBackArtColor(updatedArtColors);
        originalColor = color;
      }
    } catch (error) {
      console.log(
        "file: design-tool.js => line 1094 => changeFrontImageColor => error",
        error
      );
    }
  };
  //  !upload image to canvas end

  const renderSwatches = () => {
    try {
      const length =
        productAngle === "front" ? frontArtColors.length : backArtColors.length;
      const colors = productAngle === "front" ? frontArtColors : backArtColors;
      totalImageColors =
        productAngle === "front" ? frontArtColors.length : backArtColors.length;
      return colors.map((color, i) => {
        return (
          <Typography
            key={i}
            component="span"
            className="image-color"
            style={{
              backgroundColor: rgb(color["red"], color["green"], color["blue"]),
            }}
          />
        );
      });
    } catch (error) {
      console.log("Login -> error", error);
    }
  };

  // *For Art Work
  const changeArtWork = (artId, url) => {
    try {
      if (productAngle === "front") {
        const widthInch = 80 / frontImageWidthPixel;
        const heightInch = 80 / frontImageHeightPixel;

        const artWidthInch = widthInch.toFixed(2);
        const artHeightInch = heightInch.toFixed(2);

        setIsActive(artId);

        const obj = {
          id: artId,
          url: url,
          left: 0,
          top: 0,
          width: 80,
          height: 80,
          flipX: 1,
          flipY: 1,
          invertColor: false,
          invertColorClass: "",
          artInchWidth: artWidthInch,
          artInchHeight: artHeightInch,
          rotate: 0,
        };
        if (artChangeStatus) {
          let artChangeValue = [...frontArtWork];
          artChangeValue[artIndex] = obj;
          setFrontArtWork(artChangeValue);
          setArtChangeStatus(false);
        } else {
          setFrontArtWork((frontArtWork) => [...frontArtWork, obj]);
        }
        // setArtWorkPanel(obj)
        console.log(
          "file: design-tool.js => line 1231 => changeArtWork => frontArtWork",
          frontArtWork
        );
        artReset();
      } else {
        setBackArtWork(url);
        artReset();
      }
    } catch (error) {
      console.log("Login -> error", error);
    }
  };

  // *For Text Size Into Inches
  const setTextSize = (currentWidthPx, currentHeightPx) => {
    try {
      if (productAngle === "front") {
        let definedFrontWidthPx = frontImageWidthPixel;
        let definedFrontHeightPx = frontImageHeightPixel;
        let frontWidthPxIntoInches = currentWidthPx / definedFrontWidthPx;
        let frontHeightPxIntoInches = currentHeightPx / definedFrontHeightPx;
        setFrontArtWPxIntoInches(frontWidthPxIntoInches.toFixed(2));
        setFrontTextSize(frontHeightPxIntoInches.toFixed(2));
      } else {
        // let definedBackWidthPx = backImageWidthPixel;
        let definedBackHeightPx = backImageHeightPixel;
        // let backWidthPxIntoInches = (currentWidthPx / definedBackWidthPx);
        let backHeightPxIntoInches = currentHeightPx / definedBackHeightPx;

        // setBackArtWPxIntoInches(backWidthPxIntoInches.toFixed(2));
        setBackTextSize(backHeightPxIntoInches.toFixed(2));
      }
    } catch (error) {
      console.log("Login -> error", error);
    }
  };

  // *For Add Art Size
  const setArtInches = (index, currentWidthPx, currentHeightPx) => {
    try {
      if (productAngle === "front") {
        let definedFrontWidthPx = frontImageWidthPixel;
        let definedFrontHeightPx = frontImageHeightPixel;
        let frontWidthPxIntoInches = currentWidthPx / definedFrontWidthPx;
        let frontHeightPxIntoInches = currentHeightPx / definedFrontHeightPx;

        frontArtWork[index].artInchWidth = frontWidthPxIntoInches.toFixed(2);
        frontArtWork[index].artInchHeight = frontHeightPxIntoInches.toFixed(2);

        setFrontArtWPxIntoInches(frontWidthPxIntoInches.toFixed(2));
        // setFrontArtHPxIntoInches(frontHeightPxIntoInches.toFixed(2));
      } else {
        let definedBackWidthPx = backImageWidthPixel;
        let definedBackHeightPx = backImageHeightPixel;
        let backWidthPxIntoInches = currentWidthPx / definedBackWidthPx;
        let backHeightPxIntoInches = currentHeightPx / definedBackHeightPx;

        setBackArtWPxIntoInches(backWidthPxIntoInches.toFixed(2));
        setBackArtHPxIntoInches(backHeightPxIntoInches.toFixed(2));
      }
    } catch (error) {
      console.log("Login -> error", error);
    }
  };

  // *For Upload Image Size
  const setUploadInches = (currentWidthPx, currentHeightPx) => {
    try {
      if (productAngle === "front") {
        let definedFrontWidthPx = frontImageWidthPixel;
        let definedFrontHeightPx = frontImageHeightPixel;
        let frontWidthPxIntoInches = currentWidthPx / definedFrontWidthPx;
        let frontHeightPxIntoInches = currentHeightPx / definedFrontHeightPx;
        // changeImageWidthSizeValue.current.value = frontWidthPxIntoInches.toFixed(2)
        setFrontUploadWPxIntoInches(frontWidthPxIntoInches.toFixed(2));
        setFrontUploadHPxIntoInches(frontHeightPxIntoInches.toFixed(2));
        changeImageWidthSizeValue.current.value = frontWidthPxIntoInches.toFixed(2);
      } else {
        let definedBackWidthPx = backImageWidthPixel;
        let definedBackHeightPx = backImageHeightPixel;
        let backWidthPxIntoInches = currentWidthPx / definedBackWidthPx;
        let backHeightPxIntoInches = currentHeightPx / definedBackHeightPx;

        setBackUploadWPxIntoInches(backWidthPxIntoInches.toFixed(2));
        setBackUploadHPxIntoInches(backHeightPxIntoInches.toFixed(2));
      }
    } catch (error) {
      console.log("Login -> error", error);
    }
  };

  // *Get Add Art size
  const getArtSize = (index, size) => {
    try {
      console.log(
        "file: design-tool.js => line 1319 => getArtSize => size",
        size
      );
      console.log(
        "file: design-tool.js => line 1319 => getArtSize => index",
        index
      );
      // setFrontArtCanvasWidth(size.width)
      // setFrontArtCanvasHeight(size.height)
      frontArtWork[index].width = size.width;
      frontArtWork[index].height = size.height;

      // setFrontArtWork((frontArtWork) => [...frontArtWork, obj]);
      console.log(
        "file: design-tool.js => line 1325 => getArtSize => frontArtWork",
        frontArtWork
      );
    } catch (error) {
      console.log(
        "file: design-tool.js => line 1322 => getArtSize => error",
        error
      );
    }
  };

  // *For Print Area
  const getPrintArea = (
    width,
    height,
    left,
    canvasFrontImageLeft,
    canvasFrontImageTop,
    canvasFrontImageWidth,
    canvasFrontImageHeight,
    canvasBackImageLeft,
    canvasBackImageTop,
    canvasBackImageWidth,
    canvasBackImageHeight
  ) => {
    try {
      // *for front print area
      let frontOffsetLeft = (canvasFrontImageLeft * width) / 100;
      frontOffsetLeft += left;
      let frontOffsetTop = (canvasFrontImageTop * height) / 100;
      let frontPrintAreaWidth = (canvasFrontImageWidth * width) / 100;
      let frontPrintAreaHeight = (canvasFrontImageHeight * height) / 100;
      setFrontLeftOffsetPA(frontOffsetLeft);
      setFrontTopOffsetPA(frontOffsetTop);
      setFrontWidthPA(frontPrintAreaWidth);
      setFrontHeightPA(frontPrintAreaHeight);

      // *for back print area
      let backOffsetLeft = (canvasBackImageLeft * width) / 100;
      backOffsetLeft += left;
      let backOffsetTop = (canvasBackImageTop * height) / 100;
      let backPrintAreaWidth = (canvasBackImageWidth * width) / 100;
      let backPrintAreaHeight = (canvasBackImageHeight * height) / 100;
      setBackLeftOffsetPA(backOffsetLeft);
      setBackTopOffsetPA(backOffsetTop);
      setBackWidthPA(backPrintAreaWidth);
      setBackHeightPA(backPrintAreaHeight);
    } catch (error) {
      console.log("Login -> error", error);
    }
  };

  // *For Art Lock Unlock Button
  const artLockUnlockProportional = (type) => {
    try {
      if (type === true) {
        if (productAngle === "front") {
          setFrontArtCanvasHeight(frontArtCanvasWidth);
          setFrontIsArtProportional(true);
          setFrontArtLockAspectRatio(true);
        } else {
          setBackArtCanvasHeight(backArtCanvasWidth);
          setBackIsArtProportional(true);
          setBackArtLockAspectRatio(true);
        }
      } else {
        if (productAngle === "front") {
          setFrontIsArtProportional(false);
          setFrontArtLockAspectRatio(false);
        } else {
          setBackIsArtProportional(false);
          setBackArtLockAspectRatio(false);
        }
      }
    } catch (error) {
      console.log("Login -> error", error);
    }
  };

  // *For Upload Lock Unlock Button
  const uploadLockUnlockProportional = (type) => {
    try {
      if (type === true) {
        if (productAngle === "front") {
          setFrontUploadCanvasHeight(frontUploadCanvasWidth);
          setFrontIsUploadProportional(true);
          setFrontUploadLockAspectRatio(true);
        } else {
          setBackUploadCanvasHeight(backUploadCanvasWidth);
          setBackIsUploadProportional(true);
          setBackUploadLockAspectRatio(true);
        }
      } else {
        if (productAngle === "front") {
          setFrontIsUploadProportional(false);
          setFrontUploadLockAspectRatio(false);
        } else {
          setBackIsUploadProportional(false);
          setBackUploadLockAspectRatio(false);
        }
      }
    } catch (error) {
      console.log("Login -> error", error);
    }
  };

  // *For Art Flip YAxis
  const flipArtYAxis = (axis) => {
    console.log(
      "file: design-tool.js => line 1566 => flipArtYAxis => axis",
      axis
    );
    try {
      if (productAngle === "front") {
        if (axis === 1) {
          setFrontArtYAxis(-1);
          frontArtWork[artIndex].flipY = -1;
          console.log(
            "file: design-tool.js => line 1552 => flipArtYAxis => frontArtWork[artIndex]",
            frontArtWork[artIndex]
          );
        } else {
          setFrontArtYAxis(1);
          frontArtWork[artIndex].flipY = 1;
        }
      } else {
        if (axis === 1) {
          setBackArtYAxis(-1);
        } else {
          setBackArtYAxis(1);
        }
      }
    } catch (error) {
      console.log("Login -> error", error);
    }
  };

  // *For Art Flip XAxis
  const flipArtXAxis = (axis) => {
    console.log(
      "file: design-tool.js => line 1590 => flipArtXAxis => axis",
      axis
    );
    try {
      if (productAngle === "front") {
        if (axis === 1) {
          setFrontArtXAxis(-1);
          frontArtWork[artIndex].flipX = -1;
        } else {
          setFrontArtXAxis(1);
          frontArtWork[artIndex].flipX = 1;
        }
      } else {
        if (axis === 1) {
          setBackArtXAxis(-1);
        } else {
          setBackArtXAxis(1);
        }
      }
    } catch (error) {
      console.log("Login -> error", error);
    }
  };

  // *For Upload Image Flip YAxis
  const flipUploadYAxis = (axis) => {
    try {
      if (productAngle === "front") {
        if (axis === 1) {
          setFrontUploadYAxis(-1);
        } else {
          setFrontUploadYAxis(1);
        }
      } else {
        if (axis === 1) {
          setBackUploadYAxis(-1);
        } else {
          setBackUploadYAxis(1);
        }
      }
    } catch (error) {
      console.log("Login -> error", error);
    }
  };

  // *For Upload Image Flip XAxis
  const flipUploadXAxis = (axis) => {
    try {
      if (productAngle === "front") {
        if (axis === 1) {
          setFrontUploadXAxis(-1);
        } else {
          setFrontUploadXAxis(1);
        }
      } else {
        if (axis === 1) {
          setBackUploadXAxis(-1);
        } else {
          setBackUploadXAxis(1);
        }
      }
    } catch (error) {
      console.log("Login -> error", error);
    }
  };

  // *For Text Rotate Value
  const setTextRotateValue = (event, value) => {
    try {
      if (productAngle === "front") {
        setFrontTextRotate(value === "" ? "0" : Number(value));
      } else {
        setBackTextRotate(value === "" ? "0" : Number(value));
      }
    } catch (error) {
      console.log("Login -> error", error);
    }
  };

  // *For Art Rotate Value
  const setArtRotateValue = (event, value) => {
    try {
      if (productAngle === "front") {
        setFrontArtRotate(value === "" ? "0" : Number(value));
        frontArtWork[artIndex].rotate = value === "" ? "0" : Number(value);
      } else {
        setBackArtRotate(value === "" ? "0" : Number(value));
      }
    } catch (error) {
      console.log("Login -> error", error);
    }
  };

  // *For Upload Image Rotate Value
  const setUploadRotateValue = (event, value) => {
    try {
      if (productAngle === "front") {
        setFrontUploadRotate(value === "" ? "0" : Number(value));
      } else {
        setBackUploadRotate(value === "" ? "0" : Number(value));
      }
    } catch (error) {
      console.log("Login -> error", error);
    }
  };

  // *For Blur Rotate Input
  const blurRotate = () => {
    try {
      if (rotateBlur < -180) {
        setRotateBlur(-180);
      } else if (rotateBlur > 180) {
        setRotateBlur(180);
      }
    } catch (error) {
      console.log("Login -> error", error);
    }
  };

  // *For Art Reset
  const artReset = () => {
    try {
      if (productAngle === "front") {
        setFrontArtRotate(0);
        setFrontArtXAxis(1);
        setFrontArtYAxis(1);
      } else {
        setBackArtRotate(0);
        setBackArtXAxis(1);
        setBackArtYAxis(1);
      }
    } catch (error) {
      console.log("Login -> error", error);
    }
  };

  // *For Upload Image Reset
  const uploadReset = () => {
    try {
      if (productAngle === "front") {
        setFrontUploadRotate(0);
        setFrontUploadXAxis(1);
        setFrontUploadYAxis(1);
        // setFrontCanvasImg(originalImage);
      } else {
        setBackUploadRotate(0);
        setBackUploadXAxis(1);
        setBackUploadYAxis(1);
      }
    } catch (error) {
      console.log("Login -> error", error);
    }
  };

  // *For Text Add To Design
  const addToDesign = () => {
    try {
      if (productAngle === "front") {
        if (frontTextInput === "") {
          return;
        } else {
          setFrontText(frontTextInput);
          // *dynamically assign the width and height to frontTextCanvas
          const canvasEle = frontTextCanvas.current;
          // canvasEle.width = canvasEle.clientWidth;
          canvasEle.width = canvasEle.getBoundingClientRect().width;
          // canvasEle.height = canvasEle.clientHeight;
          canvasEle.height = canvasEle.getBoundingClientRect().height;

          // *get context of the frontTextCanvas
          ctx = canvasEle.getContext("2d");
          // canvas.width = canvas.getBoundingClientRect().width;
          // canvas.height = canvas.getBoundingClientRect().height;

          writeText(
            frontTextInput,
            frontFontFamily,
            frontFontColor,
            frontFontOutlineColor,
            frontTextOutlineThickness
          );
          ctx.save();
          // ctx.restore();
          // ctx.reset();
          getFontEditPanel(false);

          setIsActive(frontTextCanvas);
          setTimeout(() => {
            changeFontColor("#000");
          }, 500);
        }
      } else {
        if (backTextInput === "") {
          return;
        } else {
          setBackText(backTextInput);
          // *dynamically assign the width and height to backTextCanvas
          const canvasEle = backTextCanvas.current;
          // canvasEle.width = canvasEle.clientWidth;
          // canvasEle.height = canvasEle.clientHeight;

          // *get context of the backTextCanvas
          ctx = canvasEle.getContext("2d");
          writeText(
            backTextInput,
            backFontFamily,
            backFontColor,
            backFontOutlineColor,
            backTextOutlineThickness
          );
          getFontEditPanel(false);
        }
      }
    } catch (error) {
      console.log(
        "file: design-tool.js => line 1411 => addToDesign => error",
        error
      );
    }
  };


  const handleTextFontSize = () => {
    const textSizer = document.querySelector("#front-text-box");
    const widthAttribute = window
      .getComputedStyle(textSizer)
      .getPropertyValue("width");
    const heightAttribute = window
      .getComputedStyle(textSizer)
      .getPropertyValue("height");
    if (widthAttribute.split("px")[0] > heightAttribute.split("px")[0]) {
      setValue(
        "text_value_size",
        Math.abs(
          (
            widthAttribute.split("px")[0] - heightAttribute.split("px")[0]
          ).toFixed(2)
        )
      );
    }
    else if (heightAttribute.split("px")[0] > widthAttribute.split("px")[0]) {

      setValue(
        "text_value_size",
        Math.abs(
          (
            heightAttribute.split("px")[0] - widthAttribute.split("px")[0]
          ).toFixed(2)
        )
      );
    } else {
      setValue("text_value_size", (0).toFixed(2));
    }
  };

  // *For Write Text
  const writeText = (
    input,
    selectedFont,
    selectedColor,
    selectedOutlineColor,
    textOutlineThickness
  ) => {
    const text = input;
    const x = 0;
    const y = fontSizeHeight / 1.3; // *here 1.3 is line height for center text vertical
    console.log(
      "file: design-tool.js => line 1823 => writeText => fontSizeHeight",
      fontSizeHeight
    );
    var fontSize = fontSizeHeight;
    const fontFamily = selectedFont;
    const color = selectedColor;
    const outlineColor = selectedOutlineColor;

    // *For text outline
    var fontOutline;
    if (textOutlineThickness === 0) {
      fontOutline = 0;
    } else if (textOutlineThickness === 40) {
      fontOutline = 4;
    } else if (textOutlineThickness === 80) {
      fontOutline = 8;
    } else if (textOutlineThickness === 100) {
      fontOutline = 10;
    }

    const textAlign = "left";
    const textBaseline = "middle";

    // *Get Font Size
    const canvasWidth = frontWidthPA;
    const defaultSize = 70;

    const ratio = defaultSize / canvasWidth;
    setTestingFontSize(ctx.measureText(text).width * ratio);

    ctx.beginPath();
    ctx.font = fontSize + "px " + fontFamily;
    ctx.textAlign = textAlign;
    ctx.textBaseline = textBaseline;
    ctx.fillStyle = color;

    ctx.strokeStyle = outlineColor;
    ctx.lineWidth = `${fontOutline <= 0 ? 0.001 : fontOutline}`;
    ctx.miterLimit = 2;

    if (productAngle === "front") {
      frontTextWidth = ctx.measureText(text).width;
      frontTextHeight = fontSizeHeight * 1.3; // *here 1.3 is line height

      if (frontTextWidth < frontWidthPA) {
        ctx.strokeText(text, x, y);
        ctx.fillText(text, x, y);
        ctx.stroke();
      } else {
        do {
          fontSize -= 0.5;
          ctx.font = fontSize + "px " + fontFamily;
        } while (ctx.measureText(text).width > frontWidthPA);

        do {
          frontTextWidth = frontWidthPA;
        } while (frontTextWidth > frontWidthPA);
      }
    } else {
      backTextWidth = ctx.measureText(text).width;
      backTextHeight = fontSize * 1.3; // *here 1.3 is line height

      if (backTextWidth < backWidthPA) {
        ctx.strokeText(text, x, y);
        ctx.fillText(text, x, y);
        ctx.stroke();
      } else {
        do {
          fontSize -= 0.5;
          ctx.font = fontSize + "px " + fontFamily;
        } while (ctx.measureText(text).width > backWidthPA);

        do {
          backTextWidth = backWidthPA;
        } while (backTextWidth > backWidthPA);
      }
    }

    ctx.strokeText(text, x, y);
    ctx.fillText(text, x, y);
    ctx.stroke();
  };

  // *For Set Text Input Value
  const setTextValue = (event, panel) => {
    console.log(
      "file: design-tool.js => line 1953 => setTextValue => panel",
      event.target.value
    );
    try {
      if (productAngle === "front") {
        setFrontTextInput(event.target.value);
        if (panel === "edit") {
          // *dynamically assign the width and height to frontTextCanvas
          // const canvasEle = frontTextCanvas.current;
          // canvasEle.width = canvasEle.clientWidth;
          // canvasEle.height = canvasEle.clientHeight;

          // *get context of the frontTextCanvas
          // ctx = canvasEle.getContext("2d");
          // frontTextWidth = ctx.measureText(event.target.value).width;
          // frontTextHeight = fontSizeHeight * 1.3; // *here 1.3 is line height
          // writeText(
          //   event.target.value,
          //   frontFontFamily,
          //   frontFontColor,
          //   frontFontOutlineColor,
          //   frontTextOutlineThickness
          // );
          // changeFontColor("#000");
          addToDesign();
        }
      } else {
        setBackTextInput(event.target.value);
        if (panel === "edit") {
          // *dynamically assign the width and height to backTextCanvas
          const canvasEle = backTextCanvas.current;
          // canvasEle.width = canvasEle.clientWidth;
          // canvasEle.height = canvasEle.clientHeight;

          // *get context of the backTextCanvas
          ctx = canvasEle.getContext("2d");
          writeText(
            event.target.value,
            backFontFamily,
            backFontColor,
            backFontOutlineColor,
            backTextOutlineThickness
          );
        }
      }
    } catch (error) {
      console.log("Login -> error", error);
    }
  };

  // *For Font Family
  const changeFontFamily = (fontFamily, fontType) => {
    console.log(
      "file: design-tool.js => line 1463 => changeFontFamily => fontType",
      fontType
    );
    console.log(
      "file: design-tool.js => line 1464 => changeFontFamily => fontFamily",
      fontFamily
    );
    try {
      let type = fontType === "ttf" ? "truetype" : "opentype";
      if (productAngle === "front") {
        setFrontFontFamily(fontFamily);
        setFrontFontLowerCase(fontFamilyLowerCase(fontFamily, fontType));
        setFrontFontType(type);

        // *dynamically assign the width and height to frontTextCanvas
        const canvasEle = frontTextCanvas.current;
        // canvasEle.width = canvasEle.clientWidth;
        // canvasEle.height = canvasEle.clientHeight;

        // *get context of the frontTextCanvas
        ctx = canvasEle.getContext("2d");
        writeText(
          frontTextInput,
          fontFamily,
          frontFontColor,
          frontFontOutlineColor,
          frontTextOutlineThickness
        );
      } else {
        setBackFontFamily(fontFamily);
        setBackFontLowerCase(fontFamilyLowerCase(fontFamily, fontType));
        setBackFontType(type);

        // *dynamically assign the width and height to backTextCanvas
        const canvasEle = backTextCanvas.current;
        // canvasEle.width = canvasEle.clientWidth;
        // canvasEle.height = canvasEle.clientHeight;

        // *get context of the backTextCanvas
        ctx = canvasEle.getContext("2d");
        writeText(
          backTextInput,
          fontFamily,
          backFontColor,
          backFontOutlineColor,
          backTextOutlineThickness
        );
      }
    } catch (error) {
      console.log("Login -> error", error);
    }
  };

  // *For Font Color
  const changeFontColor = (fontColor) => {
    try {
      if (productAngle === "front") {
        setFrontFontColor(fontColor);
        // *dynamically assign the width and height to frontTextCanvas
        const canvasEle = frontTextCanvas.current;
        // canvasEle.width = canvasEle.clientWidth;
        // canvasEle.height = canvasEle.clientHeight;

        // *get context of the frontTextCanvas
        ctx = canvasEle.getContext("2d");
        writeText(
          frontTextInput,
          frontFontFamily,
          fontColor,
          frontFontOutlineColor,
          frontTextOutlineThickness
        );
      } else {
        setBackFontColor(fontColor);
        // *dynamically assign the width and height to backTextCanvas
        const canvasEle = backTextCanvas.current;
        // canvasEle.width = canvasEle.clientWidth;
        // canvasEle.height = canvasEle.clientHeight;

        // *get context of the backTextCanvas
        ctx = canvasEle.getContext("2d");
        writeText(
          backTextInput,
          backFontFamily,
          fontColor,
          backFontOutlineColor,
          backTextOutlineThickness
        );
      }
    } catch (error) {
      console.log("Login -> error", error);
    }
  };

  // *For Text Outline Color
  const changeFontOutlineColor = (textOutlineColor) => {
    try {
      if (productAngle === "front") {
        setFrontFontOutlineColor(textOutlineColor);

        // *dynamically assign the width and height to frontTextCanvas
        const canvasEle = frontTextCanvas.current;
        // canvasEle.width = canvasEle.clientWidth;
        // canvasEle.height = canvasEle.clientHeight;

        // *get context of the frontTextCanvas
        ctx = canvasEle.getContext("2d");
        writeText(
          frontTextInput,
          frontFontFamily,
          frontFontColor,
          textOutlineColor,
          frontTextOutlineThickness
        );
      } else {
        setBackFontOutlineColor(textOutlineColor);
        // *dynamically assign the width and height to backTextCanvas
        const canvasEle = backTextCanvas.current;
        // canvasEle.width = canvasEle.clientWidth;
        // canvasEle.height = canvasEle.clientHeight;

        // *get context of the backTextCanvas
        ctx = canvasEle.getContext("2d");
        writeText(
          backTextInput,
          backFontFamily,
          backFontColor,
          textOutlineColor,
          backTextOutlineThickness
        );
      }
    } catch (error) {
      console.log("Login -> error", error);
    }
  };

  // *For Text Outline Thickness
  const handleThickness = (event, textOutlineThickness) => {
    try {
      if (productAngle === "front") {
        setFrontTextOutlineThickness(textOutlineThickness);
        // *dynamically assign the width and height to frontTextCanvas
        const canvasEle = frontTextCanvas.current;
        // canvasEle.width = canvasEle.clientWidth;
        // canvasEle.height = canvasEle.clientHeight;

        // *get context of the frontTextCanvas
        ctx = canvasEle.getContext("2d");
        writeText(
          frontTextInput,
          frontFontFamily,
          frontFontColor,
          frontFontOutlineColor,
          textOutlineThickness
        );
      } else {
        setBackTextOutlineThickness(textOutlineThickness);
        // *dynamically assign the width and height to backTextCanvas
        const canvasEle = backTextCanvas.current;
        // canvasEle.width = canvasEle.clientWidth;
        // canvasEle.height = canvasEle.clientHeight;

        // *get context of the backTextCanvas
        ctx = canvasEle.getContext("2d");
        writeText(
          backTextInput,
          backFontFamily,
          backFontColor,
          backFontOutlineColor,
          textOutlineThickness
        );
      }
    } catch (error) {
      console.log("Login -> error", error);
    }
  };

  // *For Art Invert Color
  const handleInvertColor = (event) => {
    try {
      let boolean = event.target.checked === true ? true : false;
      if (productAngle === "front") {
        // frontInvertColorClass = boolean === true ? 'invert-color' : '';
        setFrontArtInvertColor(boolean);
        frontArtWork[artIndex].invertColorClass =
          boolean === true ? "invert-color" : "";
        frontArtWork[artIndex].invertColor = boolean;
      } else {
        backInvertColorClass = boolean === true ? "invert-color" : "";
        setBackArtInvertColor(boolean);
      }
    } catch (error) {
      console.log("Login -> error", error);
    }
  };

  // *For Make One Color Of Upload Image
  const handleMakeOneColor = (event) => {
    try {
      let boolean = event.target.checked === true ? true : false;
      setIsMakeOneColor(boolean);
      if (boolean === true) {
        let selectedColor = colors[0].code; // *pick first color from colors array
        console.log(
          "🚀 ~ file: design-tool.js ~ line 2102 ~ handleMakeOneColor ~ selectedColor",
          selectedColor
        );
        makeOneColor(selectedColor);
      } else {
        if (productAngle === "front") {
          setFrontUploadWork(frontImageBeforeOneColor);
        } else {
          setBackUploadWork(backImageBeforeOneColor);
        }
      }
    } catch (error) {
      console.log("Login -> error", error);
    }
  };

  const makeOneColor = async (color) => {
    try {
      let isHex = /^#([0-9A-F]{3}){1,2}$/i.test(color);
      if (isHex === false) {
        selectedColor = rgbToHex(color);
      } else {
        selectedColor = color;
      }
      setSelectedOneColor(selectedColor);
      // var obj = {
      //   selected_color: selectedColor,
      //   image: productAngle === 'front' ? frontUploadWorkCanvas : backUploadWork
      // };
      const imgData = ctxFrontImage.getImageData(
        0,
        0,
        frontUploadWorkCanvas.current.width,
        frontUploadWorkCanvas.current.height
      );
      // console.log("🚀 ~ file: design-tool.js ~ line 2222 ~ makeOneColor ~ imgData", imgData)
      const px = imgData.data;
      // console.log("🚀 ~ file: design-tool.js ~ line 2224 ~ makeOneColor ~ px", px)
      const length = px.length;
      // console.log("🚀 ~ file: design-tool.js ~ line 2226 ~ makeOneColor ~ length", length)
      for (var i = 0; i < length; i += 4) {
        const grey = px[i] * 0.3 + px[i + 1] * 0.59 + px[i + 2] * 0.11;
        px[i] = px[i + 1] = px[i + 2] = grey;
      }
      // const imgData = context.getImageData(0, 0, canvas.width, canvas.height)
      // for (let i = 0; i < imgData.data.length; i += 4) {
      //   imgData.data[i] = 255
      // }
      ctxFrontImage.putImageData(imgData, 0, 0);
    } catch (error) {
      console.log("Login -> error", error);
    }
  };

  // *Save And Share Design Dialog
  const saveAndShareDesignDialog = () => {
    const boolean = isSaveAndShareDialog === true ? false : true;
    setIsSaveAndShareDialog(boolean);
    setValues({
      name: "",
      email: "",
    });
  };

  // *Save And Share Design
  const saveAndShareDesign = async (name, email) => {
    setLoadingStatus(true)
    let validateForm = saveAndShareDialogFormRef?.current?.reportValidity();
    if (validateForm === false) {
      return;
    } else {
      let designArtWork = [];
      let designUploadWork = [];
      let designText = [];

      // *for front text only
      if (frontText !== "") {
        designText.push({
          angle: "front",
          text: frontText,
          font_id: 1,
          font_type: 'ttf',
          font_path: "/storage/fonts/arial.ttf",
          font_family: frontFontFamily,
          font_color: frontFontColor,
          font_outline_color: frontFontOutlineColor,
          font_size: 20,
          text_outline_value: frontTextOutlineThickness,
          top: frontTextTop,
          left: frontTextLeft,
          rotation: frontTextRotate,
          text_alignment: "right",
          layer_sequence: 1,
        });
      }
      // *for back text only
      if (backText !== "") {
        designText.push({
          angle: "back",
          text: backText,
          font_id: 1,
          font_type: 'ttf',
          font_path: "/storage/fonts/arial.ttf",
          font_family: backFontFamily,
          font_color: backFontColor,
          font_outline_color: backFontOutlineColor,
          font_size: 20,
          text_outline_value: backTextOutlineThickness,
          top: backTextTop,
          left: backTextLeft,
          rotation: backTextRotate,
          text_alignment: "right",
          layer_sequence: 1,
        });
      }

      // *for front artwork
      if (frontArtWork !== "") {
        designArtWork.push({
          angle: "front",
          // image_url: frontArtWork,
          image_url: 'https://api-designtool.optech.pk/api/customer/get-default-images',
          top: frontArtTop,
          left: frontArtLeft,
          rotation: frontArtRotate,
          flip_x: frontArtXAxis,
          flip_y: frontArtYAxis,
          is_inverted: false,
          layer_sequence: 1,
          px_to_inch_width: frontArtWPxIntoInches,
          px_to_inch_height: frontArtHPxIntoInches,
          width: frontArtCanvasWidth,
          height: frontArtCanvasHeight,
        });
      }

      // *for back artwork
      if (backArtWork !== "") {
        designArtWork.push({
          angle: "back",
          // image_url: backArtWork,
          image_url: 'https://api-designtool.optech.pk/api/customer/get-default-images',
          top: backArtTop,
          left: backArtLeft,
          rotation: backArtRotate,
          flip_x: backArtXAxis,
          flip_y: backArtYAxis,
          is_inverted: false,
          layer_sequence: 1,
          px_to_inch_width: backArtWPxIntoInches,
          px_to_inch_height: backArtHPxIntoInches,
          width: backArtCanvasWidth,
          height: backArtCanvasHeight,
        });
      }
      // *for front upload work
      if (frontUploadWork !== "") {
        console.log("front")
        let obj = {
          angle: "front",
          image: frontFileUploadImage,
          top: frontUploadTop,
          left: frontUploadLeft,
          rotation: frontUploadRotate,
          flip_x: frontUploadXAxis,
          flip_y: frontUploadYAxis,
          px_to_inch_width: frontUploadWPxIntoInches,
          px_to_inch_height: frontUploadHPxIntoInches,
          width: frontUploadCanvasWidth,
          height: frontUploadCanvasHeight,
          image_color: [{ "color": "#fff" }, { "color": "#000" }, { "color": "#eee" }],
          is_make_one_color: false,
          is_background_remove: true,
          layer_sequence: 1,
        }
        designUploadWork.push(obj);
      }
      else {
        console.log("front")
        let obj = {
          angle: "front",
          image: frontFileUploadImage,
          top: frontUploadTop,
          left: frontUploadLeft,
          rotation: frontUploadRotate,
          flip_x: frontUploadXAxis,
          flip_y: frontUploadYAxis,
          px_to_inch_width: frontUploadWPxIntoInches,
          px_to_inch_height: frontUploadHPxIntoInches,
          width: frontUploadCanvasWidth,
          height: frontUploadCanvasHeight,
          image_color: [{ color: "#fff" }, { color: "#000" }, { color: "#eee" }],
          is_make_one_color: false,
          is_background_remove: true,
          layer_sequence: 1,
        }
        designUploadWork.push(obj)
      }

      // *for back upload work
      if (backUploadWork !== "") {
        // let obj={
        //   angle: "back",
        //   image_url: frontFileUploadImage,
        //   top: backUploadTop,
        //   left: backUploadLeft,
        //   rotation: backUploadRotate,
        //   flip_x: backUploadXAxis,
        //   flip_y: backUploadYAxis,
        //   px_to_inch_width: backUploadWPxIntoInches,
        //   px_to_inch_height: backUploadHPxIntoInches,
        //   width: backUploadCanvasWidth,
        //   height: backUploadCanvasHeight,
        //   image_color: [{ "color": "#fff" }, { "color": "#000" }, { "color": "#eee" }],
        //   is_make_one_color: false,
        //   is_background_remove: true,
        //   layer_sequence: 1,
        // }
      }
      else {
        let obj = {
          angle: "back",
          // image: "https://api-designtool.optech.pk/api/customer/get-default-images",
          image: frontFileUploadImage,
          top: frontUploadTop,
          left: frontUploadLeft,
          rotation: frontUploadRotate,
          flip_x: frontUploadXAxis,
          flip_y: frontUploadYAxis,
          px_to_inch_width: frontUploadWPxIntoInches,
          px_to_inch_height: frontUploadHPxIntoInches,
          width: frontUploadCanvasWidth,
          height: frontUploadCanvasHeight,
          image_color: [{ "color": "#fff" }, { "color": "#000" }, { "color": "#eee" }],
          is_make_one_color: false,
          is_background_remove: true,
          layer_sequence: 1,
        }
        designUploadWork.push(obj);
      }
      let obj = {
        email: userEmail,
        designName: name,
        isDefaultProduct: 1, // *1 => YES / 0 => No
        productId: 0, // *0 => No Product Selected
        productColor: maskColor,
        designArtWork: JSON.stringify(designArtWork),
        designUploadWork: designUploadWork,
        designText: JSON.stringify(designText),
      };
      dispatch(setOrderSaveDetails(obj));
      try {
        let formData = new FormData();
        formData.append('email', userEmail);
        formData.append('designName', name);
        formData.append('isDefaultProduct', 1);
        formData.append('productId', 0);
        formData.append('productColor', maskColor);
        formData.append('designArtWork', JSON.stringify(designArtWork));
        formData.append('designText', JSON.stringify(designText));
        designUploadWork.forEach((element, index) => {
          formData.append(`designUploadWork[${index}][angle]`, element.angle);
          formData.append(`designUploadWork[${index}][image]`, element.image);
          formData.append(`designUploadWork[${index}][top]`, element.top);
          formData.append(`designUploadWork[${index}][left]`, element.left);
          formData.append(`designUploadWork[${index}][rotation]`, element.rotation);
          formData.append(`designUploadWork[${index}][flip_x]`, element.flip_x);
          formData.append(`designUploadWork[${index}][flip_y]`, element.flip_y);
          formData.append(`designUploadWork[${index}][px_to_inch_width]`, element.px_to_inch_width);
          formData.append(`designUploadWork[${index}][px_to_inch_height]`, element.px_to_inch_height);
          formData.append(`designUploadWork[${index}][width]`, element.width);
          formData.append(`designUploadWork[${index}][height]`, element.height);
          formData.append(`designUploadWork[${index}][image_color]`, element.image_color);
          formData.append(`designUploadWork[${index}][is_make_one_color]`, element.is_make_one_color);
          formData.append(`designUploadWork[${index}][is_background_remove]`, element.is_background_remove);
          formData.append(`designUploadWork[${index}][layer_sequence]`, element.layer_sequence);
        });
        const { status } = await Service.saveAndShareDesign(formData);
        if (status) {
          setIsSaveAndShareDialog(false);
          setLoadingStatus(false)
          setSaveDesignOpen(false)
          Swal.fire({
            title: 'Successful',
            text: 'Your design is successfully saved, Sharing Url is in progress',
            icon: 'success',
            // confirmButtonColor: colors.primary,
          })
          if (getOrderPage === false) {
            const addToCartValue = localStorage.getItem('NoOfDesign');
            localStorage.setItem('NoOfDesign', +addToCartValue + 1)
            let obj = {
              designName: name,
              designEmail: email,
              totalItem: totalItems,
              totalPrice: totalItems * eachItemPrice,
              adultSize: totalAdultSizes,
              youthSize: totalYouthSizes,
              eachItemPrice: eachItemPrice,
            }
            dispatch(setTotalAddToCart(obj));
            navigate('/cart');
          }
          // window.location.reload();
        }
      } catch (error) {
        ErrorToaster("Network Error")
        console.log(
          "file: design-tool.js => line 2682 => saveAndShareDesign => error",
          error
        );
      }
      finally {
        setLoadingStatus(false)
      }
    }
  };

  // *For Delete Upload Art
  const deleteUpload = (image) => {
    try {
      if (productAngle === "front") {
        setTimeout(() => {
          if (image === frontArtWork) {
            setFrontArtWork("");
          } else if (image === frontUploadWorkCanvas) {
            setFrontUploadWork("");
            setIsActive("");
            frontUploadWorkCanvas.current.height = "0";
            frontUploadWorkCanvas.current.width = "0";
            setFrontUploadCanvasWidth("0");
            setFrontUploadCanvasHeight("0");
            setFrontUploadLeft(0);
            setFrontUploadTop(0);
          } else if (image === frontTextCanvas) {
            setFrontTextInput("");
            setIsActive("");
            setValue(
              "text_value_size", (0).toFixed(2));
            frontTextCanvas.current.width = "";
            frontTextCanvas.current.height = "";
            frontTextWidth = "0";
            frontTextHeight = "0";

            setFrontTextLeft(0);
            setFrontTextTop(0);
          }
          changePanel("", "main");
        }, 100);
      } else {
        setTimeout(() => {
          if (image === backArtWork) {
            setBackArtWork("");
          } else if (image === backUploadWorkCanvas) {
            setBackUploadWork("");
            setIsActive("");
            backUploadWorkCanvas.current.height = "0";
            backUploadWorkCanvas.current.width = "0";
            setBackUploadCanvasWidth("0");
            setBackUploadCanvasHeight("0");
            setBackUploadLeft(0);
            setBackUploadTop(0);
          } else if (image === backTextCanvas) {
            setBackTextInput("");
            setIsActive("");
            backTextCanvas.current.width = "";
            backTextCanvas.current.height = "";
            backTextWidth = "0";
            backTextHeight = "0";
            setBackTextLeft(0);
            setBackTextTop(0);
          }
          changePanel("", "main");
        }, 100);
      }
    } catch (error) {
      console.log(
        "file: design-tool.js => line 1782 => deleteUpload => error",
        error
      );
    }
  };

  // *For Delete Art Work
  const deleteArt = (array_index) => {
    try {
      setTimeout(() => {
        frontArtWork.splice(array_index, 1);
        changePanel("", "main");
      }, 100);
    } catch (error) {
      console.log(
        "file: design-tool.js => line 2264 => deleteArt => error",
        error
      );
    }
  };

  const artAlignCenter = () => {
    try {
      let imgWidth = frontArtWork[artIndex].width;
      let center = (frontWidthPA - imgWidth) / 2;

      frontArtWork[artIndex].left = center;
    } catch (error) {
      console.log(
        "file: design-tool.js => line 2503 => getZoom => error",
        error
      );
    }
  };

  const textAlignCenter = () => {
    try {
      let center = (frontWidthPA - frontTextWidth) / 2;
      setFrontTextLeft(center);
    } catch (error) {
      console.log(
        "file: design-tool.js => line 2503 => getZoom => error",
        error
      );
    }
  };

  const uploadAlignCenter = () => {
    try {
      let center = (frontWidthPA - frontUploadCanvasWidth) / 2;
      setFrontUploadLeft(center);
    } catch (error) {
      console.log(
        "file: design-tool.js => line 2503 => getZoom => error",
        error
      );
    }
  };

  useEffect(() => {
    setBaseURL(imageURL());
    getArtWorkCategories();
    getDefaultImages();
    getColors();
    getFonts();
    disabledInspect();
    window.scrollTo({ top: 0 });

    const canvas = frontUploadWorkCanvas.current;
    ctxFrontImage = canvas.getContext("2d");
    ctxFrontImage.save();
    ctxBackImage = canvas.getContext("2d");
  }, []);

  function rotateValueText(value) {
    return `${value}`;
  }

  function rotateValueLabel(value) {
    return rotateValue.findIndex((rotateValue) => rotateValue.value === value)
      .label;
  }

  const [artIndex, setArtIndex] = useState(0);

  const getArtWorkPanel = (artIndex) => {
    try {
      console.log(
        "file: design-tool.js => line 2464 => getArtWorkPanel => artIndex",
        artIndex
      );
      setArtIndex(artIndex);
      setFrontArtInvertColor(frontArtWork[artIndex].invertColor);
      setFrontArtRotate(frontArtWork[artIndex].rotate);
      setFrontArtXAxis(frontArtWork[artIndex].flipX);
      setFrontArtYAxis(frontArtWork[artIndex].flipY);
      changePanel("", "addArt");
    } catch (error) {
      console.log(
        "file: design-tool.js => line 2467 => getArtWorkPanel => error",
        error
      );
    }
  };

  const getZoom = () => {
    try {
      setZoom((prev) => !prev);
    } catch (error) {
      console.log(
        "file: design-tool.js => line 2503 => getZoom => error",
        error
      );
    }
  };

  const [getOrderPage, setGetOrderPage] = useState(true)

  // *function to hide drawer
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [textSidePane, setTextSidePane] = useState(false)
  function handleResize() {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    if (windowWidth > 768) {
      setTextSidePane(false)
    }
    else {
      setTextSidePane(true)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [windowWidth])

  return (
    <Layout className={classes.removeExtraClasses}>
      <TextSlidingPane isPaneOpen={isPaneOpen} setIsPaneOpen={setIsPaneOpen} textPanel={textPanel} fontEditPanel={fontEditPanel} changePanel={changePanel} setTextValue={setTextValue} addToDesign={addToDesign} handleTextFontSize={handleTextFontSize}
        fontFamilyPanel={fontFamilyPanel} getTextEditPanel={getTextEditPanel} frontTextInput={frontTextInput} backTextInput={backTextInput} frontFontFamily={frontFontFamily} backFontFamily={backFontFamily} frontFontOutlineColor={frontFontOutlineColor}
        backFontOutlineColor={backFontOutlineColor} frontTextRotate={frontTextRotate} backTextRotate={backTextRotate} setTextRotateValue={setTextRotateValue} blurRotate={blurRotate} register={register} valueChangeOfText={valueChangeOfText} textAlignCenter={textAlignCenter}
        textLayer={textLayer} fonts={fonts} baseURL={baseURL} changeFontFamily={changeFontFamily} fontColorPanel={fontColorPanel} colorLoader={colorLoader} loaderColor={loaderColor}
        opacity={opacity} productAngle={productAngle} frontFontColor={frontFontColor} backFontColor={backFontColor} colors={colors} changeFontColor={changeFontColor} fontOutlinePanel={fontOutlinePanel} frontTextOutlineThickness={frontTextOutlineThickness}
        backTextOutlineThickness={backTextOutlineThickness} handleThickness={handleThickness} rotateValueLabel={rotateValueLabel} rotateValueText={rotateValueText} rotateValue={rotateValue} changeFontOutlineColor={changeFontOutlineColor} />

      <UploadImageSlidePane isUploadPaneOpen={isPaneOpen} setIsUploadPaneOpen={setIsUploadPaneOpen} panelLoader={panelLoader} changePanel={changePanel} loaderColor={loaderColor} uploadImg={uploadImg} getImageData={getImageData} uploadImgPanel={uploadImgPanel} uploadAlignCenter={uploadAlignCenter} frontUploadYAxis={frontUploadYAxis}
        flipUploadYAxis={flipUploadYAxis} productAngle={productAngle} backUploadYAxis={backUploadYAxis} frontUploadXAxis={frontUploadXAxis} flipUploadXAxis={flipUploadXAxis} backUploadXAxis={backUploadXAxis} frontUploadRotate={frontUploadRotate} backUploadRotate={backUploadRotate}
        setUploadRotateValue={setUploadRotateValue} blurRotate={blurRotate} isMakeOneColor={isMakeOneColor} handleMakeOneColor={handleMakeOneColor} backgroundRemoveStatus={backgroundRemoveStatus} handleRemoveBackground={handleRemoveBackground} getUploadImgPanel={getUploadImgPanel}
        selectedOneColor={selectedOneColor} makeOneColor={makeOneColor} renderSwatches={renderSwatches} frontIsUploadProportional={frontIsUploadProportional} uploadLockUnlockProportional={uploadLockUnlockProportional} backIsUploadProportional={backIsUploadProportional} changeImageWidthSizeValue={changeImageWidthSizeValue}
        frontUploadWPxIntoInches={frontUploadWPxIntoInches} backUploadWPxIntoInches={backUploadWPxIntoInches} frontUploadHPxIntoInches={frontUploadHPxIntoInches} backUploadHPxIntoInches={backUploadHPxIntoInches} reset={reset} uploadImgColor={uploadImgColor} colorLoader={colorLoader} opacity={opacity}
        renderImageColors={renderImageColors} selectColor={selectColor} artColorOptions={artColorOptions} rgb={rgb} changeFrontImageColor={changeFrontImageColor} />

      <div id="DesignTool">
        {/* ========== Global Font ========== */}
        <GlobalFonts
          fontName={productAngle === "front" ? frontFontFamily : backFontFamily}
          lowerCase={
            productAngle === "front" ? frontFontLowerCase : backFontLowerCase
          }
          fontType={productAngle === "front" ? frontFontType : backFontType}
        />

        {/* ========== Toaster ========== */}
        <Toaster />

        {/* ========== DesignTool ========== */}
        <div className="design-section">
          {/* ========== Tool Sidebar ========== */}
          {getOrderPage &&
            <div className="tool-sidebar">
              <TabContext value={panel} className={classes.removeExtraClasses}>
                {/* ========== Tool Tabs ========== */}
                <Tabs
                  orientation="vertical"
                  onChange={changePanel}
                  value={panel}
                  className="toolbar-tab"
                >
                  <Tab label="Main" className="d-none" value="main" />
                  <Tab
                    label="Add Text"
                    icon={<img src={Images.addText} alt="Add Text" />}
                    value="addText"
                    onClick={() => {
                      getTextEditPanel(true, false, false, false);
                      getFontEditPanel(true);
                    }}
                  />
                  <Tab
                    label="Add Art"
                    icon={<img src={Images.addArt} alt="Add Art" />}
                    value="addArt"
                    onClick={() => {
                      getArtWorkSubCatByCat("", "", "");
                      showSubCategory(true);
                    }}
                  />
                  <Tab
                    label="Upload"
                    icon={<img src={Images.upload} alt="Upload" />}
                    value="upload"
                    onClick={() => {
                      getUploadImg(true);
                      getUploadImgPanel(false, false, false);
                    }}
                  />
                  <Tab
                    label="Garment Color"
                    icon={<img src={Images.garmentColor} alt="Garment Color" />}
                    value="productColor"
                  />
                  {/* <Tab
                  label="Add Number"
                  icon={<img src={Images.addNumber} alt="Add Number" />}
                  value="addNumber"
                /> */}
                </Tabs>


                {/* ========== Main Panel ========== */}
                <MainPanel changePanel={changePanel} getTextEditPanel={getTextEditPanel} getFontEditPanel={getFontEditPanel}
                  showSubCategory={showSubCategory} getArtWorkSubCatByCat={getArtWorkSubCatByCat} getUploadImg={getUploadImg} getUploadImgPanel={getUploadImgPanel} />
                {/* ========== Add Text Panel ========== */}

                <AddTextTab textPanel={textPanel} fontEditPanel={fontEditPanel} changePanel={changePanel} setTextValue={setTextValue} addToDesign={addToDesign} handleTextFontSize={handleTextFontSize}
                  fontFamilyPanel={fontFamilyPanel} getTextEditPanel={getTextEditPanel} frontTextInput={frontTextInput} backTextInput={backTextInput} frontFontFamily={frontFontFamily} backFontFamily={backFontFamily} frontFontOutlineColor={frontFontOutlineColor}
                  backFontOutlineColor={backFontOutlineColor} frontTextRotate={frontTextRotate} backTextRotate={backTextRotate} setTextRotateValue={setTextRotateValue} blurRotate={blurRotate} register={register} valueChangeOfText={valueChangeOfText} textAlignCenter={textAlignCenter}
                  textLayer={textLayer} fonts={fonts} baseURL={baseURL} changeFontFamily={changeFontFamily} fontColorPanel={fontColorPanel} colorLoader={colorLoader} loaderColor={loaderColor}
                  opacity={opacity} productAngle={productAngle} frontFontColor={frontFontColor} backFontColor={backFontColor} colors={colors} changeFontColor={changeFontColor} fontOutlinePanel={fontOutlinePanel} frontTextOutlineThickness={frontTextOutlineThickness}
                  backTextOutlineThickness={backTextOutlineThickness} handleThickness={handleThickness} rotateValueLabel={rotateValueLabel} rotateValueText={rotateValueText} rotateValue={rotateValue} changeFontOutlineColor={changeFontOutlineColor}
                />

                {/* ========== Add Art Panel ========== */}

                <AddArtPanel panelLoader={panelLoader} changePanel={changePanel} loaderColor={loaderColor} showSubCategoriesPanel={showSubCategoriesPanel} categoriesPanel={categoriesPanel} categories={categories} baseURL={baseURL}
                  getArtWorkSubCatByCat={getArtWorkSubCatByCat} getArtWorkBySubCat={getArtWorkBySubCat} showSubCategory={showSubCategory} showArt={showArt} categoryPanelValue={categoryPanelValue} showArtWorkPanel={showArtWorkPanel} subCategoryPanelHeading={subCategoryPanelHeading}
                  subCategoriesPanel={subCategoriesPanel} subCategories={subCategories} getEditArt={getEditArt} showEditArt={showEditArt} subCategoryPanelValue={subCategoryPanelValue} showEditArtWorkPanel={showEditArtWorkPanel} artWorkPanelHeading={artWorkPanelHeading}
                  editArtPanel={editArtPanel} artWork={artWork} changeArtWork={changeArtWork} artAlignCenter={artAlignCenter} frontArtYAxis={frontArtYAxis} flipArtYAxis={flipArtYAxis} frontArtXAxis={frontArtXAxis} flipArtXAxis={flipArtXAxis} productAngle={productAngle} frontArtRotate={frontArtRotate}
                  backArtRotate={backArtRotate} setArtRotateValue={setArtRotateValue} blurRotate={blurRotate} frontArtInvertColor={frontArtInvertColor} handleInvertColor={handleInvertColor} setArtChangeStatus={setArtChangeStatus} frontIsArtProportional={frontIsArtProportional} artLockUnlockProportional={artLockUnlockProportional}
                  backIsArtProportional={backIsArtProportional} frontArtWork={frontArtWork} artIndex={artIndex} artReset={artReset}
                />

                {/* ========== Upload Image Panel ========== */}

                <UploadImagePanel panelLoader={panelLoader} changePanel={changePanel} loaderColor={loaderColor} uploadImg={uploadImg} getImageData={getImageData} uploadImgPanel={uploadImgPanel} uploadAlignCenter={uploadAlignCenter} frontUploadYAxis={frontUploadYAxis}
                  flipUploadYAxis={flipUploadYAxis} productAngle={productAngle} backUploadYAxis={backUploadYAxis} frontUploadXAxis={frontUploadXAxis} flipUploadXAxis={flipUploadXAxis} backUploadXAxis={backUploadXAxis} frontUploadRotate={frontUploadRotate} backUploadRotate={backUploadRotate}
                  setUploadRotateValue={setUploadRotateValue} blurRotate={blurRotate} isMakeOneColor={isMakeOneColor} handleMakeOneColor={handleMakeOneColor} backgroundRemoveStatus={backgroundRemoveStatus} handleRemoveBackground={handleRemoveBackground} getUploadImgPanel={getUploadImgPanel}
                  selectedOneColor={selectedOneColor} makeOneColor={makeOneColor} renderSwatches={renderSwatches} frontIsUploadProportional={frontIsUploadProportional} uploadLockUnlockProportional={uploadLockUnlockProportional} backIsUploadProportional={backIsUploadProportional} changeImageWidthSizeValue={changeImageWidthSizeValue}
                  frontUploadWPxIntoInches={frontUploadWPxIntoInches} backUploadWPxIntoInches={backUploadWPxIntoInches} frontUploadHPxIntoInches={frontUploadHPxIntoInches} backUploadHPxIntoInches={backUploadHPxIntoInches} reset={reset} uploadImgColor={uploadImgColor} colorLoader={colorLoader} opacity={opacity}
                  renderImageColors={renderImageColors} selectColor={selectColor} artColorOptions={artColorOptions} rgb={rgb} changeFrontImageColor={changeFrontImageColor}
                />

                {/* ========== Product Color Panel ========== */}
                <TabPanel className="color-panel" value="productColor">
                  <IconButton
                    aria-label="close"
                    className="close-btn"
                    onClick={() => changePanel("", "main")}
                  >
                    <Close />
                  </IconButton>

                  <Typography variant="body1" component="h1" align="center">
                    Garment color
                  </Typography>

                  <Typography variant="h5" component="span">
                    Selected color:
                  </Typography>

                  <Typography
                    className="selected-color-box"
                    style={{ backgroundColor: maskColor }}
                  ></Typography>

                  <Typography component="p">
                    Ordering fewer than 5 total items?
                  </Typography>

                  {colors.map((value, index) => (
                    <label className="color-box" key={index}>
                      <input type="radio" name="color" />
                      <span
                        className="checkmark"
                        style={{ backgroundColor: value.code }}
                        onClick={() => {
                          changeColor(value.code);
                        }}
                      ></span>
                    </label>
                  ))}
                </TabPanel>

                {/* ========== Add Number Panel ========== */}
                <TabPanel className="number-panel" value="addNumber">
                  <IconButton
                    aria-label="close"
                    className="close-btn"
                    onClick={() => changePanel("", "main")}
                  >
                    <Close />
                  </IconButton>

                  <Typography variant="body1" component="h1" align="center">
                    Names and Numbers
                  </Typography>

                  <CardMedia
                    component="img"
                    alt="Add Name"
                    height="250"
                    image={Images.addName}
                  />
                  <Typography variant="body2" component="h6">
                    Lorem ipsum dolor sit amen consenter adipisicing elite.
                    Perspiciatis, Nat's rerun. Cumquat dolores conservator amer?
                  </Typography>
                  <Button variant="contained" size="medium">
                    Add Names and Number
                  </Button>
                </TabPanel>
              </TabContext>
            </div>}

          {/* ========== Canvas Image ========== */}
          <div className="canvas-image-wrapper">
            {/* ========== Image Loader ========== */}
            {imageLoader && (
              <div>
                <div className="sweet-loading">
                  <PropagateLoader color={loaderColor} loading={true} size={15} />
                </div>
              </div>
            )}
            {productAngle === "front" && (
              <Fragment>
                <div
                  className="front-canvas"
                  style={{ transform: zoom ? "scale(1.8)" : "" }}
                >
                  {frontImage !== null && (
                    <CardMedia
                      id="canvas-image"
                      component="img"
                      alt="Front Design"
                      image={frontImage}
                      style={{
                        backgroundColor: maskColor,
                      }}
                    />
                  )}
                </div>

                <div
                  className="print-area-wrapper"
                  onClick={() => {
                    setIsActive("");
                    changePanel("", "main");
                  }}
                  style={{ transform: zoom ? "scale(1.8)" : "" }}
                >
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setIsActive("");
                      changePanel("", "main");
                    }}
                    className="print-area"
                    id="front-print-area"
                    ref={printArea}
                    style={{
                      top: frontTopOffsetPA + "px",
                      left: frontLeftOffsetPA + "px",
                      width: frontWidthPA + "px",
                      height: frontHeightPA + "px",
                    }}
                  >
                    {frontTextCanvas !== "" && (
                      <Fragment>
                        {/* Text Resize & Drag Section */}
                        <Rnd
                          className="print-box"
                          id="front-text-box"
                          onClick={(e) => {
                            // 
                            e.stopPropagation();
                            textSidePane && setIsPaneOpen(true);
                            changePanel("", "addText");
                            setIsActive(frontTextCanvas);
                          }}
                          style={{
                            width: frontTextWidth,
                            height: frontTextHeight,
                            // zIndex: frontTextLayer,
                          }}
                          enableResizing={{
                            topLeft: false,
                            topRight: false,
                            bottomLeft: false,
                            bottomRight: true,
                          }}
                          lockAspectRatio={false}
                          width={frontTextWidth}
                          height={frontTextHeight}
                          onResizeStop={(e, data) => {
                            setCanvasTextPosition(e, data);
                            handleTextFontSize();
                          }}
                          default={{
                            x: frontTextLeft,
                            y: frontTextTop,
                            width: { frontTextWidth },
                            height: { frontTextHeight },
                          }}
                          minWidth={+frontTextWidth}
                          minHeight={+frontTextHeight}
                          onResize={(e, direction, ref, delta, position) => {
                            setTextSize(delta.width, delta.height);
                            setFontSizeWidth(delta.width); // *set add art div width on resize
                            setFontSizeHeight(delta.height); // *set add art div height on resize
                          }}
                          bounds="parent"
                        >
                          <div
                            className={`flip-rotate ${isActive === frontTextCanvas ? "imageActive" : ""
                              }`}
                            style={{
                              transform: `rotate(${frontTextRotate}deg)`,
                              position: "relative",
                            }}
                          >
                            <canvas
                              ref={frontTextCanvas}
                              width={frontTextWidth}
                              height={frontTextHeight}
                            // style={{ width: "100%", height: "100%" }}
                            ></canvas>
                            {isActive === frontTextCanvas && (
                              <Fragment>
                                <IconButton
                                  aria-label="delete"
                                  className="delete-btn"
                                  size="small"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    deleteUpload(frontTextCanvas);
                                  }}
                                >
                                  <Close />
                                </IconButton>
                                <Height
                                  style={{
                                    borderRadius: "50%",
                                    p: 0.5,
                                    color: "#ee3524",
                                    backgroundColor: "white",
                                    fontSize: "22px",
                                    position: "absolute",
                                    bottom: -15,
                                    right: -15,
                                    transform: "rotate(135deg)",
                                  }}
                                />
                              </Fragment>
                            )}
                          </div>
                        </Rnd>
                      </Fragment>
                    )}

                    {frontArtWork.map((artWork, index) => (
                      <Draggable
                        key={index}
                        position={{ x: artWork.left, y: artWork.top }}
                        onStop={(e, data) =>
                          getArtCanvasResizeWidth(e, data, index)
                        }
                        cancel={".react-resizable-handle"}
                        bounds="parent"
                      >
                        <div
                          className="print-box"
                          id="front-art-box"
                          onClick={(e) => {
                            e.stopPropagation();
                            getArtWorkPanel(index);
                            setIsActive(artWork.id);
                          }}
                          style={{ width: artWork.width, height: artWork.height }}
                        >
                          <Resizable
                            width={+artWork.width}
                            height={+artWork.height}
                            axis={"both"}
                            lockAspectRatio={frontArtLockAspectRatio}
                            minConstraints={[20, 20]}
                            maxConstraints={[
                              frontWidthPA - frontArtCanvasResizeMaxWidth,
                              frontArtLockAspectRatio
                                ? frontWidthPA - frontArtCanvasResizeMaxWidth
                                : frontHeightPA - frontArtCanvasResizeMaxHeight,
                            ]} // *if frontArtLockAspectRatio is true W - W If frontArtLockAspectRatio is true H - H
                            resizeHandles={[
                              isActive === artWork.id ? "se" : "se disabled",
                            ]}
                            onResize={(e, data) => {
                              setArtInches(
                                index,
                                data.size.width,
                                data.size.height
                              );
                              getArtSize(index, data.size);
                              // setFrontArtCanvasWidth(data.size.width); // *set add art div width on resize
                              // setFrontArtCanvasHeight(data.size.height);// *set add art div height on resize
                            }}
                          >
                            <div
                              className={`flip-rotate ${isActive === artWork.id ? "imageActive" : ""
                                }`}
                              style={{
                                transform: `scale(${artWork.flipX}, ${artWork.flipY}) rotate(${artWork.rotate}deg)`,
                              }}
                            >
                              <img
                                src={artWork.url}
                                alt="Art"
                                className={`${artWork.invertColorClass}`}
                              />
                              {isActive === artWork.id && (
                                <IconButton
                                  aria-label="delete"
                                  className="delete-btn"
                                  size="small"
                                  onClick={() => {
                                    deleteArt(index);
                                  }}
                                >
                                  <Close />
                                </IconButton>
                              )}
                            </div>
                          </Resizable>
                        </div>
                      </Draggable>
                    ))}

                    {frontUploadWorkCanvas !== "" && (
                      <Draggable
                        position={{ x: frontUploadLeft, y: frontUploadTop }}
                        onStop={(e, data) => getUploadCanvasResizeWidth(e, data)}
                        cancel={".react-resizable-handle"}
                        bounds="parent"
                        scale={2}
                      >
                        <div
                          className="print-box"
                          id="front-upload-box"
                          onClick={(e) => {
                            e.stopPropagation();
                            textSidePane && setIsUploadPaneOpen(true);
                            changePanel("", "upload");
                            getUploadImgPanel(true, false, false);
                            setUploadImg(false);
                            setIsActive(frontUploadWorkCanvas);
                          }}
                          style={{
                            width: frontUploadCanvasWidth,
                            height: frontUploadCanvasHeight
                          }}
                        >
                          <Resizable
                            width={+frontUploadCanvasWidth}
                            height={+frontUploadCanvasHeight}
                            axis={"both"}
                            lockAspectRatio={frontUploadLockAspectRatio}
                            minConstraints={[20, 20]}
                            maxConstraints={[
                              frontWidthPA - frontUploadCanvasResizeMaxWidth,
                              frontUploadLockAspectRatio
                                ? frontWidthPA - frontUploadCanvasResizeMaxWidth
                                : frontHeightPA -
                                frontUploadCanvasResizeMaxHeight,
                            ]} // *if frontUploadLockAspectRatio is true W - W If frontUploadLockAspectRatio is true H - H
                            resizeHandles={[
                              isActive === frontUploadWorkCanvas
                                ? "se"
                                : "se disabled",
                            ]}
                            onResize={(e, data) => {
                              console.log(
                                "🚀 ~ file: design-tool.js ~ line 4033 ~ DesignTool ~ data",
                                data
                              );
                              setUploadInches(data.size.width, data.size.height);
                              getUploadSize(data.size);
                            }}
                          >
                            <div
                              className={`flip-rotate ${isActive === frontUploadWorkCanvas
                                ? "imageActive"
                                : ""
                                }`}
                              style={{
                                objectFit: 'contain',
                                transform: `scale(${frontUploadXAxis}, ${frontUploadYAxis}) rotate(${frontUploadRotate}deg)`,
                              }}
                            >
                              <canvas
                                ref={frontUploadWorkCanvas}
                                width={+frontUploadCanvasWidth}
                                height={+frontUploadCanvasHeight}

                              />
                              {isActive === frontUploadWorkCanvas && (
                                <IconButton
                                  aria-label="delete"
                                  className="delete-btn"
                                  size="small"
                                  onClick={() => {
                                    deleteUpload(frontUploadWorkCanvas);
                                  }}
                                >
                                  <Close />
                                </IconButton>
                              )}
                            </div>
                          </Resizable>
                        </div>
                      </Draggable>
                    )}
                  </div>
                </div>
              </Fragment>
            )}
            {productAngle === "back" && (
              <Fragment>
                <div className="back-canvas">
                  {backImage !== null && (
                    <CardMedia
                      id="canvas-image"
                      component="img"
                      alt="Front Design"
                      image={backImage}
                      style={{
                        backgroundColor: maskColor,
                      }}
                    />
                  )}
                </div>
                <div
                  className="print-area-wrapper"
                  onClick={() => {
                    changePanel("", "main");
                  }}
                >
                  <div
                    className="print-area"
                    id="back-print-area"
                    ref={printArea}
                    style={{
                      top: backTopOffsetPA + "px",
                      left: backLeftOffsetPA + "px",
                      width: backWidthPA + "px",
                      height: backHeightPA + "px",
                    }}
                  >
                    {backText && (
                      <Draggable
                        defaultPosition={{ x: backTextLeft, y: backTextTop }}
                        onStop={(e, data) => setCanvasTextPosition(e, data)}
                        cancel={".react-resizable-handle"}
                        bounds="parent"
                      >
                        <div
                          className="print-box"
                          id="back-text-box"
                          onClick={() => {
                            changePanel("", "addText");
                          }}
                          style={{
                            width: backTextWidth,
                            height: backTextHeight,
                            zIndex: backTextLayer,
                          }}
                        >
                          <div
                            className="flip-rotate"
                            style={{ transform: `rotate(${backTextRotate}deg)` }}
                          >
                            <canvas
                              ref={backTextCanvas}
                              width={backTextWidth}
                              height={backTextHeight}
                            ></canvas>
                          </div>
                        </div>
                      </Draggable>
                    )}

                    {backArtWork !== "" && (
                      <Draggable
                        defaultPosition={{ x: backArtLeft, y: backArtTop }}
                        onStop={(e, data) => getArtCanvasResizeWidth(e, data)}
                        cancel={".react-resizable-handle"}
                        bounds="parent"
                      >
                        <Resizable
                          width={+backArtCanvasWidth}
                          height={+backArtCanvasHeight}
                          axis={"both"}
                          lockAspectRatio={backArtLockAspectRatio}
                          minConstraints={[20, 20]}
                          maxConstraints={[
                            backWidthPA - backArtCanvasResizeMaxWidth,
                            backArtLockAspectRatio
                              ? backWidthPA - backArtCanvasResizeMaxWidth
                              : backHeightPA - backArtCanvasResizeMaxHeight,
                          ]} // *if backArtLockAspectRatio is true W - W If backArtLockAspectRatio is true H - H
                          resizeHandles={["se"]}
                          onResize={(e, data) => {
                            setArtInches(data.size.width, data.size.height);
                            setBackArtCanvasWidth(data.size.width); // *set add art div width on resize
                            setBackArtCanvasHeight(data.size.height); // *set add art div height on resize
                          }}
                        >
                          <div
                            className="print-box"
                            id="back-art-box"
                            onClick={() => {
                              changePanel("", "addArt");
                            }}
                            style={{
                              width: backArtCanvasWidth,
                              height: backArtCanvasHeight,
                            }}
                          >
                            <div
                              className={`flip-rotate ${backInvertColorClass}`}
                              style={{
                                transform: `scale(${backArtXAxis}, ${backArtYAxis}) rotate(${backArtRotate}deg)`,
                              }}
                            >
                              <img src={backArtWork} alt="" />
                            </div>
                          </div>
                        </Resizable>
                      </Draggable>
                    )}

                    {backUploadWorkCanvas !== "" && (
                      <Draggable
                        position={{ x: backUploadLeft, y: backUploadTop }}
                        onStop={(e, data) => getUploadCanvasResizeWidth(e, data)}
                        cancel={".react-resizable-handle"}
                        bounds="parent"
                      >
                        <Resizable
                          width={+backUploadCanvasWidth}
                          height={+backUploadCanvasHeight}
                          axis={"both"}
                          lockAspectRatio={backUploadLockAspectRatio}
                          minConstraints={[20, 20]}
                          maxConstraints={[
                            backWidthPA - backUploadCanvasResizeMaxWidth,
                            backUploadLockAspectRatio
                              ? backWidthPA - backUploadCanvasResizeMaxWidth
                              : backHeightPA - backUploadCanvasResizeMaxHeight,
                          ]} // *if backUploadLockAspectRatio is true W - W If backUploadLockAspectRatio is true H - H
                          resizeHandles={[
                            isActive === backUploadWorkCanvas
                              ? "se"
                              : "se disabled",
                          ]}
                          onResize={(e, data) => {
                            setUploadInches(data.size.width, data.size.height);
                            getUploadSize(data.size);
                          }}
                        >
                          <div
                            className={`print-box ${isActive === backUploadWorkCanvas
                              ? "imageActive"
                              : ""
                              }`}
                            id="back-upload-box"
                            onClick={() => {
                              changePanel("", "upload");
                              setUploadImg(false);
                              setUploadImgPanel(true);
                              setIsActive(backUploadWorkCanvas);
                            }}
                            style={{
                              width: backUploadCanvasWidth,
                              height: backUploadCanvasHeight,
                            }}
                          >
                            <div
                              className="flip-rotate"
                              style={{
                                transform: `scale(${backUploadXAxis}, ${backUploadYAxis}) rotate(${backUploadRotate}deg)`,
                              }}
                            >
                              {/* <img src={backUploadWork} alt="" /> */}
                              <canvas
                                ref={backUploadWorkCanvas}
                                width={backUploadCanvasWidth}
                                height={backUploadCanvasHeight}
                              />
                            </div>
                            {isActive === backUploadWorkCanvas && (
                              <IconButton
                                aria-label="delete"
                                className="delete-btn"
                                size="small"
                                onClick={() => {
                                  deleteUpload(backUploadWorkCanvas);
                                }}
                              >
                                <Close />
                              </IconButton>
                            )}
                          </div>
                        </Resizable>
                      </Draggable>
                    )}
                  </div>
                </div>
              </Fragment>
            )}
          </div>

          {/* ========== Front Back Thumbnail Button ========== */}
          {getOrderPage &&
            <div className="front-back-thumbnail-wrapper">
              {/* ========== Front Button ========== */}
              {frontImage !== null && (
                <Button
                  variant="contained"
                  className="thumbnail"
                  onClick={() => {
                    getProductAngle("front");
                    changePanel("", "main");
                  }}
                >
                  <CardMedia
                    component="img"
                    alt="Front Design"
                    height="auto"
                    image={frontImage}
                    style={{
                      backgroundColor: maskColor,
                    }}
                  />
                  <Typography variant="body2">Front</Typography>
                </Button>
              )}

              {/* ========== Back Button ========== */}

              {backImage !== null && (
                <Button
                  variant="contained"
                  className="thumbnail"
                  onClick={() => {
                    getProductAngle("back");
                    changePanel("", "main");
                  }}
                >
                  <CardMedia
                    component="img"
                    alt="Back Design"
                    height="auto"
                    image={backImage}
                    style={{
                      backgroundColor: maskColor,
                    }}
                  />

                  <Typography variant="body2">Back</Typography>
                </Button>
              )}
              {/* ========== Zoom Button ========== */}
              <Button
                variant="contained"
                className="zoom-thumbnail"
                onClick={() => {
                  getZoom();
                }}
              >
                {zoom ? <ZoomOut /> : <ZoomIn />}
              </Button>
            </div>}
          {/* ========== Undo Redo Thumbnail Button ========== */}
          {getOrderPage &&
            <div className="undo-redo-thumbnail-wrapper">
              {/* ========== Undo Button ========== */}

              <Button variant="contained" className="thumbnail">
                <Undo />
                <Typography variant="body2">Undo</Typography>
              </Button>

              {/* ========== Redo Button ========== */}

              <Button variant="contained" className="thumbnail">
                <Redo />
                <Typography variant="body2">Redo</Typography>
              </Button>
            </div>}
          {/* Order Page */}
          {getOrderPage === false &&
            <Box sx={{ width: "45%", overflowY: 'scroll' }}>
              <OrderSection setGetOrderPage={() => { setGetOrderPage(true) }} saveAndShareDesign={saveAndShareDesign} loadingStatus={loadingStatus} saveDesignOpen={saveDesignOpen} setSaveDesignOpen={setSaveDesignOpen} isSaveAndShareDialog={isSaveAndShareDialog} open={open} setOpen={setOpen} />
            </Box>
          }
        </div>
        {/* Mobile Responsive Section */}
        {getOrderPage &&
          <MobileViewTemplate colors={colors} maskColor={maskColor} changeColor={changeColor} addToDesign={addToDesign} handleTextFontSize={handleTextFontSize} setTextValue={setTextValue}
            getImageData={getImageData} panelLoader={panelLoader} changePanel={changePanel} loaderColor={loaderColor} uploadImg={uploadImg} uploadImgPanel={uploadImgPanel} uploadAlignCenter={uploadAlignCenter} frontUploadYAxis={frontUploadYAxis}
            flipUploadYAxis={flipUploadYAxis} productAngle={productAngle} backUploadYAxis={backUploadYAxis} frontUploadXAxis={frontUploadXAxis} flipUploadXAxis={flipUploadXAxis} backUploadXAxis={backUploadXAxis} frontUploadRotate={frontUploadRotate} backUploadRotate={backUploadRotate}
            setUploadRotateValue={setUploadRotateValue} blurRotate={blurRotate} isMakeOneColor={isMakeOneColor} handleMakeOneColor={handleMakeOneColor} backgroundRemoveStatus={backgroundRemoveStatus} handleRemoveBackground={handleRemoveBackground} getUploadImgPanel={getUploadImgPanel}
            selectedOneColor={selectedOneColor} makeOneColor={makeOneColor} renderSwatches={renderSwatches} frontIsUploadProportional={frontIsUploadProportional} uploadLockUnlockProportional={uploadLockUnlockProportional} backIsUploadProportional={backIsUploadProportional} changeImageWidthSizeValue={changeImageWidthSizeValue}
            frontUploadWPxIntoInches={frontUploadWPxIntoInches} backUploadWPxIntoInches={backUploadWPxIntoInches} frontUploadHPxIntoInches={frontUploadHPxIntoInches} backUploadHPxIntoInches={backUploadHPxIntoInches} reset={reset} uploadImgColor={uploadImgColor} colorLoader={colorLoader} opacity={opacity}
            renderImageColors={renderImageColors} selectColor={selectColor} artColorOptions={artColorOptions} rgb={rgb} changeFrontImageColor={changeFrontImageColor}
          />
        }
        {/* Mobile Responsive Section End*/}

        {/* ========== Product Detail ========== */}
        {getOrderPage === true ?
          (<Grid className="product-tray" container spacing={2} justifyContent={'center'} >
            <Grid item xs={6} md={6} >
              {saveDesignOpen ? (
                <Box sx={{ textAlign: "end" }}>
                  <Button
                    variant="outlined"
                    className="button get-price"
                    onClick={(e) => saveAndShareDesignDialog()}
                  // onClick={() => { setOpen(true) }}
                  >
                    Save Design
                  </Button>
                </Box>
              ) : (
                <Box sx={{ textAlign: "end" }}>
                  <Button
                    variant="outlined"
                    className="button get-price"
                    // onClick={(e) => saveAndShareDesignDialog()}
                    onClick={() => {
                      if (!user) {
                        setOpen(true);
                      }
                      else {
                        saveAndShareDesignDialog()
                      }
                    }
                    }
                  >
                    Save Design
                  </Button>
                </Box>
              )}
            </Grid>
            <Grid item xs={5} md={6}>
              <Button
                // disabled={frontTextCanvas === "" && frontArtWork.length === 0 && frontUploadWorkCanvas === ""}
                sx={{ ml: 2 }}
                variant="outlined"
                className="button get-price"
                onClick={() => { setGetOrderPage(false) }}
              >
                Get Price
              </Button>
            </Grid>
          </Grid>) : ""}

        {/* ================ Save&Share Dialog ================ */}
        {
          !user ? (
            <LoginDialog open={open} setOpen={setOpen} saveAndShareDesignDialog={saveAndShareDesignDialog} />
          ) : (
            <SaveAndShare
              isSaveAndShareDialog={isSaveAndShareDialog}
              saveAndShareDesignDialog={saveAndShareDesignDialog}
              saveAndShareDialogFormRef={saveAndShareDialogFormRef}
              saveAndShareDesign={saveAndShareDesign}
              loadingStatus={loadingStatus}
            />
          )
        }
        {isSaveAndShareDialog &&
          <SaveAndShare
            isSaveAndShareDialog={isSaveAndShareDialog}
            saveAndShareDesignDialog={saveAndShareDesignDialog}
            saveAndShareDialogFormRef={saveAndShareDialogFormRef}
            saveAndShareDesign={saveAndShareDesign}
            addToCartStatus={true}
            loadingStatus={loadingStatus}
          />}

      </div >
    </Layout >
  );
}

export default DesignTool;