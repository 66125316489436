import { Apis, get, post } from './';

export const Service = {
    // *Auth
    login: async (obj) => {
        let result = await post(Apis.login, obj);
        if (result.status === 200) return result.data;
        else throw result;
    },
    logout: async (obj) => {
        let result = await post(Apis.logout, obj);
        if (result.status === 200) return result.data;
        else throw result;
    },
    register: async (obj) => {
        let result = await post(Apis.register, obj);
        if (result.status === 200) return result.data;
        else throw result;
    },

    getProduct: async () => {
        let result = await get(`${Apis.product}`);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getProductCategory: async () => {
        let result = await get(`${Apis.productCategory}`);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getProductCatWiseSubCat: async (catID) => {
        let result = await get(`${Apis.productCatWiseSubCat}`);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getProductSubCatByCatID: async (catID) => {
        let result = await get(`${Apis.productSubCatByCatID}/${catID}`);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getProductsBySubCatID: async (subCatID) => {
        let result = await get(`${Apis.productBySubCatID}/${subCatID}`);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getArtWorkCategory: async () => {
        let result = await get(Apis.artworkCategory);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getArtWorkSubCatByCatID: async (catID) => {
        let result = await get(`${Apis.artworkSubCatByCatID}/${catID}`);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getArtWorkBySubCatID: async (subCatID) => {
        let result = await get(`${Apis.artworkBySubCatID}/${subCatID}`);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getUploadArtURL: async (data) => {
        const config = {
            headers: { 'content-type': 'multipart/form-data', 'Access-Control-Allow-Origin': '*' }
        }
        let result = await post(Apis.uploadArtWork, data, config);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getImageColorChangeURL: async (obj) => {
        // const config = {
        //     headers: { 'content-type': 'multipart/form-data', 'Access-Control-Allow-Origin': '*' }
        // }
        let result = await post(Apis.changeImageColor, obj);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getMakeOneColorImageURL: async (obj) => {
        // const config = {
        //     headers: { 'content-type': 'multipart/form-data', 'Access-Control-Allow-Origin': '*' }
        // }
        let result = await post(Apis.makeOneColor, obj);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getDefaultImage: async () => {
        let result = await get(`${Apis.defaultToolImage}`);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getColors: async () => {
        let result = await get(`${Apis.colors}`);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getBrands: async () => {
        let result = await get(`${Apis.brand}`);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getFonts: async () => {
        let result = await get(`${Apis.fonts}`);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getProfilePersonalInfo: async () => {
        let result = await get(`${Apis.profilePersonalInfo}`);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getOrderDetails: async () => {
        let result = await get(`${Apis.getOrderDetails}`);
        if (result.status === 200) return result.data;
        else throw result;
    },
    saveAndShareDesign: async (obj) => {
        let result = await post(Apis.saveDesign, obj);
        if (result.status === 200) return result.data;
        else throw result;
    },
    orderDesignDetails: async (obj) => {
        let result = await post(Apis.orderDesignDetails, obj);
        if (result.status === 200) return result.data;
        else throw result;
    },
    updateProfilePersonalInfo: async (obj) => {
        let result = await post(Apis.updateProfilePersonalInfo, obj);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getOrderDetailsInvoice: async (id) => {
        let result = await get(`${Apis.getOrderDetailsInvoice}/${id}`);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getProductDetailsByProdID: async (id) => {
        let result = await get(`${Apis.getProductDetailsByProdID}/${id}`);
        if (result.status === 200) return result.data;
        else throw result;
    },
    getProductFilters: async (param) => {
        let result = await get(`${Apis.getProductFilters}${param}`);
        if (result.status === 200) return result.data;
        else throw result;
    },
}