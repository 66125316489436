import React, { useState, useEffect } from 'react'
import ProductsTypes from './Shared/ProductsTypes';
import { Box, Typography, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Service } from '../../config/service';
import colors from '../../styles/colors';
import Layout from '../../layout/Layout/Layout';
import { PrimaryButton } from './../../components/UI/Buttons';
import { FilterList } from '@mui/icons-material';
import Lottie from 'react-lottie';
import animationData from '../../assets/images/loader';


const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

function Products() {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);

  const [filterSection, setFilterSection] = useState(false)
  const handleFilterDrop = () => {
    setFilterSection(!filterSection)
  }
  // For Api Call
  const [productDetails, setProductDetails] = useState();
  console.log("🚀 ~ file: Apparel.js ~ line 14 ~ Apparel ~ productDetails", productDetails)

  //* Get Product Details API
  const getProductCategory = async () => {
    try {
      const { data } = await Service.getProductCategory()
      console.log("🚀 ~ file: Products.js ~ line 23 ~ getProductCategory ~ data", data)
      setProductDetails(data);
      setLoader(false);
    } catch (error) {
      console.log("🚀 ~ file: Products.js ~ line 26 ~ getProductCategory ~ error", error)
    }
  }


  useEffect(() => {
    getProductCategory();
  }, [])

  return (
    <Layout>

      <Box sx={{ textAlign: "center", mt: 6 }}>
        <Typography sx={{ fontSize: "28px", fontWeight: "bold", color: colors.secondary }}>Custom Clothing & Apparel</Typography>
        <Typography sx={{ fontSize: "14px", color: colors.textSecondary }}>Personalize your gear with free & fast shipping</Typography>
      </Box>
      <Box sx={{ display: "flex", my: 2, p: 3, boxSizing: "border-box", flexDirection: { xs: 'column', md: 'row' } }}>
        <Box sx={{ display: { xs: 'block', md: 'none' }, textAlign: 'center' }}>
          <PrimaryButton onClick={() => handleFilterDrop()} startIcon={<FilterList />}>Filter</PrimaryButton>
        </Box>
        <Box sx={{ minWidth: { xs: '100%', md: 320 }, display: { xs: filterSection ? 'block' : 'none', md: 'block' }, transition: '.8s all ease-in' }}>
          <ProductsTypes />
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            {loader ? (
              <Grid item xs={12} md={12} sx={{ height: "500px", justifyContent: 'center' }}>
                <Lottie
                  options={defaultOptions}
                  height={300}
                  width={300}
                />
              </Grid>) : (
              <>
                {/* Products */}
                {productDetails?.map((item, index) => (
                  <Grid key={index} item xs={12} md={4}>
                    <Box onClick={() => { navigate(`/category/${item.id}`) }} sx={{
                      textAlign: "center", boxShadow: ` rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;`, borderRadius: "6px", cursor: "pointer",
                      transition: "0.3s all ease", '&:hover': { boxShadow: `rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;` }, backgroundColor: colors.white,
                    }}>
                      <Typography sx={{ color: colors.black, pt: 3, fontWeight: 500, }}>{item.name}</Typography>
                      <img src={item.image} width="242.66px" height="272.98px" alt={item.name} />
                    </Box>
                  </Grid>
                ))} </>)}
          </Grid>
        </Box>
      </Box>
    </Layout>
  )
}

export default Products