import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { StarRate } from '@mui/icons-material'
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Container, Divider, Grid, Typography } from '@mui/material';
// *Imported Components
import Images from '../../assets/Images'
import ProductSlider from './Shared/ProductSlider'
import Layout from '../../layout/Layout/Layout'
import colors from '../../styles/colors'
import ColorBox from '../../components/UI/ColorBox'
import { selectStartDesigningDetails, setProductType } from '../../features/counter/counterSlice';
import { Service } from './../../config/service';
import AddToCart from './../../components/Dialogue/AddToCart/AddToCart';


// const features = ["4 oz., 100% moisture-wicking polyester", "Odor resistant", "Stain resistant",
//   "Snag resistant", "Women's complement is A4 Women's Promotional Performance Shirt",
// ]

// const juniorSizes = ['YS', 'YM', 'YL', 'YXL',]
// const adultSizes = ['S', 'M', 'L', 'XL', '2XL', '3XL',]

const brandSVG = [
  Images.Brand1, Images.Brand2, Images.Brand3, Images.Brand4, Images.Brand5, Images.Brand6, Images.Brand7, Images.Brand8,
  Images.Brand9, Images.Brand10, Images.Brand11, Images.Brand12, Images.Brand3, Images.Brand14, Images.Brand15,
]
function StartDesigning() {
  const dispatch = useDispatch();
  const productDetailsToShow = useSelector(selectStartDesigningDetails);
  const value = useParams();
  const [openAddToCartDialog, setOpenAddToCartDialog] = useState(false);

  // For Api Call
  const [productDetails, setProductDetails] = useState('');
  console.log("🚀 ~ file: StartDesigning.js ~ line 34 ~ StartDesigning ~ productDetails", productDetails)

  //* Get Order Details API
  const getOrderDetails = async () => {
    try {
      const { data } = await Service.getProductDetailsByProdID(value.prod_id)
      console.log("🚀 ~ file: StartDesigning.js ~ line 40 ~ getProductDetailsByProdID ~ data", data)
      setProductDetails(data[0])
    } catch (error) {
      console.log("🚀 ~ file: StartDesigning.js ~ line 43 ~ getProductDetailsByProdID ~ error", error)

    }
  }

  useEffect(() => {
    getOrderDetails()
  }, [])

  return (
    <Layout>
      <AddToCart openAddToCartDialog={openAddToCartDialog} setOpenAddToCartDialog={setOpenAddToCartDialog} colorName={productDetailsToShow?.product_colors} prod_id={value.prod_id} prod_type={productDetails?.prod_type} />
      <Container>
        <Grid item xs={12} md={12} container sx={{ p: 3, my: 4, boxShadow: `rgba(0, 0, 0, 0.16) 0px 1px 4px;` }}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" sx={{ fontWeight: "bold", color: colors.black }}>{productDetailsToShow?.name}</Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {[...Array(Number(5))].map((item, index) => (
                <StarRate sx={{ color: "#f9a60f" }} />
              ))}
              <Typography sx={{ mx: 1, color: colors.darkGray }}>(2,090)</Typography>
            </Box>
            <Typography sx={{ color: colors.black, fontSize: "18px", my: 1 }}>{productDetailsToShow?.prod_description}</Typography>
            {productDetails?.prod_type === "Customizable" ? (
              <Button variant='contained' onClick={() => dispatch(setProductType("Customizable"))} component={Link} to="/design-tool" sx={{
                my: 1.5, width: "100%", fontWeight: "bold", fontSize: "16px", backgroundColor: colors.primary,
                "&:hover": {
                  backgroundColor: colors.primary,
                }
              }} >Start Designing</Button>
            ) : (
              <Button variant='contained' onClick={() => { dispatch(setProductType("NotCustomizable")); setOpenAddToCartDialog(true) }} sx={{
                my: 1.5, width: "100%", fontWeight: "bold", fontSize: "16px", backgroundColor: colors.primary,
                "&:hover": {
                  backgroundColor: colors.primary,
                }
              }} >Add to Cart</Button>
            )}
            <Box>
              <Typography sx={{ fontSize: "20px", color: colors.black, fontWeight: "bold", mt: 2 }}>Available Colors:</Typography>
              <ColorBox colorName={productDetailsToShow?.product_colors} />
            </Box>
            <Box>
              <Typography sx={{ fontSize: "20px", color: colors.black, fontWeight: "bold" }}>Sizes Available in:</Typography>
              {/* Youth Size */}
              {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                  {juniorSizes.map((item, index) => (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography key={index} sx={{ fontSize: "16px", color: colors.black }}>{item}</Typography>
                      <Divider orientation='vertical' sx={{
                        color: colors.darkGray, height: "24px", mx: 1.5
                      }} />
                    </Box>
                  ))}
                </Box> */}
              {/* Adult Size */}
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {productDetailsToShow?.product_sizes.map((item, index) => (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography key={index} sx={{ fontSize: "16px", color: colors.black }}>{item.size_name}</Typography>
                    <Divider orientation='vertical' sx={{
                      color: colors.darkGray, height: "24px", mx: 1.5
                    }} />
                  </Box>
                ))}
              </Box>
            </Box>
            {/* <Box>
                <Typography sx={{ fontSize: "20px", color: colors.black, fontWeight: "bold" }}>Fit & Sizing:</Typography>
                <Typography sx={{ fontSize: "18px", color: colors.black }}>Relaxed fit: loose fit on body, chest, & arms</Typography>
              </Box> */}
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ p: 3, height: "550px" }}>
              <ProductSlider productImages={productDetails?.product_sliderimages} />
            </Box>
          </Grid>
        </Grid>
        <Grid container sx={{ p: 3, boxShadow: `rgba(0, 0, 0, 0.16) 0px 1px 4px;` }}>
          <Grid item xs={12} md={12}>
            <Typography variant="h4" sx={{ color: colors.black }}>Product Overview</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant='h6' fontWeight={'bold'} color={colors.black}>Features</Typography>
            <Box>
              <Divider sx={{ color: colors.darkGray, width: '100%' }} />
              {/* <ul style={{ padding: 4, marginLeft: 14, fontFamily: "sans-serif" }}>
                {features.map((item, index) => (
                  <li key={index} style={{ lineHeight: 1.75, fontSize: "16px", color: colors.black }}>{item}</li>
                ))}
              </ul> */}
              <Typography sx={{ whiteSpace: 'break-spaces', fontSize: "16px", color: colors.black, px: 2, py: 2, fontFamily: "sans-serif" }}>{productDetails?.prod_features}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant='h6' fontWeight={'bold'} color={colors.black} my={2}>Additional Details</Typography>
            <Box>
              <Divider sx={{ color: colors.darkGray, width: '100%' }} />
              <Typography variant='h6' fontWeight={'bold'} color={colors.black} mt={2} >Delivery Options</Typography>
              <ul style={{ padding: 4, marginLeft: "30px", fontFamily: "sans-serif" }}>
                <li style={{ lineHeight: 1.75, fontSize: "16px", color: colors.black }}>
                  <span style={{ fontWeight: "bold", marginRight: 4 }}>Free</span>Delivery — Get it by Fri. Aug 19
                </li>
                <li style={{ lineHeight: 1.75, fontSize: "16px", color: colors.black }}>Rush or Super Rush — Get it as soon as Wed. Aug 10</li>
                <li style={{ lineHeight: 1.75, fontSize: "16px", color: colors.black }}>
                  <span style={{ color: colors.primary, fontWeight: "bold", marginRight: 4 }}>SHIP TO MULTIPLE ADDRESSES</span>— Flat rate shipping is $6.95 per US address
                </li>
              </ul>
              {/* <Typography variant='h6' fontWeight={'bold'} color={colors.black} >Decoration</Typography>
              <Typography color={colors.black} >Screen Printing</Typography> */}
              <Typography variant='h6' fontWeight={'bold'} color={colors.black} >Minimum Quantity</Typography>
              <Typography color={colors.black} >{productDetails?.prod_minimum_qty}</Typography>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ p: 3, width: "100%", boxShadow: `rgba(0, 0, 0, 0.16) 0px 1px 4px;`, my: 4, boxSizing: 'border-box' }} >
          <Typography variant='h6' fontWeight={'bold'} color={colors.black}>Product Information</Typography>
          <Typography sx={{ fontWeight: "18px", color: colors.black, my: 2, whiteSpace: 'break-spaces', }}>{productDetails?.prod_info}</Typography>
          {/* <Typography sx={{ fontWeight: "18px", color: colors.black, my: 2 }}>Available in almost 20 different colors, these A4 performance shirts are available for kids, teens, and adults. Plus, we carry the women's complement: our A4 Women's Promotional Performance Shirt.</Typography>
          <Typography sx={{ fontWeight: "18px", color: colors.black, my: 2 }}>The silky texture of this shirt makes it a favorite for humid climates, keeping your group cool and comfortable on the hottest of summer days. It's great for workouts and all sorts of team sports</Typography> */}
        </Box>
        {/* Clients who trust us */}
        <Box sx={{ p: 3, border: `0.5px solid ${colors.secondary}`, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "space-around", borderRadius: '4px', position: 'relative' }}>
          {brandSVG.map((item, index) => (
            <Box key={index} sx={{ width: "100px", height: "70px", display: "flex", justifyContent: "center", mx: 1.5, my: 1.5 }}>
              <img src={item} width="125px" height="60px" alt="Clients" />
            </Box>
          ))}
          <Box sx={{ textAlign: "center", position: "absolute", top: { xs: -15, md: -22 }, right: { xs: '14%', md: "40%" }, backgroundColor: colors.white, px: 1 }}>
            <Typography sx={{ fontSize: { xs: '18px', md: "28px" }, fontWeight: 800, color: colors.primary }}>Clients who trust us</Typography>
          </Box>
        </Box>
      </Container>
    </Layout>
  )
}

export default StartDesigning