import { Navigate } from "react-router-dom";
import Home from "../views/Home/Home";
import Login from "../views/Auth/Login";
import Register from "../views/Auth/Register";
import DesignTool from "../views/DesignTool/DesignTool";
import BecomePartner from './../views/BecomePartner/BecomePartner';
import WhoWeAre from './../views/WhoWeAre/WhoWeAre';
import ChairmanMessage from './../views/ChairmanMessage/ChairmanMessage';
import Category from './../views/Category/Category';
import Cart from "../views/Cart/Cart";
import Payment from "../views/Payment/Payment";
import Product from "../views/Product/Product";
import SubCategory from "../views/SubCategory/SubCategory";
import StartDesigning from "../views/StartDesigning/StartDesigning";
import ContactUs from './../views/ContactUs/ContactUs';

// *Import Pages

const AppRoutes = [
  {
    path: "*",
    component: <Navigate to="/" />,
  },
  {
    path: "/",
    component: <Home />,
  },
  {
    path: "/login",
    component: <Login />,
  },
  {
    path: "/register",
    component: <Register />,
  },
  {
    path: "/design-tool",
    component: <DesignTool />,
  },
  {
    path: "/who-we-are",
    component: <WhoWeAre />,
  },
  {
    path: "/chairman-message",
    component: <ChairmanMessage />,
  },
  {
    path: "/become-partner",
    component: <BecomePartner />,
  },
  {
    path: "/contact-us",
    component: <ContactUs />,
  },
  {
    path: "/category",
    component: <Category />,
  },
  {
    path: "/category/:category_id",
    component: <SubCategory />,
  },
  {
    path: '/products/:prod_id',
    component: <Product />,
  },
  {
    path: '/products/:prod_name/:prod_id',
    component: <StartDesigning />,
  },
  {
    path: "/cart",
    component: <Cart />
  },
  {
    path: "/payment",
    component: <Payment />
  },
];

export default AppRoutes;
