import React, { useState } from "react";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { Box, Typography, Divider, CardMedia, Button, Grid, Slider, Input, ButtonGroup, TextField, IconButton, FormControlLabel, Switch } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ArrowForwardIos, Close, LockOpenOutlined, LockOutlined, VerticalAlignCenter } from "@mui/icons-material";
import Images from './../../../assets/Images';
import colors from './../../../styles/colors';

const useStyle = makeStyles({
  textSlidePane: {
    height: '95%',
    marginTop: '15%',
    '.slide-pane__header': {
      height: '40px !important',
    },
    '.ReactModalPortal': {
      display: { md: 'none !important' }
    }


  },


});

function UploadImageSlidePane({ isUploadPaneOpen, setIsUploadPaneOpen, panelLoader, changePanel, loaderColor, uploadImg, getImageData, uploadImgPanel, uploadAlignCenter, frontUploadYAxis, flipUploadYAxis, productAngle, backUploadYAxis, frontUploadXAxis,
  flipUploadXAxis, backUploadXAxis, frontUploadRotate, backUploadRotate, setUploadRotateValue, blurRotate, isMakeOneColor, handleMakeOneColor, backgroundRemoveStatus, handleRemoveBackground, getUploadImgPanel,
  selectedOneColor, makeOneColor, renderSwatches, frontIsUploadProportional, uploadLockUnlockProportional, backIsUploadProportional, changeImageWidthSizeValue, frontUploadWPxIntoInches, backUploadWPxIntoInches,
  frontUploadHPxIntoInches, backUploadHPxIntoInches, reset, uploadImgColor, colorLoader, opacity, renderImageColors, selectColor, artColorOptions, rgb, changeFrontImageColor }) {

  const classes = useStyle();

  return (
    <Box sx={{ display: { xs: 'block', md: 'none' } }} >
      <SlidingPane
        className={classes.textSlidePane}
        // hideHeader
        overlayClassName={classes.textSlidePane}
        isOpen={isUploadPaneOpen}
        from="bottom"
        width="100%"
        title="Edit Text"
        onRequestClose={() => {
          setIsUploadPaneOpen(false);
        }}
      >
        <div className="edit-tool-content">
          {/* <div className="header-title">
              <IconButton
                aria-label="close"
                className="close-btn"
                onClick={() => changePanel("", "main")}
              >
                <Close />
              </IconButton>

              <Typography variant="body1" component="h1" align="center">
                Edit Upload
              </Typography>
            </div> */}

          <Grid container spacing={2}>
            <Grid container item md={3} justifyContent="center">
              <Button
                className="center-btn"
                onClick={() => {
                  uploadAlignCenter();
                }}
              >
                <VerticalAlignCenter className="center-icon" />
              </Button>
              <Typography variant="caption">Center</Typography>
            </Grid>

            <Grid
              container
              item
              md={5}
              alignItems="center"
              direction="column"
            >
              <ButtonGroup>
                <Button
                  className={
                    frontUploadYAxis === 1
                      ? ""
                      : "invert-color color-white"
                  }
                  onClick={() =>
                    flipUploadYAxis(
                      productAngle === "front"
                        ? frontUploadYAxis
                        : backUploadYAxis
                    )
                  }
                >
                  <CardMedia
                    component="img"
                    height="20"
                    image={Images.flipY}
                  />
                </Button>
                <Button
                  className={
                    frontUploadXAxis === 1
                      ? ""
                      : "invert-color color-white"
                  }
                  onClick={() =>
                    flipUploadXAxis(
                      productAngle === "front"
                        ? frontUploadXAxis
                        : backUploadXAxis
                    )
                  }
                >
                  <CardMedia
                    component="img"
                    height="20"
                    image={Images.flipX}
                  />
                </Button>
              </ButtonGroup>
              <Typography variant="caption">Flip</Typography>
            </Grid>
          </Grid>

          <Divider />

          <Grid container spacing={2} className="rotation">
            <Grid container item md={3} justifyContent="center">
              <Typography variant="body1" component="h6">
                Rotation
              </Typography>
            </Grid>

            <Grid container item md={7} justifyContent="center">
              <Slider
                value={
                  productAngle === "front"
                    ? frontUploadRotate
                    : backUploadRotate
                }
                onChange={setUploadRotateValue}
                valueLabelDisplay="auto"
                min={-180}
                max={180}
              />
            </Grid>

            <Grid container item md={2} justifyContent="center">
              <Input
                value={
                  productAngle === "front"
                    ? frontUploadRotate
                    : backUploadRotate
                }
                onChange={(e) =>
                  setUploadRotateValue(e, e.target.value)
                }
                onBlur={blurRotate}
                inputProps={{
                  step: 1,
                  min: -180,
                  max: 180,
                  type: "number",
                }}
              />
            </Grid>
          </Grid>

          <Divider />

          <Grid container spacing={2} className="single-color">
            <Grid container item md={12} justifyContent="center">
              <FormControlLabel
                value=""
                control={
                  <Switch
                    checked={isMakeOneColor}
                    onChange={handleMakeOneColor}
                  />
                }
                label="Make One Color"
                labelPlacement="start"
              />
            </Grid>
          </Grid>

          <Divider />

          {/* ========== Remove Upload Image Background ========== */}
          <Grid container spacing={2} className="single-color">
            <Grid
              container
              item
              md={12}
              justifyContent="center"
              alignItems="center"
            >
              <FormControlLabel
                value=""
                control={
                  <Switch
                    checked={backgroundRemoveStatus}
                    onChange={handleRemoveBackground}
                  />
                }
                label="Remove Background Color"
                labelPlacement="start"
              />
            </Grid>
          </Grid>

          <Divider />

          <Grid
            container
            spacing={0}
            className="change-color"
            onClick={() => {
              getUploadImgPanel(null, null, true);
            }}
          >
            <Grid item md={5}>
              <Typography variant="body1" component="h6">
                Edit Color
              </Typography>
            </Grid>

            {isMakeOneColor && (
              <Grid item md={7} style={{ textAlign: "right" }}>
                <Typography
                  component="span"
                  className="image-color"
                  style={{
                    backgroundColor: selectedOneColor,
                  }}
                  onClick={() => {
                    makeOneColor(selectedOneColor);
                  }}
                />
                <ArrowForwardIos />
              </Grid>
            )}

            {isMakeOneColor === false && (
              <Grid
                item
                md={7}
                style={{
                  textAlign: "right",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    overflow: "hidden",
                    width: "145px",
                    height: "30px",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "1",
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {renderSwatches()}
                </div>
                <ArrowForwardIos />
                {/* {totalImageColors <= maxColors &&
                            <ArrowForwardIos />
                          } */}
              </Grid>
            )}
          </Grid>

          <Divider />

          <Grid container spacing={2} className="art-size">
            <Grid item md={4}>
              <Typography variant="body1" component="h6">
                Upload Size
              </Typography>
              <Typography component="p">Width × Height</Typography>
            </Grid>

            <Grid container item md={8} justifyContent="flex-end">
              {productAngle === "front" && (
                <div>
                  {frontIsUploadProportional && (
                    <IconButton
                      aria-label="lock"
                      className="lock-btn"
                      onClick={() => {
                        uploadLockUnlockProportional(false);
                      }}
                    >
                      <LockOutlined />
                    </IconButton>
                  )}

                  {frontIsUploadProportional === false && (
                    <IconButton
                      aria-label="lock open"
                      className="lockOpen-btn"
                      onClick={() => {
                        uploadLockUnlockProportional(true);
                      }}
                    >
                      <LockOpenOutlined />
                    </IconButton>
                  )}
                </div>
              )}

              {productAngle === "back" && (
                <div>
                  {backIsUploadProportional && (
                    <IconButton
                      aria-label="lock"
                      className="lock-btn"
                      onClick={() => {
                        uploadLockUnlockProportional(false);
                      }}
                    >
                      <LockOutlined />
                    </IconButton>
                  )}

                  {backIsUploadProportional === false && (
                    <IconButton
                      aria-label="lock open"
                      className="lockOpen-btn"
                      onClick={() => {
                        uploadLockUnlockProportional(true);
                      }}
                    >
                      <LockOpenOutlined />
                    </IconButton>
                  )}
                </div>
              )}

              <TextField
                variant="outlined"
                ref={changeImageWidthSizeValue}
                value={
                  productAngle === "front"
                    ? frontUploadWPxIntoInches
                    : backUploadWPxIntoInches
                }
                InputProps={{ readOnly: true }}
              // onChange={(e) => {
              //   setUploadInches(e.target.value, frontUploadHPxIntoInches);
              //   getUploadSize({ width: e.target.value, height: frontUploadHPxIntoInches })
              // }}
              // handleResizeImageHeightWidth(e.target.value, frontUploadHPxIntoInches) }}
              />
              {console.log(
                "🚀 ~ file: design-tool.js ~ line 3681 ~ DesignTool ~ frontUploadWPxIntoInches",
                frontUploadWPxIntoInches
              )}
              {/*onChange={(e) => { setUploadInches(e.target.value, frontUploadHPxIntoInches) }}*/}
              {/*InputProps={{ readOnly: true }}*/}
              {/* {console.log("🚀 ~ file: design-tool.js ~ line 3679 ~ DesignTool ~ frontUploadWPxIntoInches", frontUploadWPxIntoInches)} */}

              <Typography variant="body2">in</Typography>
              <Typography variant="caption">x</Typography>

              <TextField
                variant="outlined"
                value={
                  productAngle === "front"
                    ? frontUploadHPxIntoInches
                    : backUploadHPxIntoInches
                }
                InputProps={{ readOnly: true }}
              // onChange={(e) => {
              //   setUploadInches(frontUploadWPxIntoInches, e.target.value);
              //   getUploadSize({ width: frontUploadWPxIntoInches, height: e.target.value })
              // }}
              // onChange={(e) => { handleResizeImageHeightWidth(frontUploadWPxIntoInches, e.target.value) }}
              />

              <Typography variant="body2">in</Typography>
            </Grid>
          </Grid>

          <Divider />

          <Grid container spacing={1} className="reset-save">
            <Grid container item md={6} justifyContent="flex-start">
              <Button
                variant="outlined"
                className="reset-btn"
                onClick={() => {
                  reset();
                }}
              >
                reset to original
              </Button>
            </Grid>

            <Grid container item md={6} justifyContent="flex-start">
              <Button variant="contained" className="save-btn">
                save
              </Button>
            </Grid>
          </Grid>
        </div>
      </SlidingPane>

    </Box>
  );
};

export default UploadImageSlidePane