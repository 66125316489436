import React from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
// import "swiper/css";
// import "swiper/css/pagination";
// import "swiper/css/navigation";
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/modules/navigation/navigation.min.css';


// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";
import '../../../src/index.css';
import { makeStyles } from "@mui/styles";
import colors from './../../styles/colors';
import Images from './../../assets/Images';
import { Box, Typography, Divider, CardMedia } from '@mui/material';
import { PrimaryButton } from './../UI/Buttons';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles({

    paginationBullet: {
        background: colors.secondary,
    },
    paginationBulletActive: {
        background: colors.primary,
    },
})

function HomeCarousel() {
    const classes = useStyles();
    const navigate = useNavigate();
    return (
        <>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    "dynamicBullets": true,
                    clickable: true,
                    bulletClass: classes.paginationBullet + " swiper-pagination-bullet",
                    bulletActiveClass: classes.paginationBulletActive + " swiper-pagination-bullet-active",
                }}
                navigation={false}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper3"
            >
                <SwiperSlide position="relative">
                    <CardMedia sx={{
                        height: '360px',
                        width: "100%",
                        background: `linear-gradient(to right top, rgba(0,0,0,0.5),rgba(255,255,255,0)),url(${Images.slider_one}) no-repeat top center / cover`,
                    }} />
                    {/* <img src={Images.slider_one} width="100%" height="500px" alt="slider 1" /> */}
                    <Box sx={{ my: "auto", textAlign: "center", position: "absolute", top: "50%", left: "50%", transform: 'translate(-50%,-50%)', height: "320px", width: "90%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                        <Typography variant="h3" sx={{ fontWeight: 600, color: colors.black, letterSpacing: "6px", textTransform: "uppercase" }}>Custom</Typography>
                        <Typography variant="h4" sx={{ fontWeight: 600, color: colors.black, letterSpacing: "6px", textTransform: "uppercase" }}>T-Shirts</Typography>
                        <Divider sx={{ backgroundColor: colors.primary, height: "4px", borderRadius: "6px", width: "150px", mx: "auto", mt: 1, mb: 2 }} />
                        <Typography sx={{ color: colors.white, width: "330px", mx: "auto" }}>Create your own t-shirt, tanks, hoodies and more in our online design studio. </Typography>
                        <PrimaryButton onClick={() => { navigate('/design-tool') }} myStyle={{ my: 2, fontWeight: 700 }}>Start Designing Now</PrimaryButton>
                    </Box>
                </SwiperSlide>
                <SwiperSlide position="relative">
                    <CardMedia sx={{
                        height: '360px',
                        width: "100%",
                        background: `linear-gradient(to right top, rgba(0,0,0,1),rgba(255,255,255,0)),url(${Images.slider_two}) no-repeat top center / cover`,
                    }} />
                    {/* <img src={Images.slider_two} width="100%" height="500px" alt="slider 2" /> */}
                    <Box sx={{ my: "auto", textAlign: "center", position: "absolute", top: "50%", left: "50%", transform: 'translate(-50%,-50%)', height: "360px", width: "90%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                        <Typography variant="h4" sx={{ fontWeight: 600, color: colors.secondary, textTransform: "uppercase" }}>Leading Suppliers of Food Delivery Bags In The UAE</Typography>
                        <PrimaryButton onClick={() => { navigate('/design-tool') }} myStyle={{ my: 2, fontWeight: 700 }}>Explore Thermal Bags</PrimaryButton>
                    </Box></SwiperSlide>
                <SwiperSlide position="relative">
                    <CardMedia sx={{
                        height: '360px',
                        width: "100%",
                        background: `linear-gradient(to right top, rgba(0,0,0,0.7),rgba(255,255,255,0)),url(${Images.slider_three}) no-repeat top center / cover`,
                    }} />
                    {/* <img src={Images.slider_three} width="100%" height="500px" alt="slider 3" /> */}
                    <Box sx={{ my: "auto", textAlign: "center", position: "absolute", top: 0, left: 30, width: "50%", height: "360px", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                        <Typography variant="h4" sx={{ fontWeight: 600, color: colors.secondary, textTransform: "uppercase" }}>World of Premium Corporate Uniforms</Typography>
                        <Typography variant="body1" sx={{ fontWeight: 600, color: colors.white, textTransform: "uppercase" }}>Best Uniforms in best rates, anywhere in UAE. </Typography>
                        <PrimaryButton onClick={() => { navigate('/design-tool') }} myStyle={{ my: 2, fontWeight: 700 }}>We Offer More</PrimaryButton>
                    </Box>
                </SwiperSlide>
                <SwiperSlide position="relative">
                    <CardMedia sx={{
                        height: '360px',
                        width: "100%",
                        background: `linear-gradient(to right top, rgba(0,0,0,0.6),rgba(255,255,255,0)),url(${Images.slider_four}) no-repeat top center / cover`,
                    }} />
                    {/* <img src={Images.slider_four} width="100%" height="500px" alt="slider 4" /> */}
                    <Box sx={{ my: "auto", textAlign: "center", position: "absolute", top: 0, left: 50, height: "360px", width: "50%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                        <Typography variant="h4" sx={{ fontWeight: 600, color: colors.secondary, textTransform: "uppercase" }}>Design Custom Clothing & Accessories.</Typography>
                        <Typography variant="body1" sx={{ fontWeight: 600, color: colors.white, textTransform: "uppercase" }}>Create personalized shirts, hoodies, and much more with our online editor</Typography>
                        <PrimaryButton onClick={() => { navigate('/design-tool') }} myStyle={{ my: 2, fontWeight: 700 }}>Create Now</PrimaryButton>
                    </Box>
                </SwiperSlide>
            </Swiper>
        </>
    )
}

export default HomeCarousel

