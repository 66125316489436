import React, { useState, useEffect } from 'react'
import { Grid, Box, Typography, FormControl, TextField, InputLabel, OutlinedInput, InputAdornment, IconButton } from '@mui/material';
import Images, { HandsDeliver, PlaceOrder, TrackOrder, AddSaveDesign } from '../../assets/Images';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import colors from './../../styles/colors';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { PrimaryButton } from './../../components/UI/Buttons';
import { Error } from './../../components/UI/Error';
import useAuth from '../../hooks/useAuth';
import Layout from '../../layout/Layout/Layout';
import { SuccessToaster, ErrorToaster } from '../../components/Toaster/Toaster';
import { Service } from './../../config/service';
import Swal from 'sweetalert2';

function Login() {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // *Login 
  const { register, handleSubmit, formState: { errors } } = useForm();

  // For API Call
  const loginInfo = async (formData) => {
    setLoading(true)
    try {
      let obj = {
        email: formData.email,
        password: formData.password
      }
      const { status, data, responseCode, message } = await Service.login(obj)
      if (status === true && responseCode === 200) {
        setLoading(false)
        SuccessToaster("Login Successfully")
        login(data.token, data.userName, obj.email)
      }
      else {
        Swal.fire({
          text: message,
          icon: 'error',
          confirmButtonColor: colors.primary,
        })
      }

    } catch (error) {
      console.log("🚀 ~ file: Login.js ~ line 47 ~ loginInfo ~ error", error)
      ErrorToaster("Something went wrong.")
    } finally {
      setLoading(false)
    }
  };

  // const loginInfo = async (formData) => {
  //   let token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiMTdmMTQwMzM0OGVkYTQ1ZmZlYTI1MzA5NzhkNWJiNzg5OGRmMjFiZDdiZTBlYjExNDQzMzgxM2Y1NDZmYjQ1MjVkMWZmODFjMDU1MGZhNDQiLCJpYXQiOjE2NjMwNjEwNjYuMDI2MTg3LCJuYmYiOjE2NjMwNjEwNjYuMDI2MTkxLCJleHAiOjE2OTQ1OTcwNjYuMDE5MTQ3LCJzdWIiOiI3Iiwic2NvcGVzIjpbXX0.r1IKlsD5gmWAd9W-1xtMeNmSv3cEJjOF_vjprnGUTp4nvO2gcKP6fG3iV86Sxb97xLUGbu6fKDv9WEpKdpXrImIkxFL3ikGJwmY7IULjym4B0uB6JMjHDPU_vZpcHr8oajbSyqx5bdBFJ-1rKsz3UsCKbCTgry9Ihxmszdczk7RxGQGBlrYg0CNeH-r8nLVSCdnjtcREBt2Ne30sY_c8WMYsc1crFU-GMbRNjxZmz3zwBNcY3BCtavx-dlmil7G-Cq8Szb74z4nLwXejVNw90PJknsQIwyAZdj6LwuYaZLPdwQduPmVTuPUjkX5LyEHcoF0eNRFI3wRGWaYQlPVqatKu86H8_6L9ozQ2tL7GCqFq0ljfJMG-8z76mhkqci4lhPooPDt39IGdk-dHK4yC0EIhlqe5qcxsjtMNsDXDV3LBzRu5iCR6F7JUaHEF5cZQIXI4rxN7kIHfd93BYE7I3x386b-KLXt9Y7q8EHTRNgRsnzjvB3_Str2wJgZcDNAq5MVHBN9F7XyHFmGQrpRiznjtWseMo0QoiGlIj7hiY00tHuxAGHelL3keSaa8o1aMdGPkdpvBvLYN-2fNkhFycV7XQSiulzC-t7GeuGtU_gt2Jm_iGv_Pt_wPy_v1DRc6z2toK97xxYIFzuawAToYk_69LOsRS2MhiGKtwMePRp8';
  //   setLoading(true)
  //   login(token)
  //   setTimeout(() => {
  //     setLoading(false)
  //   }, 1000)
  //   navigate('/')
  // }

  const [showPassword, setShowPassword] = useState(false);


  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [])

  return (
    <Layout>
      <Grid container columnSpacing={4} justifyContent="center" alignItems="center" sx={{ py: 6, px: 2 }} >
        <Grid item xs={12} md={5}>
          <Box sx={{ borderRadius: "6px", textAlign: "center", boxShadow: `rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;`, p: 4 }} >
            <img src={Images.logo} width="25%" alt="logo" />
            <Typography sx={{ fontWeight: "bold", fontSize: "22px", color: colors.black }}>Login to Your Account</Typography>
            <Typography sx={{ fontSize: "12px", color: colors.textSecondary }}>Enter your email address to access your saved designs, track your orders, and place a reorder!</Typography>
            <Typography component={"form"} onSubmit={handleSubmit(loginInfo)}>
              <Box sx={{ mt: 2, display: "flex", flexDirection: 'column' }}>
                <FormControl variant="outlined" sx={{ my: 1 }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Email"
                    type="email"
                    error={errors?.email?.message && (true)}
                    {...register("email", {
                      required: 'Please enter your email.',
                    })}
                  />
                  {errors?.email?.message && (
                    <Error message={errors?.email?.message} />
                  )}
                </FormControl>
                <FormControl variant="outlined" sx={{ my: 1 }}>
                  <InputLabel error={errors?.password?.message && (true)}>Password</InputLabel>
                  <OutlinedInput
                    label="Password"
                    fullWidth
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => { handleClickShowPassword() }}
                          sx={{ color: colors.primary }}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    error={errors?.password?.message && (true)}
                    {...register("password", {
                      required: 'Please create your password.',
                    })}
                  />
                  {errors?.password?.message && (
                    <Error message={errors?.password?.message} />
                  )}
                </FormControl>

              </Box>
              <Typography
                onClick={() => { navigate('/register') }}
                sx={{
                  color: colors.textSecondary, my: 1,
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
              >
                Don't have an account ? <span style={{ color: colors.primary }}>Create Account</span>
              </Typography>
              <PrimaryButton
                fullWidth
                variant="contained"
                type="submit"
                loading={loading}
                sx={{
                  p: 1,
                  my: 1,
                  width: '100%'
                }}
              >
                Login
              </PrimaryButton>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4} sx={{ mt: { xs: 2 } }}>
          <Typography sx={{ color: colors.black, fontSize: "18px" }}>Did you know?</Typography>
          <Typography sx={{ color: colors.textSecondary, fontSize: "12px" }}>Inside your account you can:</Typography>
          <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
            <Box sx={{ width: "10%" }}>
              <HandsDeliver />
            </Box>
            <Typography sx={{ fontSize: "16px", ml: 2 }}>Hands Delivery</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
            <Box sx={{ width: "10%" }}>
              <PlaceOrder />
            </Box>
            <Typography sx={{ fontSize: "16px", ml: 2 }}>Place A Reorder</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
            <Box sx={{ width: "10%" }}>
              <TrackOrder />
            </Box>
            <Typography sx={{ fontSize: "16px", ml: 2 }}>Track an Existing Order</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
            <Box sx={{ width: "10%" }}>
              <AddSaveDesign />
            </Box>
            <Typography sx={{ fontSize: "16px", ml: 2 }}>Share Your Designs</Typography>
          </Box>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default Login