// *Import Components

import ViewOrderPage from './../components/ViewOrderPage/ViewOrderPage';
import UserProfile from './../views/UserProfile/UserProfile';
import MyOrders from './../views/MyOrder/MyOrder';


const PrivateRoutes = [
  {
    path: "/order-details",
    component: <MyOrders />,
  },
  {
    path: "/order-invoice/:id",
    component: <ViewOrderPage />
  },
  {
    path: "/profile-details",
    component: <UserProfile />
  },
]

export default PrivateRoutes