const colors = {
  // primary: '#179bd8',
  primary: '#d30e07',
  // primaryLight: '#c9f2ff',
  primaryLight: '#d30e073d',
  // secondary: '#1b8eb3',
  secondary: '#d5b64f',
  secondaryLight: '#93e5ff',
  tertiary: '#161624',
  textPrimary: '#262626',
  textSecondary: '#757575',
  bondiBlue: '#03989E',
  bondiBlueLight: '#17c7cf',
  textLight: '#484D50',
  candleLight: '#FFD824',
  white: '#ffffff',
  whiteSmoke: '#f6f6f6',
  mercury: '#f5f9fc',
  black: '#222222',
  lightGray: '#f5f5f5',
  danger: '#FF2121',
  ebonyClay: '#252340',
  gunMetal: '#e5e7ea',
  success: '#79AA5A',
  cloud: '#C4C4C4',

};

export default colors;