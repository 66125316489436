import React, { forwardRef, useState } from 'react';
import { Box, Slide, DialogTitle, DialogContentText, DialogContent, DialogActions, Dialog, Button, IconButton, TextField, Typography } from '@mui/material';
import { Close } from '@material-ui/icons';
import { PrimaryButton } from '../../UI/Buttons';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddProductColorDialog({ openProductColorDialog, setProductColorDialog, colors, maskColor, changeColor }) {


  const handleClose = () => {
    setProductColorDialog(false);
  };

  return (
    <Box>
      <Dialog
        open={openProductColorDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ display: 'flex', alignItems: "center", justifyContent: 'space-evenly' }}>
          <Typography component="h1" sx={{ fontWeight: "bold" }} >
            Garment color
          </Typography>

          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton></DialogTitle>
        <DialogContent>
          <Box sx={{ my: 1.5 }}>
            <Typography
              className="selected-color-box"
              style={{ backgroundColor: maskColor }}
            ></Typography>

            <Typography sx={{ fontSize: 13 }} >
              Ordering fewer than 5 total items?
            </Typography>
            <Box sx={{ display: 'flex', alignItems: "center", my: 1 }}>
              {colors.map((item, index) => (
                <Box key={index} onClick={() => { changeColor(item.code) }}
                  sx={{ mr: 1.5, width: '25px', height: '25px', borderRadius: '6px', backgroundColor: item.code, cursor: 'pointer', boxShadow: `rgba(0, 0, 0, 0.24) 0px 3px 8px;` }}
                />
              ))}
            </Box>
            <Box sx={{ display: 'flex', alignItems: "center" }}>
              <Typography variant="body1">
                Selected color:
              </Typography>
              <Box
                sx={{ width: '25px', height: '25px', borderRadius: '6px', backgroundColor: maskColor, mx: 2, boxShadow: `rgba(0, 0, 0, 0.24) 0px 3px 8px;` }}
              ></Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
