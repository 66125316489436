import React, { Fragment } from 'react'
import { TabPanel } from '@material-ui/lab';
import { Typography, List, ListItem, CardMedia, ListItemText, Divider } from '@mui/material';
import Images from './../../assets/Images';

function MainPanel({ changePanel, getTextEditPanel, getFontEditPanel, showSubCategory, getArtWorkSubCatByCat, getUploadImg, getUploadImgPanel }) {
  return (
    <Fragment>
      <TabPanel className="main-panel" value="main">
        <Typography variant="body1" component="h1" align="center">
          Lets start a project together !
        </Typography>

        <List component="nav">
          {/* ========== Add Text ========== */}
          <ListItem
            button
            onClick={() => {
              changePanel("", "addText");
              getTextEditPanel(true, false, false, false);
              getFontEditPanel(true);
            }}
          >
            <CardMedia
              component="img"
              alt="add text"
              image={Images.toolAddText}
            />

            <ListItemText primary="Add Text" />
          </ListItem>

          <Divider />

          {/* ========== Add Art ========== */}
          <ListItem
            button
            onClick={() => {
              changePanel("", "addArt");
              showSubCategory(true);
              getArtWorkSubCatByCat("", "", "");
            }}
          >
            <CardMedia
              component="img"
              alt="add art"
              image={Images.toolAddArt}
            />

            <ListItemText primary="Add Art" />
          </ListItem>

          <Divider />

          {/* ========== Upload Art ========== */}
          <ListItem
            button
            onClick={() => {
              changePanel("", "upload");
              getUploadImg(true);
              getUploadImgPanel(false, false, false);
            }}
          >
            <CardMedia
              component="img"
              alt="upload"
              image={Images.toolUpload}
            />

            <ListItemText primary="Upload" />
          </ListItem>

          <Divider />

          {/* ========== Change Product ========== */}
          <ListItem button>
            <CardMedia
              component="img"
              alt="change product"
              image={Images.toolChangeProduct}
            />

            <ListItemText primary="Change Product" />
          </ListItem>

          {/* ========== Start over ========== */}
          <Typography
            className="start-over"
            onClick={() => window.location.reload()}
          >
            Start Over
          </Typography>
        </List>
      </TabPanel>
    </Fragment>
  )
}

export default MainPanel