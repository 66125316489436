import React from 'react';
import { alpha, Box, InputBase, Typography } from '@mui/material';
import styled from '@emotion/styled';
import SearchIcon from '@mui/icons-material/Search';
import colors from './../../styles/colors';

//* Imported Components

// For Search Bar
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '8.5ch',
      '&:focus': {
        width: '35ch',
      },
    },
  },
}));

function SearchFilter() {
  return (
    <Box sx={{ display: "flex", algnItems: "center", justifyContent: "space-between", py: 1.5, px: 2 }}>
      <Typography
        sx={{
          fontSize: { xs: "18px", md: '26px' },
          color: colors.black,
          fontWeight: 600,
        }}>
        T-Shirts
      </Typography>
      {/* <Search style={{ border: `3px solid ${colors.primary}`, width: "400px", display: "flex", alignItems: "center" }}>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search for t-shirts,hoodies,backpacks,etc."
          inputProps={{ 'aria-label': 'search' }}
        />
      </Search> */}
    </Box>
  )
}

export default SearchFilter