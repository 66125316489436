import React, { useState } from 'react';
import { Box, Typography, Dialog, DialogActions, DialogContent, useMediaQuery, TextField, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import colors from './../../../styles/colors';
import { Close } from '@mui/icons-material';
import { PrimaryButton, SecondaryButton } from './../../UI/Buttons';
import ColorBox from './../../UI/ColorBox';
import { useDispatch } from 'react-redux';
import { incrementAsyncTotalItems, setOrderSaveDetails, setTotalAddToCart } from '../../../features/counter/counterSlice';
import { incrementAsyncTotalAdultSize } from './../../../features/counter/counterSlice';

export default function AddToCart({ openAddToCartDialog, setOpenAddToCartDialog, colorName, prod_id }) {

  const [maskColor, setMaskColor] = useState(colorName[0].color_code);
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    setOpenAddToCartDialog(false);
  };

  // Size Array Object
  const [selectedSizes, setSelectedSizes] = useState({ 'S': 0, 'M': 0, 'L': 0, 'XL': 0, '2XL': 0, '4XL': 0 })

  // Size
  const sizes = ['S', 'M', 'L', 'XL', '2XL', '4XL']


  const handleAdultSize = (key, value) => {
    const dummyValue = selectedSizes
    dummyValue[key] = value
    setSelectedSizes(dummyValue)
    console.log('selectedSizes', selectedSizes)
    handleTotalItem()
  }

  const [totalItems, setTotalItems] = useState(0)
  // Accumulated Total Item
  const handleTotalItem = () => {
    let total = 0
    for (const key in selectedSizes) {
      total += Number(selectedSizes[key]) || 0
    }
    dispatch(incrementAsyncTotalItems(Number(total) || 0));
    setTotalItems(total)
  }

  const addToCartOrder = () => {
    let setOrderDetails = {
      email: localStorage.getItem('userEmail'),
      productId: prod_id, // *0 => No Product Selected
      productColor: maskColor,
      designSize: JSON.stringify(selectedSizes),
    };
    dispatch(setOrderSaveDetails(setOrderDetails));
    let obj = {
      designEmail: localStorage.getItem('userEmail'),
      totalItem: totalItems,
      totalPrice: totalItems * 27,
      adultSize: selectedSizes,
      eachItemPrice: 27,
    }
    dispatch(setTotalAddToCart(obj));
  }

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={openAddToCartDialog}
        onClose={handleClose}
        sx={{ borderRadius: '18px' }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <Box>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", my: 1.5 }}>
              <Typography sx={{ fontSize: "12px", color: colors.primary }}>QUANTITY & SIZES</Typography>
              <Close sx={{ color: colors.black, cursor: "pointer" }} onClick={handleClose} />
            </Box>
            <Typography sx={{ fontSize: "26px", color: colors.black, fontWeight: "bold", mb: 2 }}>Choose Quantity & Sizes</Typography>
            <Typography sx={{ fontSize: '13.6px', color: colors.textSecondary, }}>Enter quantities to calculate the price. Save money by increasing quantity and reducing ink colors in your design.</Typography>
          </Box>
          <Box>
            <Typography sx={{ fontSize: "20px", color: colors.black, fontWeight: "bold", mt: 2 }}>Available Colors:</Typography>
            {/* <ColorBox colorName={colorName} /> */}
            <Box sx={{ display: 'flex', alignItems: "center", my: 1 }}>
              {colorName.map((item, index) => (
                <Box key={index} onClick={() => { setMaskColor(item.color_code) }}
                  sx={{ mr: 1.5, width: '25px', height: '25px', borderRadius: '6px', backgroundColor: item.color_code, cursor: 'pointer', boxShadow: `rgba(0, 0, 0, 0.24) 0px 3px 8px;` }}
                />
              ))}
            </Box>
            <Box sx={{ display: 'flex', alignItems: "center" }}>
              <Typography variant="body1">
                Selected color:
              </Typography>
              <Box
                sx={{ width: '25px', height: '25px', borderRadius: '6px', backgroundColor: maskColor, mx: 2, boxShadow: `rgba(0, 0, 0, 0.24) 0px 3px 8px;` }}
              ></Box>
            </Box>
          </Box>
          <Box sx={{ my: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 2 }}>
              <Typography sx={{ fontWeight: "bold", color: colors.black }}>Sizes</Typography>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ fontSize: "16px", color: colors.black, fontWeight: 'bold' }}>Total: &nbsp;</Typography>
                <Typography sx={{ fontSize: "16px", color: colors.textSecondary }}>{totalItems} Items</Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mr: 4 }}>
              {[...Array(Number(6))].map((item, index) => (
                <Box key={index} sx={{ textAlign: "center" }}>
                  <Typography sx={{ fontSize: "14px", color: colors.textSecondary }}>{sizes[index]}</Typography>
                  <Box sx={{ width: "70px" }}>
                    <TextField
                      defaultValue={selectedSizes[sizes[index]] !== 0 && selectedSizes[sizes[index]]}
                      onChange={(e) => { handleAdultSize(sizes[index], e.target.value) }} className='myNumberType' type={"number"} size="small" variant="outlined" />
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
          <Divider sx={{ my: 2 }} />
          <Box sx={{ my: 4, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Box>
              <Typography sx={{ fontSize: "16px", fontWeight: "bold", color: colors.black }}>Your Total ({totalItems} Items)</Typography>
              <Typography sx={{ fontSize: "16px", color: colors.textSecondary }}>
                <span style={{ fontWeight: "bold", color: colors.black }}>$27</span> each</Typography>
            </Box>
            <Typography sx={{ fontSize: "16px", fontWeight: "bold", color: colors.black }}>${+totalItems * (27)}</Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ mb: 2 }}>
          <SecondaryButton autoFocus onClick={handleClose}>
            Cancel
          </SecondaryButton>
          <PrimaryButton onClick={() => { dispatch(incrementAsyncTotalAdultSize(selectedSizes)); addToCartOrder(); handleClose() }} disabled={totalItems === 0} autoFocus>
            Add To Cart
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </div >
  );
}
