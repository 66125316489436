import React, { useState, useEffect } from 'react'
import { Service } from './../../config/service';
import { Grid, Box, Typography, Divider, FormControl, TextField } from '@mui/material';
import { BorderColorTwoTone, EmailTwoTone, PhoneAndroidTwoTone } from '@material-ui/icons';
import Layout from './../../layout/Layout/Layout';
import colors from './../../styles/colors';
import Images from './../../assets/Images';
import { useForm } from 'react-hook-form';
import { Error } from './../../components/UI/Error';
import { PrimaryButton } from './../../components/UI/Buttons';
import { ErrorToaster, SuccessToaster } from '../../components/Toaster/Toaster';
import { Swal } from 'sweetalert2';

function UserProfile() {

  const [editDetails, setEditDetails] = useState(false)
  const { register, handleSubmit, formState: { errors } } = useForm();
  // For Api Call
  const [profileDetails, setProfileDetails] = useState();

  //* Get Profile Details API
  const getProfileDetails = async () => {
    try {
      const { data } = await Service.getProfilePersonalInfo()
      localStorage.setItem('userName', data.first_name + " " + data.last_name)
      console.log("🚀 ~ file: UserProfile.js ~ line 17 ~ getOrderDetails ~ data", data)
      setProfileDetails(data)
    } catch (error) {
      console.log("🚀 ~ file: UserProfile.js ~ line 21 ~ getOrderDetails ~ error", error)

    }
  }

  // Api For Update Details
  const [loading, setLoading] = useState(false);
  const handleUpdateUserDetails = async (formData) => {
    setLoading(true)
    try {
      let obj = {
        firstname: formData.first_name,
        lastname: formData.last_name,
        phone: formData.phone,
        s_address: formData.ship_address,
        s_state: formData.ship_state,
        s_postalcode: formData.ship_postalCode,
        s_city: formData.ship_city,
        s_country: formData.ship_country,
        b_address: formData.address,
        b_state: formData.state,
        b_postalcode: formData.postalCode,
        b_city: formData.city,
        b_country: formData.country,
      }
      const { status, data, responseCode, message } = await Service.updateProfilePersonalInfo(obj)
      if (status === true && responseCode === 200) {
        setLoading(false)
        SuccessToaster("Profile Updated")
        getProfileDetails();
        setEditDetails(false);
      }
      else {
        Swal.fire({
          text: message,
          icon: 'error',
          confirmButtonColor: colors.primary,
        })
      }

    } catch (error) {
      console.log("🚀 ~ file: Login.js ~ line 47 ~ loginInfo ~ error", error)
      ErrorToaster("Something went wrong.")
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    getProfileDetails();
  }, [])

  return (
    <Layout>
      <Grid container sx={{ my: 8, justifyContent: 'space-evenly' }}>
        <Grid item xs={12} md={4} sx={{ height: 'max-content', border: `1px solid rgb(227, 232, 232)`, borderRadius: "8px", "&:hover": { transition: `box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;` }, mb: 2, mx: { xs: 2 } }}>
          <Box >
            <Box sx={{ display: "flex", alignItems: "center", py: 1, mx: 2 }}>
              <img src={Images.userProfile} width="60px" height="60px" alt="User Profile" style={{ borderRadius: '60px' }} />
              <Typography sx={{ fontWeight: "bold", color: colors.black, fontSize: "18px", ml: 2 }}>{profileDetails?.first_name} {profileDetails?.last_name}</Typography>
            </Box>
          </Box>
          <Divider sx={{ width: "100%", bgColor: colors.textSecondary, my: 1 }} />
          <Box sx={{ mx: 2, my: 1.5, p: 2, display: "flex", alignItems: "center", flexWrap: 'wrap', justifyContent: 'space-between', "&:hover": { backgroundColor: colors.primaryLight, cursor: "pointer" } }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <EmailTwoTone />
              <Typography sx={{ fontWeight: "bold", color: colors.black, fontSize: "16px", ml: 2 }}>Email:</Typography>
            </Box>
            <Typography sx={{ color: colors.textSecondary, fontSize: "14px", ml: 2 }}>{profileDetails?.email_address}</Typography>
          </Box>
          <Divider sx={{ width: "100%", bgColor: colors.textSecondary, my: 1 }} />
          <Box sx={{ mx: 2, my: 1.5, p: 2, display: "flex", alignItems: "center", flexWrap: 'wrap', justifyContent: 'space-between', "&:hover": { backgroundColor: colors.primaryLight, cursor: "pointer" } }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <PhoneAndroidTwoTone />
              <Typography sx={{ fontWeight: "bold", color: colors.black, fontSize: "16px", ml: 2 }}>Phone:</Typography>
            </Box>
            <Typography sx={{ color: colors.textSecondary, fontSize: "14px", ml: 2 }}>{profileDetails?.phone ? profileDetails?.phone : "No Number"}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={7} sx={{ px: { xs: 2, md: 0 }, boxSizing: 'border-box' }}>
          <Typography component={"form"} onSubmit={handleSubmit(handleUpdateUserDetails)}>
            <Box sx={{ p: 2, border: `1px solid rgb(227, 232, 232)`, borderRadius: "8px", "&:hover": { transition: `box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;` } }}>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Typography sx={{ fontweight: 600 }}>Personal Info</Typography>
                <Box onClick={() => setEditDetails(!editDetails)} sx={{ bgcolor: colors.primaryLight, borderRadius: "8px", px: 1.5, py: 0.5, cursor: "pointer" }}>
                  <BorderColorTwoTone style={{ color: colors.primary }} />
                </Box>
              </Box>
              <Divider sx={{ width: "100%", bgColor: colors.textSecondary, my: 2 }} />
              <Grid container my={1} alignItems='center'>
                <Grid item md={4}>
                  <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>First Name</Typography>
                </Grid>
                <Grid item md={0.75}>
                  <Typography sx={{ fontSize: "14px" }}>:</Typography>
                </Grid>
                <Grid item md={7.25}>
                  {editDetails ? (
                    <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                      <TextField
                        size="small"
                        variant="outlined"
                        label="First name"
                        type="text"
                        defaultValue={profileDetails?.first_name}
                        error={errors?.first_name?.message && (true)}
                        {...register("first_name", {
                          required: 'Please enter your First name.',
                        })}
                      />
                      {errors?.first_name?.message && (
                        <Error message={errors?.first_name?.message} />
                      )}
                    </FormControl>
                  ) : (
                    <Typography sx={{ fontSize: "14px", color: colors.textSecondary }}>{profileDetails?.first_name}</Typography>
                  )}
                </Grid>
              </Grid>
              <Grid container my={1} alignItems='center'>
                <Grid item md={4}>
                  <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>Last Name</Typography>
                </Grid>
                <Grid item md={0.75}>
                  <Typography sx={{ fontSize: "14px" }}>:</Typography>
                </Grid>
                <Grid item md={7.25}>
                  {editDetails ? (
                    <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                      <TextField
                        size="small"
                        variant="outlined"
                        label="Last name"
                        type="text"
                        defaultValue={profileDetails?.last_name}
                        error={errors?.last_name?.message && (true)}
                        {...register("last_name")}
                      />
                      {errors?.last_name?.message && (
                        <Error message={errors?.last_name?.message} />
                      )}
                    </FormControl>) : (
                    <Typography sx={{ fontSize: "14px", color: colors.textSecondary }}>{profileDetails?.last_name}</Typography>
                  )}
                </Grid>
              </Grid>
              <Grid container my={1} alignItems='center'>
                <Grid item md={4}>
                  <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>Phone Number</Typography>
                </Grid>
                <Grid item md={0.75}>
                  <Typography sx={{ fontSize: "14px" }}>:</Typography>
                </Grid>
                <Grid item md={7.25}>
                  {editDetails ? (
                    <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                      <TextField
                        variant="outlined"
                        size="small"
                        label="Phone"
                        type="number"
                        defaultValue={profileDetails?.phone ? profileDetails?.phone : "No Phone Number"}
                        error={errors?.phone?.message && (true)}
                        {...register("phone")}
                      />
                      {errors?.phone?.message && (
                        <Error message={errors?.phone?.message} />
                      )}
                    </FormControl>
                  ) : (
                    <Typography sx={{ fontSize: "14px", color: colors.textSecondary }}>{profileDetails?.phone ? profileDetails?.phone : "No Phone Number"}</Typography>
                  )}
                </Grid>
              </Grid>
            </Box>
            {/* Shipping Details */}
            <Box sx={{ my: 1.5, p: 2, border: `1px solid rgb(227, 232, 232)`, borderRadius: "8px", "&:hover": { transition: `box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;` } }}>
              <Typography sx={{ fontweight: 600 }}>Shipping Info</Typography>
              <Divider sx={{ width: "100%", bgColor: colors.textSecondary, my: 2 }} />
              <Grid container my={1} alignItems='center'>
                <Grid item md={4}>
                  <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>Address</Typography>
                </Grid>
                <Grid item md={0.75}>
                  <Typography sx={{ fontSize: "14px" }}>:</Typography>
                </Grid>
                <Grid item md={7.25}>
                  {editDetails ? (
                    <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                      <TextField
                        size="small"
                        variant="outlined"
                        label="Address"
                        type="text"
                        defaultValue={profileDetails?.ship_to_address ? profileDetails?.ship_to_address : "No Address"}
                        error={errors?.ship_address?.message && (true)}
                        {...register("ship_address")}
                      />
                      {errors?.ship_address?.message && (
                        <Error message={errors?.ship_address?.message} />
                      )}
                    </FormControl>

                  ) : (
                    <Typography sx={{ fontSize: "14px", color: colors.textSecondary }}>{profileDetails?.ship_to_address ? profileDetails?.ship_to_address : "No Address"}</Typography>
                  )}
                </Grid>
              </Grid>
              <Grid container my={1} alignItems='center'>
                <Grid item md={4}>
                  <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>City</Typography>
                </Grid>
                <Grid item md={0.75}>
                  <Typography sx={{ fontSize: "14px" }}>:</Typography>
                </Grid>
                <Grid item md={7.25}>
                  {editDetails ? (
                    <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                      <TextField
                        size="small"
                        variant="outlined"
                        label="City"
                        type="text"
                        defaultValue={profileDetails?.ship_to_address_city ? profileDetails?.ship_to_address_city : "No City"}
                        error={errors?.ship_city?.message && (true)}
                        {...register("ship_city")}
                      />
                      {errors?.ship_city?.message && (
                        <Error message={errors?.ship_city?.message} />
                      )}
                    </FormControl>
                  ) : (
                    <Typography sx={{ fontSize: "14px", color: colors.textSecondary }}>{profileDetails?.ship_to_address_city ? profileDetails?.ship_to_address_city : "No City"}</Typography>
                  )}
                </Grid>
              </Grid>
              <Grid container my={1} alignItems='center'>
                <Grid item md={4}>
                  <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>Country</Typography>
                </Grid>
                <Grid item md={0.75}>
                  <Typography sx={{ fontSize: "14px" }}>:</Typography>
                </Grid>
                <Grid item md={7.25}>
                  {editDetails ? (
                    <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                      <TextField
                        size="small"
                        variant="outlined"
                        label="Country"
                        type="text"
                        defaultValue={profileDetails?.ship_to_address_country ? profileDetails?.ship_to_address_country : "No Country"}
                        error={errors?.ship_country?.message && (true)}
                        {...register("ship_country")}
                      />
                      {errors?.ship_country?.message && (
                        <Error message={errors?.ship_country?.message} />
                      )}
                    </FormControl>
                  ) : (
                    <Typography sx={{ fontSize: "14px", color: colors.textSecondary }}>{profileDetails?.ship_to_address_country ? profileDetails?.ship_to_address_country : "No Country"}</Typography>
                  )}
                </Grid>
              </Grid>
              <Grid container my={1} alignItems='center'>
                <Grid item md={4}>
                  <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>State</Typography>
                </Grid>
                <Grid item md={0.75}>
                  <Typography sx={{ fontSize: "14px" }}>:</Typography>
                </Grid>
                <Grid item md={7.25}>
                  {editDetails ? (
                    <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                      <TextField
                        size="small"
                        variant="outlined"
                        label="State"
                        type="text"
                        defaultValue={profileDetails?.ship_to_address_state ? profileDetails?.ship_to_address_state : "No State"}
                        error={errors?.ship_state?.message && (true)}
                        {...register("ship_state")}
                      />
                      {errors?.ship_state?.message && (
                        <Error message={errors?.ship_state?.message} />
                      )}
                    </FormControl>
                  ) : (
                    <Typography sx={{ fontSize: "14px", color: colors.textSecondary }}>{profileDetails?.ship_to_address_state ? profileDetails?.ship_to_address_state : "No State"}</Typography>
                  )}
                </Grid>
              </Grid>
              <Grid container my={1} alignItems='center'>
                <Grid item md={4}>
                  <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>Postal Code</Typography>
                </Grid>
                <Grid item md={0.75}>
                  <Typography sx={{ fontSize: "14px" }}>:</Typography>
                </Grid>
                <Grid item md={7.25}>
                  {editDetails ? (
                    <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                      <TextField
                        size="small"
                        variant="outlined"
                        label="Postal Code"
                        type="number"
                        defaultValue={profileDetails?.ship_to_address_postal_code ? profileDetails?.ship_to_address_postal_code : "No Postal Code"}
                        error={errors?.ship_postalCode?.message && (true)}
                        {...register("ship_postalCode")}
                      />
                      {errors?.ship_postalCode?.message && (
                        <Error message={errors?.ship_postalCode?.message} />
                      )}
                    </FormControl>
                  ) : (
                    <Typography sx={{ fontSize: "14px", color: colors.textSecondary }}>{profileDetails?.ship_to_address_postal_code ? profileDetails?.ship_to_address_postal_code : "No Postal Code"}</Typography>
                  )}
                </Grid>
              </Grid>
            </Box>
            {/* Billing Details */}
            <Box sx={{ my: 1.5, p: 2, border: `1px solid rgb(227, 232, 232)`, borderRadius: "8px", "&:hover": { transition: `box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;` } }}>
              <Typography sx={{ fontweight: 600 }}>Billing Info</Typography>
              <Divider sx={{ width: "100%", bgColor: colors.textSecondary, my: 2 }} />
              <Grid container my={1} alignItems='center'>
                <Grid item md={4}>
                  <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>Address</Typography>
                </Grid>
                <Grid item md={0.75}>
                  <Typography sx={{ fontSize: "14px" }}>:</Typography>
                </Grid>
                <Grid item md={7.25}>
                  {editDetails ? (
                    <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                      <TextField
                        size="small"
                        variant="outlined"
                        label="Address"
                        type="text"
                        defaultValue={profileDetails?.bill_to_address ? profileDetails?.bill_to_address : "No Address"}
                        error={errors?.address?.message && (true)}
                        {...register("address")}
                      />
                      {errors?.address?.message && (
                        <Error message={errors?.address?.message} />
                      )}
                    </FormControl>
                  ) : (
                    <Typography sx={{ fontSize: "14px", color: colors.textSecondary }}>{profileDetails?.bill_to_address ? profileDetails?.bill_to_address : "No Address"}</Typography>
                  )}
                </Grid>
              </Grid>
              <Grid container my={1} alignItems='center'>
                <Grid item md={4}>
                  <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>City</Typography>
                </Grid>
                <Grid item md={0.75}>
                  <Typography sx={{ fontSize: "14px" }}>:</Typography>
                </Grid>
                <Grid item md={7.25}>
                  {editDetails ? (
                    <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                      <TextField
                        size="small"
                        variant="outlined"
                        label="City"
                        type="text"
                        defaultValue={profileDetails?.first_name}
                        error={errors?.city?.message && (true)}
                        {...register("city")}
                      />
                      {errors?.city?.message && (
                        <Error message={errors?.city?.message} />
                      )}
                    </FormControl>
                  ) : (
                    <Typography sx={{ fontSize: "14px", color: colors.textSecondary }}>{profileDetails?.bill_to_address_city ? profileDetails?.bill_to_address_city : "No City"}</Typography>
                  )}
                </Grid>
              </Grid>
              <Grid container my={1} alignItems='center'>
                <Grid item md={4}>
                  <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>Country</Typography>
                </Grid>
                <Grid item md={0.75}>
                  <Typography sx={{ fontSize: "14px" }}>:</Typography>
                </Grid>
                <Grid item md={7.25}>
                  {editDetails ? (
                    <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                      <TextField
                        size="small"
                        variant="outlined"
                        label="Country"
                        type="text"
                        defaultValue={profileDetails?.bill_to_address_country ? profileDetails?.bill_to_address_country : "No Country"}
                        error={errors?.country?.message && (true)}
                        {...register("country")}
                      />
                      {errors?.country?.message && (
                        <Error message={errors?.country?.message} />
                      )}
                    </FormControl>
                  ) : (
                    <Typography sx={{ fontSize: "14px", color: colors.textSecondary }}>{profileDetails?.bill_to_address_country ? profileDetails?.bill_to_address_country : "No Country"}</Typography>
                  )}
                </Grid>
              </Grid>
              <Grid container my={1} alignItems='center'>
                <Grid item md={4}>
                  <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>State</Typography>
                </Grid>
                <Grid item md={0.75}>
                  <Typography sx={{ fontSize: "14px" }}>:</Typography>
                </Grid>
                <Grid item md={7.25}>
                  {editDetails ? (
                    <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                      <TextField
                        size="small"
                        variant="outlined"
                        label="State"
                        type="text"
                        defaultValue={profileDetails?.bill_to_address_state ? profileDetails?.bill_to_address_state : "No State"}
                        error={errors?.state?.message && (true)}
                        {...register("state")}
                      />
                      {errors?.state?.message && (
                        <Error message={errors?.state?.message} />
                      )}
                    </FormControl>
                  ) : (
                    <Typography sx={{ fontSize: "14px", color: colors.textSecondary }}>{profileDetails?.bill_to_address_state ? profileDetails?.bill_to_address_state : "No State"}</Typography>
                  )}
                </Grid>
              </Grid>
              <Grid container my={1} alignItems='center'>
                <Grid item md={4}>
                  <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>Postal Code</Typography>
                </Grid>
                <Grid item md={0.75}>
                  <Typography sx={{ fontSize: "14px" }}>:</Typography>
                </Grid>
                <Grid item md={7.25}>
                  {editDetails ? (
                    <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                      <TextField
                        size="small"
                        variant="outlined"
                        label="Postal Code"
                        type="number"
                        defaultValue={profileDetails?.bill_to_address_postal_code ? profileDetails?.bill_to_address_postal_code : "No Postal Code"}
                        error={errors?.postalCode?.message && (true)}
                        {...register("postalCode")}
                      />
                      {errors?.postalCode?.message && (
                        <Error message={errors?.postalCode?.message} />
                      )}
                    </FormControl>
                  ) : (
                    <Typography sx={{ fontSize: "14px", color: colors.textSecondary }}>{profileDetails?.bill_to_address_postal_code ? profileDetails?.bill_to_address_postal_code : "No Postal Code"}</Typography>
                  )}
                </Grid>
              </Grid>
            </Box>
            {editDetails &&
              <Grid item xs={12} md={12} sx={{ float: 'right' }}>
                <PrimaryButton loading={loading} type="submit">Update</PrimaryButton>
              </Grid>}
          </Typography>
        </Grid>
      </Grid>
    </Layout >
  )
}

export default UserProfile