import React, { Fragment, useEffect } from 'react'
import { Box } from '@mui/material'
import AOS from "aos";
import "aos/dist/aos.css";


// *Import Components
import Header from './Header';
import Footer from './Footer';
import GoToTop from '../../hooks/scrollTop';
import Toaster from '../../components/Toaster/Toaster';
// import Header from './../Header';

function Layout({ children }) {

  useEffect(() => {
    AOS.init({
      initClassName: 'aos-init',
      animatedClassName: 'aos-animate',
      duration: 900,
      easing: 'ease-in-sine',
      offset: 120,
    });
  }, []);

  return (
    <Fragment>

      {/* ========== Toaster Container ========== */}
      <Toaster />

      {/* ========== Header ========== */}
      <Header sx={{ width: '100%' }} />

      <Box component="main" sx={{ minHeight: "55vh" }}>
        {children}
      </Box>

      {/* ========== Footer ========== */}
      <Footer />

      {/* ========== Go TO TOP ========== */}
      <GoToTop />

    </Fragment>
  )
}

export default Layout