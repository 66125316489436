import React, { forwardRef, useState, useEffect } from 'react';
import { Box, Slide, DialogTitle, DialogContent, Dialog, IconButton, Typography } from '@mui/material';
import { Close } from '@material-ui/icons';
import { PrimaryButton } from '../../UI/Buttons';
import Images from './../../../assets/Images';
import colors from './../../../styles/colors';
import { Service } from './../../../config/service';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddArtDialog({ openArtDialog, setArtDialog }) {

  // Base Url
  const server = 'https://api-designtool.optech.pk';

  const [toShowSectionDialog, setToShowSectionDialog] = useState('ArtCategory')

  // const handleDIalogSection = (value) => {
  //   setToShowSectionDialog()
  // }

  const handleClose = () => {
    setArtDialog(false);
  };

  //* Get Art Category API
  const [artCategory, setArtCategory] = useState([])
  const getArtCategory = async () => {
    try {
      const { data } = await Service.getArtWorkCategory()
      console.log("🚀 ~ file: AddArtDialog.js ~ line 24 ~ getArtCategory ~ data", data)
      setArtCategory(data)
    } catch (error) {
      console.log("🚀 ~ file: AddArtDialog.js ~ line 27 ~ //getArtCategory ~ error", error)
    }
  }
  //* Get Art Sub Category API
  const [artSubCategory, setArtSubCategory] = useState([])
  console.log("🚀 ~ file: AddArtDialog.js ~ line 33 ~ AddArtDialog ~ artSubCategory", artSubCategory)
  const getArtSubCategory = async (id) => {
    try {
      const { data } = await Service.getArtWorkSubCatByCatID(id)
      console.log("🚀 ~ file: AddArtDialog.js ~ line 36 ~ getArtSubCategory ~ data", data)
      setArtSubCategory(data)
    } catch (error) {
      console.log("🚀 ~ file: AddArtDialog.js ~ line 39 ~ getArtSubCategory ~ error", error)
    }
  }
  //* Get Art By Sub Category ID API
  const [artBySubCategory, setArtBySubCategory] = useState([])
  console.log("🚀 ~ file: AddArtDialog.js ~ line 45 ~ AddArtDialog ~ artBySubCategory", artBySubCategory)
  const getArtBySubCategory = async (id) => {
    try {
      const { data } = await Service.getArtWorkBySubCatID(id)
      console.log("🚀 ~ file: AddArtDialog.js ~ line 49 ~ getArtBySubCategory ~ data", data)
      setArtBySubCategory(data)
    } catch (error) {
      console.log("🚀 ~ file: AddArtDialog.js ~ line 52 ~ getArtBySubCategory ~ error", error)
    }
  }

  useEffect(() => {
    getArtCategory()

  }, [])

  return (
    <Box>
      <Dialog
        fullWidth
        open={openArtDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle><IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton></DialogTitle>
        <DialogContent>
          <Box>
            {toShowSectionDialog === 'ArtCategory' &&
              <>
                {artCategory?.map((item, index) => (
                  <Box onClick={() => { getArtSubCategory(item.id); setToShowSectionDialog('ArtSubCategory') }} sx={{
                    width: '100%',
                    display: 'flex', alignItems: "center", p: 2, boxSizing: 'border-box', backgroundColor: colors.gunMetal, mt: 2, borderRadius: 2, "&:hover": {
                      boxShadow: `rgba(0, 0, 0, 0.24) 0px 3px 8px;`
                    }
                  }}>
                    <img style={{ width: '50px' }} src={server + item.image} alt="" />
                    <Typography sx={{ fontSize: 16, ml: 1, fontWeight: "bold" }}>{item.name}</Typography>
                  </Box>
                ))}
              </>}
            {toShowSectionDialog === 'ArtSubCategory' &&
              <>
                {artSubCategory?.map((item, index) => (
                  <Box onClick={() => { getArtBySubCategory(item.id); setToShowSectionDialog('ArtBySubCategory') }} sx={{
                    width: '100%',
                    display: 'flex', alignItems: "center", justifyContent: 'center', p: 2, boxSizing: 'border-box', backgroundColor: colors.gunMetal, mt: 2, borderRadius: 2, "&:hover": {
                      boxShadow: `rgba(0, 0, 0, 0.24) 0px 3px 8px;`
                    }
                  }}>
                    <Typography sx={{ fontSize: 16, ml: 1, fontWeight: "bold" }}>{item.name}</Typography>
                  </Box>
                ))}
              </>}
            {toShowSectionDialog === 'ArtBySubCategory' &&
              <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', my: 1 }}>
                {artBySubCategory?.map((item, index) => (
                  <Box sx={{
                    width: '88px', height: '88px', mx: 1,
                    boxSizing: 'border-box', border: `1px solid ${colors.black}`, borderRadius: 2, "&:hover": {
                      boxShadow: `rgba(0, 0, 0, 0.24) 0px 3px 8px;`, cursor: 'pointer'
                    }
                  }}>
                    <img style={{ width: '88px', height: '88px', objectFit: 'contain', borderRadius: 2, }} src={server + item.image} alt="" />
                  </Box>
                ))}
              </Box>}
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
