import Axios from '../axios';

import { errorHandler } from './errorHandler';

export const Apis = {
    login: 'auth/login',
    logout: '/auth/logout',
    register: 'auth/register',
    product: 'customer/get-products',
    productCategory: 'customer/get-product-categories',
    productSubCatByCatID: 'customer/get-product-subcat-by-catid',
    productCatWiseSubCat: 'customer/get-product-cat-wise-subcategory',
    productBySubCatID: 'customer/get-product-by-subcategory',
    artworkCategory: 'customer/get-artwork-category',
    artworkSubCatByCatID: 'customer/get-artwork-subcat-by-catid',
    artworkBySubCatID: 'customer/get-artwork',
    uploadArtWork: 'customer/upload-artwork',
    changeImageColor: 'customer/artwork-color-change',
    makeOneColor: 'customer/make-one-color',
    defaultToolImage: 'customer/get-default-images',
    colors: 'customer/get-colors',
    brand: 'customer/get-brands',
    fonts: 'customer/get-fonts',
    saveDesign: 'customer/saveDesign',
    orderDesignDetails: 'customer/saveOrder',
    profilePersonalInfo: 'customer/getProfilePersonalInfo',
    updateProfilePersonalInfo: 'customer/updateProfilePersonalInfo',
    getOrderDetails: 'customer/getCustomerOrder',
    getOrderDetailsInvoice: 'customer/getCustomerOrderDetailById',
    getProductDetailsByProdID: 'customer/get-productdetail-by-productid',
    getProductFilters: 'customer/get-filtered-products',
};

export const headers = {
    'content-type': 'application/json',
};

export const get = async (endPoint, token) => {
    try {
        const result = await Axios.get(endPoint);
        return result;
    } catch (e) {
        console.log('post -> e', e);
        throw errorHandler(e);
    }
};

export const post = async (endPoint, data, token) => {
    try {
        const result = await Axios.post(endPoint, data, { headers: { Authorization: `Bearer ${token}` } });
        return result;
    } catch (e) {
        throw errorHandler(e);
    }
};

export const put = async (endPoint, data, token) => {
    try {
        const result = await Axios.put(endPoint, data, { headers: { Authorization: `Bearer ${token}` } });
        return result;
    } catch (e) {
        throw errorHandler(e);
    }
};