import React, { Fragment, useState } from 'react'
import { Box, Typography, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Images from './../../assets/Images';
import { PrimaryButton } from './../UI/Buttons';
import colors from './../../styles/colors';
import AddTextDialog from './Shared/AddTextDialog';
import AddArtDialog from './Shared/AddArtDialog';
import AddImageDialog from './Shared/AddImageDialog';
import AddProductColorDialog from './Shared/AddProductColorDialog';
import TextSlidingPane from './SlidingPane/TextSlidingPane';

const useStyle = makeStyles({
  responsiveToolBarImage: {
    width: '45px',
    height: '25px',
    cursor: 'pointer',
  }
});

function MobileViewTemplate({ colors, maskColor, changeColor, addToDesign, handleTextFontSize, setTextValue, getImageData }) {
  const classes = useStyle();
  const [openTextDialog, setTextDialog] = useState(false);
  const [openArtDialog, setArtDialog] = useState(false);
  const [openImageDialog, setImageDialog] = useState(false);
  const [openProductColorDialog, setProductColorDialog] = useState(false);

  return (
    <Fragment>
      {/* <TextSlidingPane isPaneOpen={isPaneOpen} setIsPaneOpen={setIsPaneOpen} /> */}
      <Box style={{ padding: '6px', borderSizing: 'border-box', }} className="responsive_toolBar">
        <Box
          onClick={() => { setTextDialog(!openTextDialog) }}
          style={{ textAlign: 'center', width: '75px' }}>
          <img className={classes.responsiveToolBarImage} src={Images.addText} alt="" />
          <Typography >Add Text</Typography>
        </Box>
        <Box onClick={() => { setArtDialog(!openArtDialog) }} style={{ textAlign: 'center', width: '75px' }}>
          <img className={classes.responsiveToolBarImage} src={Images.addArt} alt="" />
          <Typography >Add Art</Typography>
        </Box>
        <Box onClick={() => { setImageDialog(!openImageDialog) }} style={{ textAlign: 'center', width: '75px' }}>
          <img className={classes.responsiveToolBarImage} src={Images.upload} alt="" />
          <Typography >Upload</Typography>
        </Box>
        <Box onClick={() => { setProductColorDialog(!openProductColorDialog) }} style={{ textAlign: 'center', width: '75px' }}>
          <img className={classes.responsiveToolBarImage} src={Images.garmentColor} alt="" />
          <Typography >Color</Typography>
        </Box>
      </Box>
      {openTextDialog &&
        <AddTextDialog openTextDialog={openTextDialog} setTextDialog={setTextDialog} addToDesign={addToDesign} handleTextFontSize={handleTextFontSize} setTextValue={setTextValue} />
      }
      {openArtDialog &&
        <AddArtDialog openArtDialog={openArtDialog} setArtDialog={setArtDialog} />
      }
      {openImageDialog &&
        <AddImageDialog openImageDialog={openImageDialog} setImageDialog={setImageDialog} getImageData={getImageData} />
      }
      {openProductColorDialog &&
        <AddProductColorDialog openProductColorDialog={openProductColorDialog} setProductColorDialog={setProductColorDialog} colors={colors} maskColor={maskColor} changeColor={changeColor} />
      }
    </Fragment>
  )
}

export default MobileViewTemplate