import React, { useState, useRef, useEffect } from 'react'
import { Grid, Box, Typography, FormControl, TextField, InputLabel, OutlinedInput, InputAdornment, IconButton } from '@mui/material';
import Images, { HandsDeliver, PlaceOrder, TrackOrder, AddSaveDesign } from '../../assets/Images';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import colors from './../../styles/colors';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { PrimaryButton } from './../../components/UI/Buttons';
import { Error } from './../../components/UI/Error';
import useAuth from '../../hooks/useAuth';
import Layout from '../../layout/Layout/Layout';
import { ErrorToaster, SuccessToaster } from '../../components/Toaster/Toaster';
import { Service } from './../../config/service';
import Swal from 'sweetalert2';

function Register() {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  // *Registration 
  const { register, handleSubmit, formState: { errors }, watch } = useForm();
  const password = useRef({});
  password.current = watch("password", "");
  // For API Call
  const registerInfo = async (formData) => {
    setLoading(true)
    try {
      let obj = {
        first_name: formData.first_name,
        last_name: formData.last_name,
        email: formData.email,
        password: formData.password,
        confirm_password: formData.confirm_password
      }
      const { status, data, responseCode, message } = await Service.register(obj)
      if (status === true && responseCode === 200) {
        setLoading(false)
        SuccessToaster("Register Successfully")
        login(data.token, data.userName, obj.email)
      }
      else {
        Swal.fire({
          text: message,
          icon: 'error',
          confirmButtonColor: colors.primary,
        })
      }

    } catch (error) {
      ErrorToaster("Something went wrong.")
      console.log("🚀 ~ file: Register.js ~ line 52 ~ registerInfo ~ error", error)
    } finally {
      setLoading(false)
    }
  };
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword)
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [])
  return (
    <Layout>
      <Grid container columnSpacing={4} justifyContent="center" alignItems="center" sx={{ py: 6, px: 2 }} >
        <Grid item xs={12} md={5}>
          <Box sx={{ borderRadius: "6px", textAlign: "center", boxShadow: `rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;`, p: 4 }} >
            <img src={Images.logo} width="25%" alt="logo" />
            <Typography sx={{ fontWeight: "bold", fontSize: "22px", color: colors.black }}>Register Your Account</Typography>
            <Typography sx={{ fontSize: "12px", color: colors.textSecondary }}>Create your account to access your saved designs, track your orders, create & manage your stores, and place a reorder!</Typography>
            <Typography component={"form"} onSubmit={handleSubmit(registerInfo)}>
              <Grid container>
                {/* First Name */}
                <Grid item xs={12} md={12}>
                  <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                    <TextField
                      variant="outlined"
                      label="First name"
                      type="text"
                      error={errors?.first_name?.message && (true)}
                      {...register("first_name", {
                        required: 'Please enter your First name.',
                      })}
                    />
                    {errors?.first_name?.message && (
                      <Error message={errors?.first_name?.message} />
                    )}
                  </FormControl>
                </Grid>
                {/* Last Name */}
                <Grid item xs={12} md={12}>
                  <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                    <TextField
                      variant="outlined"
                      label="Last name"
                      type="text"
                      error={errors?.last_name?.message && (true)}
                      {...register("last_name", {
                        required: 'Please enter your Last name.',
                      })}
                    />
                    {errors?.last_name?.message && (
                      <Error message={errors?.last_name?.message} />
                    )}
                  </FormControl>
                </Grid>
                {/* Email */}
                <Grid item xs={12} md={12}>
                  <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                    <TextField
                      variant="outlined"
                      label="Email"
                      type="email"
                      error={errors?.email?.message && (true)}
                      {...register("email", {
                        required: 'Please enter your email.',
                      })}
                    />
                    {errors?.email?.message && (
                      <Error message={errors?.email?.message} />
                    )}
                  </FormControl>
                </Grid>
                {/* Password */}
                <Grid item xs={12} md={12}>
                  <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                    <InputLabel error={errors?.password?.message && (true)}>Password</InputLabel>
                    <OutlinedInput
                      label="Password"
                      type={showPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => { handleClickShowPassword() }}
                            edge="end"
                            sx={{ color: colors.primary }}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      error={errors?.password?.message && (true)}
                      {...register("password", {
                        required: 'Please create your password.',
                      })}
                    />
                    {errors?.password?.message && (
                      <Error message={errors?.password?.message} />
                    )}
                  </FormControl>
                </Grid>
                {/* Confirm Password */}
                <Grid item xs={12} md={12}>
                  <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                    <InputLabel error={errors?.confirm_password?.message && (true)}>Confirm Password</InputLabel>
                    <OutlinedInput
                      label="Confirm Password"
                      type={showConfirmPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => { handleClickShowConfirmPassword() }}
                            edge="end"
                            sx={{ color: colors.primary }}
                          >
                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      error={errors?.confirm_password?.message && (true)}
                      {...register("confirm_password", {
                        required: 'Please re-type your password.',
                        validate: value =>
                          value === password.current || "Confirm password does not match"
                      })}
                    />
                    {errors?.confirm_password?.message && (
                      <Error message={errors?.confirm_password?.message} />
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Typography
                onClick={() => { navigate('/login') }}
                sx={{
                  color: colors.textSecondary, my: 1,
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
              >
                Already have an account ? <span style={{ color: colors.primary }}>Log in</span>
              </Typography>
              <PrimaryButton
                fullWidth
                variant="contained"
                type="submit"
                loading={loading}
                sx={{
                  p: 1,
                  my: 1,
                  width: '100%'

                }}
              >
                Register
              </PrimaryButton>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4} sx={{ mt: { xs: 2 } }}>
          <Typography sx={{ color: colors.black, fontSize: "18px" }}>Did you know?</Typography>
          <Typography sx={{ color: colors.textSecondary, fontSize: "12px" }}>Inside your account you can:</Typography>
          <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
            <Box sx={{ width: "10%" }}>
              <HandsDeliver />
            </Box>
            <Typography sx={{ fontSize: "16px", ml: 2 }}>Hands Delivery</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
            <Box sx={{ width: "10%" }}>
              <PlaceOrder />
            </Box>
            <Typography sx={{ fontSize: "16px", ml: 2 }}>Place A Reorder</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
            <Box sx={{ width: "10%" }}>
              <TrackOrder />
            </Box>
            <Typography sx={{ fontSize: "16px", ml: 2 }}>Track an Existing Order</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
            <Box sx={{ width: "10%" }}>
              <AddSaveDesign />
            </Box>
            <Typography sx={{ fontSize: "16px", ml: 2 }}>Share Your Designs</Typography>
          </Box>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default Register