import React, { useState } from "react";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { Box, Typography, Divider, CardMedia, Button, Grid, Slider, Input, ButtonGroup, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Close, KeyboardArrowRight, VerticalAlignCenter } from "@mui/icons-material";
import Images from './../../../assets/Images';
import colors from './../../../styles/colors';

const useStyle = makeStyles({
  textSlidePane: {
    height: '95%',
    marginTop: '15%',
    '.slide-pane__header': {
      height: '40px !important',
    },
    '.ReactModalPortal': {
      display: { md: 'none !important' }
    }


  },


});

function TextSlidingPane({ isPaneOpen, setIsPaneOpen, frontTextRotate, backTextRotate, setTextRotateValue, blurRotate, register, valueChangeOfText, textAlignCenter,
  textLayer, productAngle, }) {

  const classes = useStyle();

  return (
    <Box sx={{ display: { xs: 'block', md: 'none' } }} >
      <SlidingPane
        className={classes.textSlidePane}
        // hideHeader
        overlayClassName={classes.textSlidePane}
        isOpen={isPaneOpen}
        from="bottom"
        width="100%"
        title="Edit Text"
        onRequestClose={() => {
          setIsPaneOpen(false);
        }}
      >
        <Box >
          {/* Font Box */}
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Typography>Font</Typography>
            <Box sx={{ display: "flex", alignItems: "center", cursor: 'pointer' }}>
              <Typography>Arial</Typography>
              <KeyboardArrowRight />
            </Box>
          </Box>
          <Divider sx={{ width: '100%', my: 1 }} />
          {/* Color Box */}
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Typography>Text Color</Typography>
            <Box sx={{ display: "flex", alignItems: "center", cursor: 'pointer' }}>
              <Box sx={{ backgroundColor: colors.black, width: '20px', height: '20px', borderRadius: '4px' }} />
              <KeyboardArrowRight />
            </Box>
          </Box>
          <Divider sx={{ width: '100%', my: 1 }} />
          {/* Outline Box */}
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Typography>Text Outline</Typography>
            <Box sx={{ display: "flex", alignItems: "center", cursor: 'pointer' }}>
              <Box sx={{ backgroundColor: colors.textSecondary, width: '20px', height: '20px', borderRadius: '4px' }} />
              <KeyboardArrowRight />
            </Box>
          </Box>
          <Divider sx={{ width: '100%', my: 1 }} />
          {/* ========== Font Rotation ========== */}
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} >
            <Box>
              <Typography variant="body1" component="h6">
                Rotation
              </Typography>
            </Box>
            <Slider
              sx={{ mx: 1 }}
              value={
                productAngle === "front"
                  ? frontTextRotate
                  : backTextRotate
              }
              onChange={setTextRotateValue}
              valueLabelDisplay="auto"
              min={-180}
              max={180}
            />
            <Input
              value={
                productAngle === "front"
                  ? frontTextRotate
                  : backTextRotate
              }
              onChange={(e) =>
                setTextRotateValue(e, e.target.value)
              }
              onBlur={blurRotate}
              inputProps={{
                step: 1,
                min: -180,
                max: 180,
                type: "number",
              }}
            />
          </Box>
          <Divider sx={{ width: '100%', my: 1 }} />
          {/* ========== Font Size ========== */}
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Box>
              <Typography variant="body1" component="h6">
                Text Size
              </Typography>
            </Box>
            <TextField
              size="small"
              sx={{ width: '55px' }}
              variant="outlined"
              type="number"
              {...register("text_value_size")}
              onChange={(e) => {
                valueChangeOfText(e.target.value);
              }}
            />
          </Box>
          <Divider sx={{ width: '100%', my: 1 }} />
          {/* ========== Font Alignment & layering ========== */}
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Box sx={{ textAlign: 'center' }} >
              <Button
                sx={{ border: '1px solid ', }}
                className="center-btn"
                onClick={() => textAlignCenter()}
              >
                <VerticalAlignCenter className="center-icon" />
              </Button>
              <Typography variant="caption">Center</Typography>
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <ButtonGroup>
                <Button onClick={() => textLayer(true)}>
                  <CardMedia
                    component="img"
                    height="25"
                    image={Images.topLayer}
                  />
                </Button>
                <Button onClick={() => textLayer(false)}>
                  <CardMedia
                    component="img"
                    height="25"
                    image={Images.bottomLayer}
                  />
                </Button>
              </ButtonGroup>
              <Typography variant="caption">Layering</Typography>
            </Box>
          </Box>
        </Box>
      </SlidingPane>

    </Box>
  );
};

export default TextSlidingPane;