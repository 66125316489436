import { FormatQuote } from '@mui/icons-material';
import { Box, Container, Grid, Typography, Divider } from '@mui/material'
import React from 'react'
import Images, { WhoWeAreBanner } from '../../assets/Images'
import Layout from '../../layout/Layout/Layout'
import colors from './../../styles/colors';

function WhoWeAre() {
  return (
    <Layout>
      {/* ========== Service Banner ========== */}
      <Box sx={{
        background: `url(${Images.whoWeAre})no-repeat top center / cover`,
        minHeight: "70vh",
        px: 5,
        my: 4,
        py: { xs: 3, md: 0 },
        color: "white",
        display: "flex",
      }}>
        <Grid container justifyContent={"center"} spacing={8}>
          <Grid item xs={12} md={5} mt={6}>
            <Box textAlign="center">
              <Typography sx={{ fontSize: "58px", fontWeight: "bold", color: colors.primary }}>Who We Are</Typography>
            </Box>
          </Grid>
        </Grid>

      </Box>
      <Container>
        <Box sx={{ my: 4, mx: 4 }}>
          <Grid container alignItems="center" justifyContent={'space-between'}>
            <Grid item xs={12} md={7}>
              <Typography sx={{ fontSize: "20px", fontWeight: "bold", color: colors.secondary }}>How we Works?</Typography>
              <Divider sx={{ backgroundColor: colors.primary, py: 0.2, width: "50px" }} />
              <Typography sx={{ fontSize: "14px", color: colors.textSecondary, my: 2 }}>Trade Time works with business and organizations to help achieve their
                strategic goals through effective branding and marketing activities. The
                products and service we provide helps our customers carry out successful
                promotions, building strong customer relationships and grow their
                business.</Typography>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box sx={{ width: '100%' }}>
                <img src={Images.whoWeAreBannerOne} width="100%" alt="Who we are" />
              </Box>
            </Grid>
            <Grid item xs={12} md={12} sx={{ bgcolor: colors.primary, borderRadius: "10px", my: 4, position: "relative" }}>
              <Box sx={{ textAlign: "center", py: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Typography sx={{ fontSize: "26px", fontWeight: "bold", color: colors.white }}>Our Vision</Typography>
                <Divider sx={{ backgroundColor: colors.secondary, py: 0.2, width: "50px" }} />
              </Box>
              <Typography sx={{ fontSize: "14px", color: colors.white, pb: 3, px: 3 }}>We view our customers as our partners. The success of our customer
                promotions and helping them achieve their brand objectives ensure our
                business success as well. We don
                't just sell products. We get to know our
                customers and provide them with solutions that achieve results. Trade
                Time has customers of all sizes, across most industries and in all corners of
                the world and including a number of global companies. The products and
                service we provide have helped us develop strong relationships with many
                now trusting us as their single source of supply for branded merchandises
                and promotional products</Typography>
              <FormatQuote sx={{ position: "absolute", top: { xs: 10, md: 20 }, right: { xs: 10, md: 15 }, fontSize: { xs: "35px", md: "65px" }, color: colors.secondary }} />
            </Grid>
            <Grid item xs={12} md={5}>
              <Box sx={{ width: '100%' }}>
                <img src={Images.whoWeAreBannerTwo} width="100%" alt="Who we are" />
              </Box>
            </Grid>
            <Grid item xs={12} md={7}>
              <Typography sx={{ fontSize: "20px", fontWeight: "bold", color: colors.secondary }}>Our Success</Typography>
              <Divider sx={{ backgroundColor: colors.primary, py: 0.2, width: "50px" }} />
              <Typography sx={{ fontSize: "14px", color: colors.textSecondary, my: 2 }}>We view our customers as our partners. The success of our customer
                promotions and helping them achieve their brand objectives ensure our
                business success as well. We don
                't just sell products. We get to know our
                customers and provide them with solutions that achieve results. Trade
                Time has customers of all sizes, across most industries and in all corners of
                the world and including a number of global companies. The products and
                service we provide have helped us develop strong relationships with many
                now trusting us as their single source of supply for branded merchandises
                and promotional products</Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Layout >
  )
}

export default WhoWeAre