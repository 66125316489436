import React, { useState, useEffect } from 'react'
import { Add } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography, Divider } from '@mui/material'
import { Service } from '../../../config/service';
import colors from '../../../styles/colors';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { setSubCategoryDetails, selectSubCategory } from '../../../features/counter/counterSlice';


//  Filter Naming List
// const products = ['T-Shirts', 'Sweatshirts & Hoodies', 'Polo Shirts', 'Caps', 'Active wear', 'Team Uniforms & Jerseys', 'Kids']
// const subProducts = ['Short Sleeve T-Shirts', 'Long Sleeve T-Shirts', 'Soft & Fitted T-Shirts', 'Performance Shirts',
//   'Women`s T-Shirts', 'Kids T-Shirts', 'V-Neck T-Shirts']


function ProductsTypes() {
  // Using Redux
  const subCategoryValue = useSelector(selectSubCategory)
  const dispatch = useDispatch();
  const value = useParams();
  const prod_id = value.category_id
  const navigate = useNavigate();
  // For Api Call
  const [productDetails, setProductDetails] = useState();
  const [subCategory, setSubCategory] = useState();

  //* Get Category Details API
  const getProductCategory = async () => {
    try {
      const { data } = await Service.getProductCategory()
      // console.log("🚀 ~ file: ProductsTypes.js ~ line 40 ~ getProductCategory ~ data", data)
      setProductDetails(data)
    } catch (error) {
      console.log("🚀 ~ file: ProductsTypes.js ~ line 43 ~ getProductCategory ~ error", error)
    }
  }

  const handleSubCategoryData = (data, name) => {
    let obj = {
      [name]: data
    }
    dispatch(setSubCategoryDetails(obj))
    console.log("🚀 ~ file: ProductsTypes.js ~ line 54 ~ handleSubCategoryData ~ obj", obj)
  }


  //* Get Sub Category Details API
  const getSubCategoryDetails = async (id, name) => {
    // console.log("🚀 ~ file: ProductsTypes.js ~ line 22 ~ getSubCategoryDetails ~ id", id)
    try {
      const { data } = await Service.getProductSubCatByCatID(id)
      // console.log("🚀 ~ file: ProductsTypes.js ~ line 25 ~ getSubCategoryDetails ~ data", data)
      setSubCategory(data);
      handleSubCategoryData(data, name) /// add into redux
    } catch (error) {
      console.log("🚀 ~ file: ProductsTypes.js ~ line 28 ~ getSubCategoryDetails ~ error", error)
    }
  }


  useEffect(() => {
    getProductCategory();
    getSubCategoryDetails(prod_id);
  }, [])

  return (
    <Box sx={{ p: 1.5 }}>
      <Typography onClick={() => navigate('/category')} sx={{ fontWeight: 500, fontSize: 17, ml: 2, cursor: 'pointer' }}>All Products</Typography>
      <Divider sx={{ bgColor: colors.cloud, width: '100%', my: 1 }} />
      {productDetails?.map((item, index) => (
        <Accordion defaultExpanded={+item.id === +prod_id ? true : false} key={index} elevation={0} sx={{ color: +item.id === +prod_id ? colors.primary : colors.black }} >
          <AccordionSummary expandIcon={<Add sx={{ color: colors.primary }} />} onClick={() => getSubCategoryDetails(item.id, item.name)}>
            <Typography sx={{ fontWeight: 500, fontSize: "15px" }}>{item.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {subCategory?.map((subItem, index) => (
              <Box>
                {+subItem.head_id === item.id &&
                  <Box key={index} sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                    <Typography sx={{ fontSize: 12, color: colors.textSecondary, my: 1 }}>{subItem.name}</Typography>
                  </Box>
                }</Box>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  )
}

export default ProductsTypes