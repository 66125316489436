import React, { Fragment } from 'react'
import { Link } from 'react-router-dom';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { Email, Facebook, HeadsetMic, LinkedIn, Pinterest, QuestionAnswer, Twitter } from '@mui/icons-material';

import { makeStyles } from "@mui/styles";
import colors from '../../styles/colors'
import { CurrentYear } from '../../utils';
import Images from './../../assets/Images';
import { FaFacebookF, FaLinkedinIn, FaPinterestP } from 'react-icons/fa';
import { BsTwitter } from 'react-icons/bs';

const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
    color: colors.textSecondary,
    my: 0.5,
    display: 'block',
    cursor: 'pointer',
    '&:hover': {
      color: colors.secondary,
      transition: '0.3s ease-in-out',
    },
  },
  navItems: {
    textDecoration: 'none',
    marginLeft: "10px",
    color: colors.black,
    padding: "10px",
    borderRadius: "10px",
    "&:hover": {
      color: colors.black,
    }
  },
  socialIcons: {
    fontSize: "20px",
    color: colors.black,
    height: "40px",
    width: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `2px solid ${colors.black}`,
    borderRadius: "40px",
    margin: 8,
    "&:hover": {
      color: colors.primary,
    }
  },
  twitter: {
    textDecoration: 'none',
    color: colors.black,
    margin: "0px 10px",
    "&:hover": {
      color: "#00acee",
    }
  },
  linkedin: {
    textDecoration: 'none',
    color: colors.black,
    margin: "0px 10px",
    "&:hover": {
      color: "#0e76a8",
    }
  },
  pinterest: {
    textDecoration: 'none',
    color: colors.black,
    margin: "0px 10px",
    "&:hover": {
      color: "#c8232c",
    }
  },
});

function Footer() {

  const classes = useStyles();

  return (
    <Fragment>
      <Grid container spacing={2} sx={{ backgroundColor: colors.mercury, py: 2 }} >
        <Grid item xs={12} md={12}
        >
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ textAlign: 'center', width: { xs: '38%', md: "14%" } }}>
              <img src={Images.logo} width="100%" alt="Trade Time" />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ textAlign: "center", borderRight: `1px solid ${colors.cloud}`, py: 2 }}>
            <Typography sx={{ fontWeight: 500, fontSize: "18px", color: colors.black }}>About Us</Typography>
            <Typography variant="body2" sx={{ color: colors.textSecondary, mx: 4 }} >Trade Time General Trading LLC is one of the leading trader & supplier of gold, general supplies, linen & bedsheets, stationary items, corporate gifts and clothes & wearables. We are a team of professional & experienced work force committed towards exceeding our customer expectations.</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ textAlign: "center", borderRight: `1px solid ${colors.cloud}`, py: 2, }}>
            <Typography variant="body1" sx={{ color: colors.black, fontWeight: 600 }}>TOP CATEGORIES</Typography>
            <Box >
              <Link to={"/"} className={classes.link} style={{ marginLeft: 4, marginRight: 4 }} >
                T-Shirts
              </Link>
              <Link to={"/"} className={classes.link} style={{ marginLeft: 4, marginRight: 4 }} >
                Polos
              </Link>
              <Link to={"/"} className={classes.link} style={{ marginLeft: 4, marginRight: 4 }} >
                Bags
              </Link>
              <Link to={"/"} className={classes.link} style={{ marginLeft: 4, marginRight: 4 }} >
                Caps
              </Link>
              <Link to={"/"} className={classes.link} style={{ marginLeft: 4, marginRight: 4 }} >
                Promotional Products
              </Link>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ textAlign: "center", borderRight: `1px solid ${colors.cloud}`, py: 2 }}>
            <Typography sx={{ fontWeight: 500, fontSize: "18px", color: colors.black }}>Contact Us</Typography>
            <Typography variant="body2" sx={{ color: colors.textSecondary, mx: 2, mb: 2 }}>Office# 104 First Floor Kaltham Building
              Al Dhagaya – Gold Souq Deira Dubai – UAE</Typography>
            <Box sx={{ textAlign: "left", mx: 3.5 }}>
              <Typography variant="body2" sx={{ color: colors.black }}><span style={{ fontWeight: 600, color: colors.primary }}>Landline:</span> +971-4-2202230</Typography>
              <Typography variant="body2" sx={{ color: colors.black }}><span style={{ fontWeight: 600, color: colors.primary }}>Email Address:</span> info@tradetimegt.com</Typography>
              <Typography variant="body2" sx={{ color: colors.black }}><span style={{ fontWeight: 600, color: colors.primary }}>Mobile:</span> +971-52-821-7664 / +971-52-946-5755</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={12} my={2}>
          <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
            <Link to={"/"} className={classes.socialIcons}  >
              <FaFacebookF />
            </Link>
            <Link to={"/"} className={classes.socialIcons}  >
              <BsTwitter />
            </Link>
            <Link to={"/"} className={classes.socialIcons}  >
              <FaLinkedinIn />
            </Link>
            <Link to={"/"} className={classes.socialIcons}  >
              <FaPinterestP />
            </Link>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="body2" color={colors.black} sx={{ display: 'flex', justifyContent: "center", py: 1 }} >
              © <CurrentYear /> All Rights Reserved. Developed by
              <Link to="https://www.mangotechsolutions.com/" target="_blank" className={classes.link} style={{ color: colors.primary, marginLeft: 6 }} > Mangotech Solutions</Link>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Fragment >
  );
}

export default Footer;