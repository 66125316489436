import React, { Fragment, useState, useEffect } from 'react'
import { Typography, Box, Grid, Divider, Radio } from '@mui/material';
import { useSelector, useDispatch } from "react-redux";
import { ArrowForward } from '@material-ui/icons';
// * Imported Components
import Images, { AddSaveDesign, HandsDeliver } from './../../assets/Images';
import { PrimaryButton, SecondaryButton } from '../../components/UI/Buttons';
import { useNavigate } from 'react-router-dom';
import Layout from '../../layout/Layout/Layout';
import { selectTotalAddToCart, setDeliveryDays, setDeliveryFeeToPay, setTotalAddToCartItemsAfterRemove, setTotalAmountToPay, setTotalItems, selectDeliveryDetails } from '../../features/counter/counterSlice';
import colors from '../../styles/colors';

function Cart() {
  // Using Redux
  const dispatch = useDispatch();
  const totalAddToCart = useSelector(selectTotalAddToCart);
  const { deliveryFeeToPay } = useSelector(selectDeliveryDetails);
  const navigate = useNavigate();
  const [selectedDeliveryValue, setSelectedDeliveryValue] = useState('9-12');
  const [deliveryShippingFee, setDeliveryShippingFee] = useState('0');
  const [selectedDeliveryTypeIndex, setSelectedDeliveryTypeIndex] = useState(0)
  const [totalItemState, setTotalItemState] = useState(0);
  const [subTotalItemPrice, setSubTotalItemsPrice] = useState(0);

  const handleChangeRadioDelivery = (deliveryTypeValue, deliveryFeeValue) => {
    setSelectedDeliveryValue(deliveryTypeValue);
    setDeliveryShippingFee(deliveryFeeValue);
    dispatch(setDeliveryFeeToPay(deliveryFeeValue));
    // dispatch(setDeliveryDays(deliveryOptions[Object.keys(deliveryOptions)[selectedDeliveryTypeIndex]][0]));
  };

  const [showMoreOption, setShowMoreOption] = useState(false)
  const handleOptions = () => {
    setShowMoreOption(!showMoreOption)
  }

  const deliveryOptions = {
    options1: ['Free Delivery Oct 4 - Oct 7', '9-12', '0.00'], options2: ['October 3, Mon', '8', '2.30'], options3: ['September 30, Fri', '7', '2.88'],
    options4: ['September 29, Thu', '6', '3.6'], options5: ['September 28, Wed', '5', '4.17'], options6: ['September 27, Tue', '4', '4.75'],
  }

  const handleRemoveFromCart = (index) => {
    const dummyAddToCart = [...totalAddToCart]
    dummyAddToCart.splice(index, 1)
    dispatch(setTotalAddToCartItemsAfterRemove(dummyAddToCart));
    handleSubTotalAndTotalPriceAfterRemoving(index)
  }

  const handleSubTotalAndTotalPrice = () => {
    let totalAmountItemPrice = 0
    let totalSubItems = 0
    totalAddToCart.map((item, index) => {
      totalAmountItemPrice = totalAmountItemPrice + totalAddToCart[index].totalPrice
      totalSubItems = totalSubItems + totalAddToCart[index].totalItem
    })
    setSubTotalItemsPrice(totalAmountItemPrice)
    setTotalItemState(totalSubItems)
    dispatch(setTotalItems(totalSubItems));
  }

  const handleSubTotalAndTotalPriceAfterRemoving = (index) => {
    const totalAmountItemPrice = subTotalItemPrice - totalAddToCart[index].totalPrice
    const totalSubItems = totalItemState - totalAddToCart[index].totalItem
    setSubTotalItemsPrice(totalAmountItemPrice)
    setTotalItemState(totalSubItems)
    dispatch(setTotalItems(totalSubItems));
  }

  const handleDeliveryFeeStateSaved = (val) => {
    var count = 0
    for (const key in deliveryOptions) {
      if (deliveryOptions[key][2] == val) {
        setSelectedDeliveryTypeIndex(count);
        setSelectedDeliveryValue(deliveryOptions[key][1]);
        handleChangeRadioDelivery(deliveryOptions[key][1], deliveryOptions[Object.keys(deliveryOptions)[count]][2])
      }
      else {
        count++
      }
    }

  }

  useEffect(() => {
    if (deliveryFeeToPay) {
      handleDeliveryFeeStateSaved(deliveryFeeToPay);
    }
    handleSubTotalAndTotalPrice()
  }, [totalAddToCart])



  return (
    <Layout>
      {totalAddToCart.length > 0 ? (
        <Box sx={{ backgroundColor: colors.whiteSpace, py: 2, px: 4, height: "100%" }}>
          <Typography sx={{ textAlign: "center", fontSize: "32px", fontWeight: "bold", mb: 2 }}>Shopping Cart</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Box sx={{ border: `1px solid #e5e7ea`, p: 2, display: "flex", alignItems: "center", justifyContent: "space-between", backgroundColor: colors.white }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ width: "30px", mr: 3 }}>
                    <HandsDeliver />
                  </Box>
                  <Box>
                    <Typography sx={{ fontSize: '14px', fontWeight: 700, lineHeight: 1.2, }}>We Review Everything!</Typography>
                    <Typography sx={{ color: colors.textSecondary, fontSize: "12px" }}>We double check every order for accuracy.
                      <span style={{ color: colors.primary, cursor: "pointer" }}>Add Notes or Special Instructions</span></Typography>
                  </Box>
                </Box>
                <Typography onClick={() => { dispatch(setTotalAddToCartItemsAfterRemove([])); }} sx={{ fontSize: "12px", color: colors.primary, cursor: "pointer" }}>Clear Cart</Typography>
              </Box>
              {/* Add Cart Design */}
              {[...Array(Number(totalAddToCart.length))].map((item, index) => (
                <Box sx={{ border: `1px solid #e5e7ea`, py: 4, px: 2, alignItems: "center", backgroundColor: colors.white, my: 2 }}>
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Typography sx={{ fontWeight: "bold" }}>{totalAddToCart[index].designName}</Typography>
                    <Typography sx={{ fontSize: "12px" }}>
                      <span style={{ color: colors.primary, cursor: "pointer" }}>Edit Design </span>/
                      <span onClick={() => { handleRemoveFromCart(index) }} style={{ color: colors.primary, cursor: "pointer" }}> Remove Design</span>
                    </Typography>
                  </Box>
                  <Divider sx={{ my: 1.5 }} />
                  <Box>
                    <Typography sx={{ fontSize: "12px" }}>
                      <span style={{ fontWeight: "bold", color: colors.textSecondary }}>PRODUCT: </span>
                      RT2000 by Design Tool Classic Tee
                    </Typography>
                    <Box sx={{ display: "flex", justifyContent: "space-between", my: 2 }}>
                      <Box>
                        <img src={Images.cartProductImage} style={{ width: "100px", marginRight: "8px" }} alt="Product Front" />
                        <img src={Images.cartProductImageBack} width="100px" alt="Product Back" />
                      </Box>
                      <Box sx={{ ml: 3, flexGrow: 1 }}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography sx={{ fontWeight: "bold", mr: 1, color: colors.textSecondary }}>
                            Color:
                          </Typography>
                          <Typography sx={{ backgroundColor: colors.white, width: '18px', height: '18px', borderRadius: "4px", border: `1px solid black` }}></Typography>
                          <Typography sx={{ ml: 1, fontSize: "13px" }}>White</Typography>
                        </Box>
                        <Box>
                          {/* <Typography sx={{ fontWeight: "bold", color: colors.textSecondary }}>
                            Size:
                          </Typography> */}
                          {/* Adult Size */}
                          {totalAddToCart[index].adultSize &&
                            <Box sx={{ display: "flex", my: 1, alignItems: "center" }}>
                              <Typography sx={{ fontWeight: "bold", mr: 1, color: colors.textSecondary, fontSize: "18px" }}>
                                Size:
                              </Typography>
                              {[...Array(Number(Object.keys(totalAddToCart[index].adultSize).length))].map((item, index2) => (
                                totalAddToCart[index].adultSize[Object.keys(totalAddToCart[index].adultSize)[index2]] !== 0 &&
                                <Box key={index2}>
                                  <Typography sx={{ border: `1px solid ${colors.textSecondary}`, px: 0.5, boxSizing: 'border-box', color: colors.textSecondary, fontSize: "13px", mr: 0.5 }}>
                                    {Object.keys(totalAddToCart[index].adultSize)[index2]} - {totalAddToCart[index].adultSize[Object.keys(totalAddToCart[index].adultSize)[index2]]}</Typography>
                                </Box>
                              ))}
                            </Box>
                          }
                          {/* Youth Size */}
                          {/* {totalAddToCart[index].youthSize &&
                            <Box sx={{ display: "flex", mb: 1, alignItems: "center" }}>
                              <Typography sx={{ fontWeight: "bold", mr: 1, color: colors.textSecondary, fontSize: "13px" }}>
                                Youth Size:
                              </Typography>
                              {[...Array(Number(Object.keys(totalAddToCart[index].youthSize).length))].map((item, index3) => (
                                totalAddToCart[index].youthSize[Object.keys(totalAddToCart[index].youthSize)[index3]] !== 0 &&
                                <Box key={index3}>
                                  <Typography sx={{ border: `1px solid ${colors.textSecondary}`, px: 0.5, boxSizing: 'border-box', color: colors.textSecondary, fontSize: "13px", mr: 0.5 }}>
                                    {Object.keys(totalAddToCart[index].youthSize)[index3]} - {totalAddToCart[index].youthSize[Object.keys(totalAddToCart[index].youthSize)[index3]]}</Typography>
                                </Box>
                              ))}
                            </Box>
                          } */}
                          <Typography sx={{ color: colors.primary, fontSize: "13px" }}>Edit Sizes</Typography>
                        </Box>
                      </Box>
                      <Box sx={{ alignSelf: 'flex-end' }}>
                        <Typography sx={{ color: colors.textSecondary, fontSize: "12px" }}>{totalAddToCart[index].totalItem} items</Typography>
                        <Typography sx={{ color: colors.textSecondary, fontSize: "12px" }}>${totalAddToCart[index].eachItemPrice} each</Typography>
                        <Typography sx={{ fontWeight: "bold", color: colors.black, fontSize: "14px" }}>${totalAddToCart[index].totalPrice}</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ))}
              {/* Add Save Design */}
              <Box sx={{ border: `1px solid #e5e7ea`, p: 2, display: "flex", alignItems: "center", backgroundColor: colors.white, my: 2 }}>
                <Box sx={{ width: "35px", mr: 2 }}>
                  <AddSaveDesign />
                </Box>
                <Box>
                  <Typography sx={{ fontSize: "14px", fontWeight: "bold", color: colors.primary }}>Add A Saved Design</Typography>
                  <Typography sx={{ fontSize: "12px", color: colors.textSecondary }}>1 additional designs for rushan.mangotech@gmail.com</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ border: `1px solid #e5e7ea`, p: 2, alignItems: "center", backgroundColor: colors.white }}>
                <Typography sx={{ fontWeight: "bold", fontSize: "18px", color: colors.black }}>Delivery Option</Typography>
                <Divider sx={{ my: 2 }} />
                {deliveryShippingFee === '0' ? (
                  <Box sx={{ display: "flex", alignItems: 'flex-start', cursor: "pointer" }} onClick={() => { handleChangeRadioDelivery(deliveryOptions[Object.keys(deliveryOptions)[0]][1], deliveryOptions[Object.keys(deliveryOptions)[0]][2]) }}>
                    <Radio
                      checked={selectedDeliveryValue === deliveryOptions[Object.keys(deliveryOptions)[0]][1]}
                      value={deliveryOptions[Object.keys(deliveryOptions)[0]][1]}
                      onChange={(e) => { handleChangeRadioDelivery(e.target.value, deliveryOptions[Object.keys(deliveryOptions)[0]][2]) }}
                      name="radio-buttons"
                      size='small'
                      sx={{
                        color: colors.primary,
                        '&.Mui-checked': {
                          color: colors.primary,
                        },
                      }}
                    />
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography sx={{ fontSize: "13px", fontWeight: "bold", color: colors.black, mt: 1 }}>{deliveryOptions[Object.keys(deliveryOptions)[0]][0]}</Typography>
                      <Typography sx={{ fontSize: "12px", fontWeight: "bold", color: colors.textSecondary }}><span>{deliveryOptions[Object.keys(deliveryOptions)[0]][1]}</span> Business Days</Typography>
                    </Box>
                    <Typography sx={{ fontSize: "12px" }}>+<span>{deliveryOptions[Object.keys(deliveryOptions)[0]][2]}</span> each</Typography>
                  </Box>
                ) : (
                  <Box sx={{ display: "flex", alignItems: 'flex-start', cursor: "pointer" }} onClick={() => { handleChangeRadioDelivery(deliveryOptions[Object.keys(deliveryOptions)[selectedDeliveryTypeIndex]][1], deliveryOptions[Object.keys(deliveryOptions)[selectedDeliveryTypeIndex]][2]) }}>
                    <Radio
                      checked={selectedDeliveryValue === deliveryOptions[Object.keys(deliveryOptions)[selectedDeliveryTypeIndex]][1]}
                      value={deliveryOptions[Object.keys(deliveryOptions)[selectedDeliveryTypeIndex]][1]}
                      onChange={(e) => { handleChangeRadioDelivery(e.target.value, deliveryOptions[Object.keys(deliveryOptions)[selectedDeliveryTypeIndex]][2]) }}
                      name="radio-buttons"
                      size='small'
                      sx={{
                        color: colors.primary,
                        '&.Mui-checked': {
                          color: colors.primary,
                        },
                      }}
                    />
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography sx={{ fontSize: "13px", fontWeight: "bold", color: colors.black, mt: 1 }}>{deliveryOptions[Object.keys(deliveryOptions)[selectedDeliveryTypeIndex]][0]}</Typography>
                      <Typography sx={{ fontSize: "12px", fontWeight: "bold", color: colors.textSecondary }}><span>{deliveryOptions[Object.keys(deliveryOptions)[selectedDeliveryTypeIndex]][1]}</span> Business Days</Typography>
                    </Box>
                    <Typography sx={{ fontSize: "12px" }}>+<span>{deliveryOptions[Object.keys(deliveryOptions)[selectedDeliveryTypeIndex]][2]}</span> each</Typography>
                  </Box>
                )}
                {showMoreOption &&
                  <Fragment>
                    {[...Array(Number(Object.keys(deliveryOptions).length))].map((item, index) => (
                      <Fragment>
                        {/* {console.log("d", index)} */}
                        {index !== selectedDeliveryTypeIndex &&
                          <Fragment>
                            <Divider sx={{ my: 1.5 }} />
                            <Box sx={{ display: "flex", alignItems: 'flex-start', cursor: "pointer" }} onClick={() => { setSelectedDeliveryTypeIndex(index); handleChangeRadioDelivery(deliveryOptions[Object.keys(deliveryOptions)[index]][1]); handleChangeRadioDelivery(deliveryOptions[Object.keys(deliveryOptions)[index]][1], deliveryOptions[Object.keys(deliveryOptions)[index]][2]); setShowMoreOption(false) }}>
                              <Radio
                                checked={selectedDeliveryValue === deliveryOptions[Object.keys(deliveryOptions)[index]][1]}
                                value={deliveryOptions[Object.keys(deliveryOptions)[index]][1]}
                                onChange={(e) => { setSelectedDeliveryTypeIndex(index); handleChangeRadioDelivery(e.target.value, deliveryOptions[Object.keys(deliveryOptions)[index]][2]); setShowMoreOption(false) }}
                                name="radio-buttons"
                                size='small'
                                sx={{
                                  color: colors.primary,
                                  '&.Mui-checked': {
                                    color: colors.primary,
                                  },
                                }}
                              />
                              <Box sx={{ flexGrow: 1 }}>
                                <Typography sx={{ fontSize: "13px", fontWeight: "bold", color: colors.black, mt: 1 }}>{deliveryOptions[Object.keys(deliveryOptions)[index]][0]}</Typography>
                                <Typography sx={{ fontSize: "12px", fontWeight: "bold", color: colors.textSecondary }}>Guaranteed <span>{deliveryOptions[Object.keys(deliveryOptions)[index]][1]}</span> Business Days</Typography>
                              </Box>
                              <Typography sx={{ fontSize: "12px" }}>+<span>{deliveryOptions[Object.keys(deliveryOptions)[index]][2]}</span> each</Typography>
                            </Box>
                          </Fragment>}
                      </Fragment>
                    ))}
                  </Fragment>
                }
                {showMoreOption ? (
                  <Typography sx={{ color: colors.primary, fontSize: "12px", my: 1.5, ml: 2, cursor: "pointer" }} onClick={() => { handleOptions() }}>Close Delivery Options</Typography>
                ) : (
                  <Typography sx={{ color: colors.primary, fontSize: "12px", my: 1.5, ml: 2, cursor: "pointer" }} onClick={() => { handleOptions() }}>Show Faster Delivery Options</Typography>
                )}
              </Box>
              <Box sx={{ border: `1px solid #e5e7ea`, p: 2, alignItems: "center", backgroundColor: colors.white, my: 2 }}>
                <Typography sx={{ fontWeight: "bold", fontSize: "18px", color: colors.black }}>Order Summary</Typography>
                <Divider sx={{ my: 2 }} />
                <Box>
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Typography sx={{ fontSize: "13px", color: colors.black }}>Subtotal ({totalItemState} items)</Typography>
                    <Typography sx={{ fontSize: "13px", color: colors.black }}>${subTotalItemPrice}</Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Typography sx={{ fontSize: "13px", color: colors.black }}>{deliveryOptions[Object.keys(deliveryOptions)[selectedDeliveryTypeIndex]][0]}</Typography>
                    <Typography sx={{ fontSize: "13px", color: colors.black }}>${deliveryShippingFee}</Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Typography sx={{ fontSize: "13px", color: colors.black }}>Tax (To be Calculated)</Typography>
                    <Typography sx={{ fontSize: "13px", color: colors.black }}>--</Typography>
                  </Box>
                </Box>
                <Divider sx={{ my: 2 }} />
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>Total</Typography>
                  <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>${(subTotalItemPrice + Number(deliveryShippingFee))}</Typography>
                </Box>
              </Box>
              <PrimaryButton myStyle={{ mt: 1 }} onClick={() => {
                dispatch(setTotalAmountToPay(((subTotalItemPrice) + Number(deliveryShippingFee))));
                dispatch(setDeliveryDays(deliveryOptions[Object.keys(deliveryOptions)[selectedDeliveryTypeIndex]][0]));
                navigate('/payment')
              }} fullWidth endIcon={<ArrowForward />}>
                Checkout
              </PrimaryButton>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box sx={{ backgroundColor: colors.whiteSpace, py: 2, px: 4, height: "100vh", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
          <Box>
            <Typography sx={{ my: 1.5 }}>No item is there in Cart</Typography>
            <SecondaryButton onClick={() => { navigate('/') }}>Continue Shopping</SecondaryButton>
          </Box>
        </Box>
      )}

    </Layout>
  )
}

export default Cart