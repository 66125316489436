import { Box, Dialog, Grid, Typography, IconButton, InputAdornment, OutlinedInput, InputLabel, FormControl, TextField } from '@mui/material';
import { Close } from '@mui/icons-material';
import React, { useState, Fragment, useRef } from 'react'
import { useForm } from 'react-hook-form';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import useAuth from './../../../hooks/useAuth';
import Swal from 'sweetalert2';
import Images from './../../../assets/Images';
import colors from './../../../styles/colors';
import { PrimaryButton } from './../../../components/UI/Buttons';
import { Error } from './../../../components/UI/Error';
import { Service } from './../../../config/service';
import { SuccessToaster, ErrorToaster } from '../../../components/Toaster/Toaster';


function LoginDialog({ open, setOpen, saveAndShareDesignDialog }) {

  const [loading, setLoading] = useState(false);

  const [userRegister, setUserRegister] = useState(true)

  const { login } = useAuth();
  const { register, handleSubmit, formState: { errors }, watch } = useForm();

  // *Login 

  // For API Call
  const loginInfo = async (formData) => {
    setLoading(true)
    try {
      let obj = {
        email: formData.email,
        password: formData.password
      }
      const { status, data, responseCode, message } = await Service.login(obj)
      if (status === true && responseCode === 200) {
        setLoading(false)
        SuccessToaster("Login Successfully")
        login(data.token, data.userName, obj.email)
        saveAndShareDesignDialog(true)
      }
      else {
        Swal.fire({
          text: message,
          icon: 'error',
          confirmButtonColor: colors.primary,
        })
      }

    } catch (error) {
      ErrorToaster("Something went wrong.")
      console.log('file: Login.js => line 14 => Login => error', error)
    } finally {
      setLoading(false)
    }
  };

  // For API Call
  const registerInfo = async (formData) => {
    setLoading(true)
    try {
      let obj = {
        first_name: formData.first_name,
        last_name: formData.last_name,
        email: formData.email,
        password: formData.password,
        confirm_password: formData.confirm_password
      }
      const { status, data, responseCode, message } = await Service.register(obj)
      if (status === true && responseCode === 200) {
        setLoading(false)
        SuccessToaster("Register Successfully")
        login(data.token, data.userName, obj.email)
        saveAndShareDesignDialog(true)
      }
      else {
        Swal.fire({
          text: message,
          icon: 'error',
          confirmButtonColor: colors.primary,
        })
      }

    } catch (error) {
      ErrorToaster("Something went wrong.")
      console.log("🚀 ~ file: Register.js ~ line 52 ~ registerInfo ~ error", error)
    } finally {
      setLoading(false)
    }
  };

  const [showPassword, setShowPassword] = useState(false);


  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  };

  // *Registration 
  const password = useRef({});
  password.current = watch("password", "");

  const [showRegisterPassword, setShowRegisterPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowRegisterPassword = () => {
    setShowRegisterPassword(!showRegisterPassword)
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword)
  };

  return (
    <Fragment>
      {userRegister ? (
        <Dialog sx={{
          "& .MuiDialog-paper": {
            borderRadius: "18px",
          }
        }} open={open} onClose={() => { setOpen(false) }} >
          <Box sx={{
            textAlign: "center", px: 3, py: 4, backgroundColor: colors.white, borderRadius: "10px", position: "relative",
          }}>
            <img src={Images.logo} width="40%" alt="Logo" />
            <Typography variant="h6"
              sx={{ color: colors.black, mt: 3 }}>
              Login
            </Typography>
            <Typography component={"form"} onSubmit={handleSubmit(loginInfo)}>
              <Box sx={{ mt: 2, display: "flex", flexDirection: 'column' }}>
                <FormControl variant="outlined" sx={{ my: 1 }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Email"
                    type="email"
                    error={errors?.email?.message && (true)}
                    {...register("email", {
                      required: 'Please enter your email.',
                    })}
                  />
                  {errors?.email?.message && (
                    <Error message={errors?.email?.message} />
                  )}
                </FormControl>
                <FormControl variant="outlined" sx={{ my: 1 }}>
                  <InputLabel error={errors?.password?.message && (true)}>Password</InputLabel>
                  <OutlinedInput
                    label="Password"
                    fullWidth
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => { handleClickShowPassword() }}
                          sx={{ color: colors.primary }}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    error={errors?.password?.message && (true)}
                    {...register("password", {
                      required: 'Please create your password.',
                    })}
                  />
                  {errors?.password?.message && (
                    <Error message={errors?.password?.message} />
                  )}
                </FormControl>

              </Box>
              <Typography
                onClick={() => { setUserRegister(false) }}
                sx={{
                  color: colors.textSecondary, my: 1,
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
              >
                Don't have an account ? <span style={{ color: colors.primary }}>Create Account</span>
              </Typography>
              <PrimaryButton
                fullWidth
                variant="contained"
                type="submit"
                loading={loading}
                sx={{
                  p: 1,
                  my: 1,
                  width: '100%'

                }}
              >
                Login
              </PrimaryButton>
            </Typography>
          </Box>
          <Close onClick={() => { setOpen(false) }} sx={{ position: "absolute", top: 15, right: 15, cursor: "pointer" }} />
        </Dialog>
      ) : (
        <Dialog sx={{
          "& .MuiDialog-paper": {
            borderRadius: "18px",
          }
        }} open={open} onClose={() => { setOpen(false) }} >
          <Box sx={{
            textAlign: "center", px: 3, py: 4, backgroundColor: colors.white, borderRadius: "10px", position: "relative",
          }}>
            <img src={Images.logo} width="40%" alt="Logo" />
            <Typography variant="h6"
              sx={{ color: colors.black, mt: 3 }}>
              Registration
            </Typography>
            <Typography component={"form"} onSubmit={handleSubmit(registerInfo)}>
              {/* First Name */}
              <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                <TextField
                  variant="outlined"
                  label="First name"
                  type="text"
                  error={errors?.first_name?.message && (true)}
                  {...register("first_name", {
                    required: 'Please enter your First name.',
                  })}
                />
                {errors?.first_name?.message && (
                  <Error message={errors?.first_name?.message} />
                )}
              </FormControl>
              {/* Last Name */}
              <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                <TextField
                  variant="outlined"
                  label="Last name"
                  type="text"
                  error={errors?.last_name?.message && (true)}
                  {...register("last_name", {
                    required: 'Please enter your Last name.',
                  })}
                />
                {errors?.last_name?.message && (
                  <Error message={errors?.last_name?.message} />
                )}
              </FormControl>
              {/* Email */}
              <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                <TextField
                  variant="outlined"
                  label="Email"
                  type="email"
                  error={errors?.email?.message && (true)}
                  {...register("email", {
                    required: 'Please enter your email.',
                  })}
                />
                {errors?.email?.message && (
                  <Error message={errors?.email?.message} />
                )}
              </FormControl>
              {/* Password */}
              <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                <InputLabel error={errors?.password?.message && (true)}>Password</InputLabel>
                <OutlinedInput
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => { handleClickShowRegisterPassword() }}
                        edge="end"
                        sx={{ color: colors.primary }}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  error={errors?.password?.message && (true)}
                  {...register("password", {
                    required: 'Please create your password.',
                  })}
                />
                {errors?.password?.message && (
                  <Error message={errors?.password?.message} />
                )}
              </FormControl>
              {/* Confirm Password */}
              <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
                <InputLabel error={errors?.confirm_password?.message && (true)}>Confirm Password</InputLabel>
                <OutlinedInput
                  label="Confirm Password"
                  type={showConfirmPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => { handleClickShowConfirmPassword() }}
                        edge="end"
                        sx={{ color: colors.primary }}
                      >
                        {showRegisterPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  error={errors?.confirm_password?.message && (true)}
                  {...register("confirm_password", {
                    required: 'Please re-type your password.',
                    validate: value =>
                      value === password.current || "Confirm password does not match"
                  })}
                />
                {errors?.confirm_password?.message && (
                  <Error message={errors?.confirm_password?.message} />
                )}
              </FormControl>
              <Typography
                onClick={() => { setUserRegister(true) }}
                sx={{
                  color: colors.textSecondary, my: 1,
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
              >
                Already have an account ? <span style={{ color: colors.primary }}>Log in</span>
              </Typography>
              <PrimaryButton
                fullWidth
                variant="contained"
                type="submit"
                loading={loading}
                sx={{
                  p: 1,
                  my: 1,
                  width: '100%'

                }}
              >
                Register
              </PrimaryButton>
            </Typography>
          </Box>
          <Close onClick={() => { setOpen(false) }} sx={{ position: "absolute", top: 15, right: 15, cursor: "pointer" }} />
        </Dialog>
      )}
    </Fragment>
  )
}

export default LoginDialog