import React from 'react'
import Layout from './../../layout/Layout/Layout';
import { Box, Grid, Typography } from '@mui/material';
import colors from './../../styles/colors';
import Images from '../../assets/Images';
import { FormatQuote } from '@mui/icons-material';

function ChairmanMessage() {
  return (
    <Layout>
      <Box sx={{
        background: `linear-gradient(to right,rgba(0,0,0,0.5),rgba(0,0,0,0.5)),url(${Images.chairmanMessage})no-repeat top center / cover`,
        minHeight: 250,
        px: 5,
        py: { xs: 3, md: 0 },
        color: "white",
        display: "flex",
        alignItems: "center",
      }}>
        <Grid container justifyContent={"center"} spacing={8}>
          <Grid item xs={12} md={5}>
            <Box textAlign="center">
              <Typography sx={{ fontSize: "34px", fontWeight: "bold", color: colors.white }}>Chairman's Message</Typography>
            </Box>
          </Grid>
        </Grid>

      </Box>

      <Box sx={{ width: "70%", mx: "auto", my: 8, position: "relative" }}>
        {/* <Typography sx={{ fontSize: "26px", fontWeight: "bold", color: colors.black }}>Heading</Typography> */}

        <Typography sx={{ fontSize: "14px", color: colors.textSecondary, my: 3, textAlign: "justify", zIndex: 999 }}>Trade Time is a sales operation centre established by Trade Time.
          EVERYWHERE strategy and better serve global customers. Advanced supply
          chain management system, strong sales service team, perfect operation
          and design centre, 9 self-operated production lines, cooperation of more
          than 5000 strategic suppliers, fast and efficient for customers, accurately
          match high-quality customized services. After years of accumulation and
          accumulation, it quickly ranked among the top three cross-border
          customization service providers in UAE. No matter in clothing, hats,
          lanyards, badges, key chains, Caps, Delivery Bags, Helmets, shopping bags,
          umbrellas or consumer electronics and home gifts, We can provide fast
          delivery, low MOQ, free design and accurate quotation, A series of one-stop
          customization services, such as multi-category supply and high-quality
          delivery, constantly optimize the construction of flexible supply chain, so
          that there is no difficult customization in the world. Trade Time is
          production of high-quality products and responsibility. Our products are
          exported to the United States, Germany, Japan, France, Britain, UAE, SA,
          Qatar and other 132 countries. We welcome everyone for experienced our
          great cooperations with better solutions.</Typography>
        <FormatQuote sx={{ position: "absolute", top: -35, left: -50, color: colors.primary, fontSize: "66px", zIndex: -1, transform: 'scaleX(-1)' }} />
      </Box>
    </Layout>
  )
}

export default ChairmanMessage