import React, { useState } from "react";
//* Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

//* Import Swiper styles
// import "swiper/css";
// import "swiper/css/free-mode";
// import "swiper/css/navigation";
// import "swiper/css/thumbs";
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/thumbs/thumbs.min.css';

//* Import required modules
import '../Shared/product.css';
import { FreeMode, Navigation, Thumbs } from "swiper";
import Images from "../../../assets/Images";
import colors from "../../../styles/colors";


export default function ProductSlider({ productImages }) {
  console.log("🚀 ~ file: ProductSlider.js ~ line 23 ~ ProductSlider ~ productImages", productImages)
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  return (
    <>
      <Swiper
        style={{
          "--swiper-navigation-color": colors.primary,
          "--swiper-pagination-color": colors.primary,
        }}
        spaceBetween={10}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper2"
      >
        {productImages?.map((item, index) => (
          <SwiperSlide key={index}>
            <img src={item?.image} alt="Product" />
          </SwiperSlide>
        ))}
        {/* <SwiperSlide>
          <img src={Images.productCardImage2} alt="Product" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Images.productCardImage3} alt="Product" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Images.productCardImage4} alt="Product" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Images.productCardImage5} alt="Product" />
        </SwiperSlide> */}

      </Swiper>

      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper"
      >
        {productImages?.map((item, index) => (
          <SwiperSlide key={index}>
            <img src={item?.image} alt="Product" />
          </SwiperSlide>
        ))}
        {/* <SwiperSlide>
          <img src={Images.productCardImage} alt="Product" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Images.productCardImage2} alt="Product" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Images.productCardImage3} alt="Product" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Images.productCardImage4} alt="Product" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Images.productCardImage5} alt="Product" />
        </SwiperSlide> */}
      </Swiper>
    </>
  );
}